import React, { Component } from 'react';

const ServerConfig = require('../../ServerConfig');
const url = ServerConfig.url;

// 쿠키 반환
function getCookie(cookieName) {
   var name = cookieName + "=";
   var cookies = document.cookie.split(';');  //쿠키별로 나누기

   for (var i = 0; i < cookies.length; i++) {
      var c = cookies[i];

      while (c.charAt(0) === ' ')
         c = c.substring(1);

      if (c.indexOf(name) === 0)
         return c.substring(name.length, c.length);
   }
   return "";
}

// 쿼리스트링 가져오기
function getQueryString(key) {
   // 전체 Url을 가져온다.
   var str = document.location.href;

   // QueryString의 값을 가져오기 위해서, ? 이후 첫번째 index값을 가져온다.
   var index = str.indexOf("?") + 1;

   // Url에 #이 포함되어 있을 수 있으므로 경우의 수를 나눴다.
   var lastIndex = str.indexOf("#") > -1 ? str.indexOf("#") + 1 : str.length;

   // index 값이 0이라는 것은 QueryString이 없다는 것을 의미하기에 종료
   if (index == 0) {
      return "";
   }

   // str의 값은 a=1&b=first&c=true
   str = str.substring(index, lastIndex);

   // key/value로 이뤄진 쌍을 배열로 나눠서 넣는다.
   str = str.split("&");

   // 결과값
   var rst = "";

   for (var i = 0; i < str.length; i++) {

      // key/value로 나눈다.
      // arr[0] = key
      // arr[1] = value
      var arr = str[i].split("=");

      // arr의 length가 2가 아니면 종료
      if (arr.length != 2) {
         break;
      }

      // 매개변수 key과 일치하면 결과값에 셋팅
      if (arr[0] == key) {
         rst = arr[1];
         break;
      }
   }
   return rst;
}


export default class Success extends Component {
   constructor(props) {
      super(props)
      this.state = {
         store_id: this.props.match.params.storeId,
         is_done: false,
      }
   }

   async callGetApi() {
      var tidCookie = getCookie("tid");
      var requestOptions = {
         method: 'POST',
         headers: {
            "Authorization": "KakaoAK 20844e233fd06b067fb74eb6d63468f3",
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
         }
      };
      //카카오페이 결제 완료 요청
      //package.json의 proxy 기반
      //원래는 https://kapi.kakao.com/v1/payment/ready ~~
      await fetch("/v1/payment/approve"
         + "?cid=TC0ONETIME"
         + "&partner_order_id=hyun"
         + "&partner_user_id=user"
         + "&tid=" + tidCookie
         + "&pg_token=" + getQueryString("pg_token")
         + "", requestOptions)
         .then(response => response.json())
         .then(result => {
            console.log("result", result);
            // this.setState({ information: result });

            var requestOptions = "";
            if (result.card_info) { //카드
               requestOptions = {
                  method: 'POST',
                  headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                     receiptNumber: result.aid,
                     storeId: this.state.store_id,
                     salesMachineId: 80,
                     // tel: result.butyer_tel,
                     goodsName: result.item_code,
                     totalPrice: result.amount.total,
                     cardName: result.card_info.purchase_corp,
                     cardNumber: result.card_info.card_mid,
                     cardInstallment: result.card_info.install_month,
                     approvalCode: result.card_info.approved_id,
                     approvalDate: result.approved_at,
                     goodsServed: 1,
                     refund: 1,
                     endDate: result.approved_at,
                     accessCount: 5,         // 서버에서 받아오기
                     division: "normal",     // 음료만 결제
                     kakaoTid: result.tid,
                     kakaoTaxFreeAmount: result.amount.tax_free,
                     selectedItem: 2
                  }),
                  mode: 'cors',
                  credentials: 'include'
               };
            } else {        //현금
               requestOptions = {
                  method: 'POST',
                  headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                     receiptNumber: result.aid,
                     storeId: this.state.store_id,
                     salesMachineId: 80,
                     // tel: result.butyer_tel,
                     goodsName: result.item_code,
                     totalPrice: result.amount.total,
                     cardName: "카카오머니",
                     cardNumber: "kakao",
                     cardInstallment: "0",
                     approvalCode: " ",
                     approvalDate: result.approved_at,
                     goodsServed: 1,
                     refund: 1,
                     endDate: result.approved_at,
                     accessCount: 5,         // 서버에서 받아오기
                     division: "normal",     // 음료만 결제
                     kakaoTid: result.tid,
                     kakaoTaxFreeAmount: result.amount.tax_free,
                     selectedItem: 2
                  }),
                  mode: 'cors',
                  credentials: 'include'
               };
            }
            //KW서버 매출 등록
            fetch(url + "/api/sales", requestOptions)
               .then(response => response.json())
               .then(value => {
                  console.log("KW", value);
                  if (value.status === 200) {
                     // alert("서버 전송 성공");
                  } else {
                     // alert("서버 전송 실패");
                  }

                  // 룸 예약 결제일 경우
                  if (getQueryString("pk")) {
                     // 예약 결제 완료처리
                     requestOptions = {
                        method: 'PATCH',
                        headers: {
                           'Accept': 'application/json',
                           'Content-Type': 'application/json'
                        },
                        mode: 'cors',
                        credentials: 'include'
                     };

                     fetch(url + "/api/reservation/room/" + getQueryString("pk") + "/" + value.data[0], requestOptions)
                        .then(response => response.json())
                        .then(value => {
                           console.log("예약 결제처리", value);
                           if (value.status === 200) {
                              alert("예약 성공");
                              this.setState({ is_done: true });
                           } else {
                              alert("서버 전송 실패");
                           }
                        })
                        .catch(err => console.log(err));
                  }
                  // 워시 예약 결제일 경우
                  else if (getQueryString("wash")) {
                     requestOptions = {
                        method: 'PATCH',
                        headers: {
                           'Accept': 'application/json',
                           'Content-Type': 'application/json'
                        },
                        mode: 'cors',
                        credentials: 'include'
                     };

                     fetch(url + "/api/reservation/wash/" + getQueryString("wash") + "/" + value.data[0], requestOptions)
                        .then(response => response.json())
                        .then(value => {
                           console.log("예약 결제처리", value);
                           if (value.status === 200) {
                              alert("예약 성공");
                              this.setState({ is_done: true });
                           } else {
                              alert("서버 전송 실패");
                           }
                        })
                        .catch(err => console.log(err));
                  }
               })
               .catch(err => console.log(err));
         })
         .catch(error => console.log('error', error));
   }

   componentDidMount() {
      this.callGetApi();
   }

   render() {
      if (this.state.is_done) {
         return (
            <div style={{ minHeight: '80vh', textAlign: 'center', marginTop: '100px' }}>
               <h1>결제가 완료되었습니다.</h1>
               <h3>입력하신 이메일로 예약 내용을 전송하였습니다.</h3>
               <img src="/images/reservation_success.png" />
            </div>
         );
      } else {
         return (
            <div style={{ minHeight: '80vh', textAlign: 'center', marginTop: '100px' }}>
               <h1>결제 및 예약 처리중입니다.</h1>
               <h3>페이지를 벗어나지 말아주세요</h3>
               <img src="/images/Spinner.gif" />
            </div>
         )
      }
   }
}