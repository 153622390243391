import React, { Component } from 'react'
import QRCode from 'qrcode.react'
import Timer from "react-compound-timer";
import $ from 'jquery'

export default class TryQRCode extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            input_tel: "",
            is_checked: false,
            code: ""
        })
        this.telOnChange = this.telOnChange.bind(this)
    }

    // 전화번호 숫자만 허용
    telOnChange(e) {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ input_tel: e.target.value })
        }
    }

    requestQr() {
        if ($('.name').val() === "") {
            alert("거주지를 입력해주세요.");
            return;
        }
        if ($('.numberOfPeople').val() === "") {
            alert("인원수를 입력해주세요.");
            return;
        }
        if ($('.input_tel').val() === "") {
            alert("전화번호를 입력해주세요.");
            return;
        }

        var requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: $('.name').val(),
                numberOfPeople: $('.numberOfPeople').val(),
                tel: $('.input_tel').val()
            }),
            mode: 'cors',
            credentials: 'include'
        };

        fetch(this.props.url + "/api/qr-code", requestOptions)
            .then(response => response.json())
            .then(value => {
                // console.log(value);
                this.setState({ is_checked: true, code: value.data[0] });
            })
            .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });
    }

    timeOut() {
        window.location.reload(false);      //페이지 새로고침
    }

    componentDidMount() {
        $('body').css('background', 'linear-gradient(to top, #accbee 0%, #e7f0fd 100%) no-repeat center center fixed');
        $('body').css('padding', '0');
    }

    render() {
        if (this.state.is_checked) {
            var code = this.state.code;
            return (
                <div style={{ textAlign: 'center' }}>
                    <div className="QRWrapper"
                        style={{
                            textAlign: 'center', margin: '0px auto', paddingTop: '50px',
                            maxWidth: '550px', minWidth: '350px', width: '200%',
                        }}
                    >
                        <h1 style={{ marginBottom: '50px', color: '#3C4856' }}>KW 출입명부</h1>
                        <QRCode value={code} size={300} includeMargin={true} />
                        <h3 style={{ margin: '50px auto' }}>위의 QR코드를 키오스크에 인식해 주세요.</h3>

                        <Timer
                            formatValue={value => `${value < 10 ? `0${value}` : value}`}            //항상 두글자씩 표현
                            initialTime={((30)) * 1000}   //30ch
                            direction="backward"
                            checkpoints={[
                                {
                                    time: 0,
                                    callback: () => { window.location.reload(false); }
                                }
                            ]}
                        >
                            <React.Fragment>
                                <span
                                    style={{ fontSize: '35px', color: 'red' }}
                                >
                                    남은시간 : <Timer.Seconds />초
                                </span>
                            </React.Fragment>
                        </Timer>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ textAlign: 'center' }}>
                    <div
                        style={{
                            textAlign: 'center', margin: '0px auto', paddingTop: '50px',
                            maxWidth: '550px', minWidth: '350px', width: '200%',
                        }}
                    >
                        <h1 style={{ marginBottom: '50px', color: '#3C4856' }}>KW 출입명부</h1>
                        <input
                            type="text"
                            className="name"
                            placeholder="시군구 (거주지)"
                            style={{ width: '70%', margin: '10px auto', height: '40px', fontSize: '25px', border: '1px solid' }}
                        ></input>
                        <br />
                        <input
                            type="number"
                            className="numberOfPeople"
                            placeholder="인원수 (본인포함)"
                            min="1"
                            style={{ width: '70%', margin: '10px auto', height: '40px', fontSize: '25px', border: '1px solid' }}
                        ></input>
                        <br />
                        <input
                            type="text"
                            className="input_tel"
                            placeholder="핸드폰번호 (-없이)"
                            value={this.state.input_tel}
                            onChange={this.telOnChange}
                            maxLength="11"
                            style={{ width: '70%', margin: '10px auto', height: '40px', fontSize: '25px', border: '1px solid' }}
                        ></input>
                        <br />
                        <button
                            onClick={this.requestQr.bind(this)}
                            className="customButton3"
                            style={{ width: '60%', padding: '12px 0px', margin: '30px auto', fontSize: '25px' }}
                        >동의 및 QR코드 생성</button>

                        <div
                            style={{
                                width: '80%', overflowY: 'scroll', margin: '0px auto',
                                border: '1px solid #ccc', background: '#fafafa', textAlign: 'left',
                                fontFamily: 'dotum,sans-serif', fontSize: '15px', padding: '10px'
                            }}>
                            □ 집합시설 출입을 위한 QR 체크인
                        <br />
                        감염병의 예방 및 관리에 관한 법률 제49조에 따른 감염병의 예방 및 감염 전파를 차단하기 위하여 이용자의 개인정보를 수집*이용*제3자 제공합니다.
                    </div>
                        <p style={{ fontSize: '12px', marginTop: '30px' }}>©KW INNOVATION. All rights reserved.</p>
                    </div>
                </div>
            );
        }
    }
}