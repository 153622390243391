import React, { Component } from 'react'
import $ from 'jquery'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class Profile extends Component {
   constructor(props) {
      super(props)
      this.state = ({
         store_id: this.props.match.params.storeId,
         is_auth: false,
         input_information: []
      });
   }

   deleteMember() {
      if ($('.input_delete_account').val() === "" || $('.input_delete_password').val() === "") {
         alert("아이디와 비밀번호를 입력해 주세요.");
         return;
      }

      var result = window.confirm("정말 탈퇴하시겠습니까?");
      if (result === false) {
         alert("취소되었습니다.");
         return;
      }

      var requestOptions = {
         method: 'DELETE',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            account: $('.input_delete_account').val(),
            password: $('.input_delete_password').val()
         }),
         mode: 'cors',
         credentials: 'include'
      };
      fetch(url + "/api/members/info", requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               document.cookie = 'name=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/';
               alert("정상적으로 탈퇴되었습니다. 감사합니다.");
               document.location.href = "/store/" + this.state.store_id;
            } else if (value.status === 401) {
               alert("아이디 또는 비밀번호가 틀립니다.");
               window.location.reload();
               return;
            }
            else {
               alert("아이디 또는 비밀번호가 틀립니다.");
               window.location.reload();
               return;
            }
         })
         .catch(err => alert("err: " + err));
   }

   // 비밀번호 변경
   changePassword() {
      if ($('.new_pw').val() !== $('.new_pw_re').val()) {
         alert("새 비밀번호가 다릅니다.");
         return;
      }

      if ($('.new_pw').val() === $('.old_pw').val()) {
         alert("기존 비밀번호와 같습니다.");
         return;
      }

      // 비밀번호 체크
      var engNum = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;      //영어+숫자로 8~20글자
      if (!engNum.test($('.new_pw').val())) {
         alert("비밀번호는 영어와 숫자를 혼합한 8~20글자이어야만 합니다.");
         return;
      }

      var result = window.confirm("비밀번호를 변경하시겠습니까?");
      if (result === false) {
         alert("취소되었습니다.");
         return;
      }

      var requestOptions = {
         method: 'PATCH',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            oldPW: $('.old_pw').val(),
            newPW: $('.new_pw').val()
         }),
         mode: 'cors',
         credentials: 'include'
      };
      fetch(url + "/api/members", requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               document.cookie = 'name=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/';
               alert("비밀번호가 변경되었습니다.");
               document.location.href = "/store/" + this.state.store_id;
            } else {
               alert("비밀번호가 다릅니다.");
            }
         })
         .catch(err => alert("err: " + err));
   }

   // 화면 이동 (비밀번호 재확인)
   changeIsAuth() {
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            password: $(`.input_pw`).val(),
         }),
         mode: 'cors',
         credentials: 'include'
      };

      fetch(url + "/api/members/info", requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               this.setState({
                  is_auth: true,
                  input_information: value.data[0]
               });
            } else {
               alert("비밀번호가 틀렸습니다.");
            }
         })
         .catch(err => console.log(err));
   }

   render() {
      const inputStyle = {
         fontSize: '17px', marginBottom: '5px',
         width: '250px', padding: '5px 0px',
         border: '1px solid #AAA'
      }

      if (this.state.is_auth) {
         return (
            <div style={{ minHeight: '75vh' }}>
               <div
                  style={{
                     border: '1px solid', borderRadius: '15px',
                     minWidth: '200px', maxWidth: '800px', width: '100%',
                     margin: '0px auto', marginTop: '50px', padding: '70px',
                     overflowX: 'auto'
                  }}
               >
                  {/* 내 정보 */}
                  <h3 style={{ marginBottom: '40px' }}>내 정보</h3>
                  <table style={{ width: '100%', fontSize: '15px' }}>
                     <tbody>
                        <tr style={{ borderTop: '2px solid' }}>
                           <th style={{ border: '1px solid gray', padding: '10px', background: '#DADDE2' }} >
                              이름
                           </th>
                           <th style={{ border: '1px solid gray', padding: '10px' }} >
                              {this.state.input_information.name}
                           </th>
                        </tr>
                        <tr>
                           <th style={{ border: '1px solid gray', padding: '10px', background: '#DADDE2' }} >
                              생년월일
                           </th>
                           <th style={{ border: '1px solid gray', padding: '10px' }} >
                              {this.state.input_information.birth}
                           </th>
                        </tr>
                        <tr>
                           <th style={{ border: '1px solid gray', padding: '10px', background: '#DADDE2' }} >
                              전화번호
                           </th>
                           <th style={{ border: '1px solid gray', padding: '10px' }} >
                              {this.state.input_information.tel}
                           </th>
                        </tr>
                        <tr>
                           <th style={{ border: '1px solid gray', padding: '10px', background: '#DADDE2' }} >
                              이메일
                           </th>
                           <th style={{ border: '1px solid gray', padding: '10px' }}>
                              {this.state.input_information.email}
                           </th>
                        </tr>
                     </tbody>
                  </table>
                  <hr />

                  {/* 비밀번호 변경 */}
                  <h3 style={{ marginBottom: '20px' }}>비밀번호 변경</h3>
                  <div style={{ textAlign: 'center' }}>
                     <input
                        className="old_pw"
                        type="password"
                        placeholder="현재 비밀번호"
                        style={inputStyle}
                     />
                     <br />
                     <input
                        className="new_pw"
                        type="password"
                        placeholder="새 비밀번호"
                        style={inputStyle}
                     />
                     <br />
                     <input
                        className="new_pw_re"
                        type="password"
                        placeholder="새 비밀번호 확인"
                        style={inputStyle}
                     />
                     <br />
                     <button
                        style={{ marginTop: '30px', padding: '5px 10px', fontSize: '20px' }}
                        className="customButton3"
                        onClick={this.changePassword.bind(this)}
                     >변경하기</button>
                  </div>

                  <hr />

                  <h3 style={{ marginBottom: '20px' }}>회원탈퇴</h3>
                  <div style={{ textAlign: 'center' }}>
                     <input type="text"
                        className="input_delete_account"
                        placeholder="아이디"
                        style={inputStyle}
                     />
                     <br />
                     <input type="password"
                        className="input_delete_password"
                        placeholder="비밀번호"
                        style={inputStyle}
                     />
                     <br />
                     <button className="btn btn-danger" onClick={this.deleteMember.bind(this)} style={{ padding: '10px', margin: '30px 15px', borderRadius: '8px', border: 'none' }}>회원탈퇴</button>
                  </div>
               </div>
            </div>
         );
      } else {
         return (
            <div style={{ minHeight: '75vh', textAlign: 'center' }}>
               <div
                  style={{
                     border: '1px solid', borderRadius: '15px',
                     minWidth: '200px', maxWidth: '600px', width: '100%',
                     margin: '0px auto', marginTop: '50px', padding: '50px 0px'
                  }}
               >
                  <h3 style={{ marginBottom: '50px' }}>비밀번호 확인</h3>
                  <input
                     className="input_pw"
                     type="password"
                     placeholder="비밀번호"
                     style={inputStyle}
                  />
                  <br />
                  <button
                     style={{ marginTop: '30px', padding: '5px 10px', fontSize: '20px' }}
                     onClick={this.changeIsAuth.bind(this)}
                     className="customButton"
                  >
                     check
                  </button>
               </div>
            </div>
         );
      }
   }
}