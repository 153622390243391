import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'

const ServerConfig = require('../../ServerConfig');
const url = ServerConfig.url;

export default class PaymentGoods extends Component {
   constructor(props) {
      super(props)
      this.state = {
         store_id: this.props.match.params.storeId,
         goods_info: [],
         input_option: [],
         bucket: JSON.parse(window.sessionStorage.getItem("bucket")),
         totalPrice: 0,
         store_info: [],
         importModal: false  // 아임포트 결제 후 서버 통신중 모달창
      }
   }

   /* 총 가격 구하기 */
   getTotal() {
      var total = 0;
      for (var i = 0; i < this.state.bucket.length; i++) {
         var goods_index = this.state.bucket[i].id;
         var goods_price = this.state.goods_info[goods_index].price;
         var total_price = goods_price * this.state.bucket[i].quantity;

         // 옵션이 있으면 가격 추가
         if(this.state.bucket[i].option !== undefined) {
            var optionIndex = this.state.bucket[i].option.split('/');

            for (var j = 1; j < optionIndex.length; j++) { 
               total_price += (this.state.input_option[optionIndex[j]].price * this.state.bucket[i].quantity);
            }
         }
         total = total + total_price;
      }
      this.setState({ totalPrice: total });
   }

   /* 아임포트 결제 */
   requestPay = () => {
      var IMP = window.IMP; // 생략해도 괜찮습니다.
      IMP.init(this.state.store_info[0].importId); // 발급받은 "가맹점 식별코드"를 사용합니다.

      IMP.request_pay({ // param
         pg: "",
         pay_method: "card",
         merchant_uid: "",
         name: "KWIN",
         // amount: 100
         amount: this.state.totalPrice
         // buyer_email: "gildong@gmail.com",
         // buyer_name: "홍길동",
         // buyer_tel: "010-4242-4242",
         // buyer_addr: "서울특별시 강남구 신사동",
         // buyer_postcode: "01181"
      }, rsp => {
         console.log(rsp);

         // 아임포트 결제 성공하면 서버에 결제결과 전송
         if (rsp.success) {
            var date = new Date(rsp.paid_at * 1000).toISOString();
            var goodsName = "";

            for (var i = 0; i < this.state.bucket.length; i++) {
               var name = this.state.goods_info[this.state.bucket[i].id].name;
               var price = this.state.goods_info[this.state.bucket[i].id].price;
               if (this.state.bucket[i].option !== undefined) {
                  var optionIndex = this.state.bucket[i].option.split('/');
   
                  for (var j = 1; j < optionIndex.length; j++) {
   
                     // 옵션 데이터가 없으면 break;
                     if (this.state.input_option.length <= 0) { break;}
   
                     name += ("{" + this.state.input_option[optionIndex[j]].name + "}");
                     price += this.state.input_option[optionIndex[j]].price;
                  }
               }
               goodsName += name + "_" + price + "_" + this.state.bucket[i].quantity + "\\";
            }
            goodsName = goodsName.slice(0, goodsName.length - 1);

            var requestOptions = {
               method: 'POST',
               headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
               },
               body: JSON.stringify({
                  receiptNumber: rsp.merchant_uid,
                  storeId: this.state.store_id,
                  salesMachineId: 80,
                  tel: rsp.butyer_tel,
                  goodsName: goodsName,
                  totalPrice: rsp.paid_amount,
                  cardName: rsp.card_name,
                  cardNumber: rsp.card_number,
                  cardInstallment: rsp.card_quota,
                  approvalCode: rsp.apply_num,
                  approvalDate: date,
                  goodsServed: 1,
                  refund: 1,
                  endDate: date,
                  accessCount: 5,       // 서버에서 받아오기
                  division: "normal",    // 음료만 결제
                  importId: rsp.imp_uid,
                  importPgId: rsp.pg_tid,
                  startDevice: -1
               }),
               mode: 'cors',
               credentials: 'include'
            };
            fetch(url + "/api/sales", requestOptions)
               .then(response => response.json())
               .then(value => {
                  console.log(value);
                  if (value.status === 200) {
                     alert("서버 전송 성공");
                     window.location.href =  "/store/" + this.state.store_id;
                  } else {
                     alert("서버 전송 실패");
                  }
               })
               .catch(err => console.log(err));

         } else {
            alert("결제 실패");
         }
      });
   }

   /* 카카오페이 결제 */
   kakaoPay() {
      if (this.state.totalPrice <= 0) {
         alert("결제할 금액이 없습니다.");
         return;
      }

      var goodsName = "";
      for (var i = 0; i < this.state.bucket.length; i++) {
         var name = this.state.goods_info[this.state.bucket[i].id].name;
         var price = this.state.goods_info[this.state.bucket[i].id].price;
         if (this.state.bucket[i].option !== undefined) {
            var optionIndex = this.state.bucket[i].option.split('/');

            for (var j = 1; j < optionIndex.length; j++) {

               // 옵션 데이터가 없으면 break;
               if (this.state.input_option.length <= 0) { break; }

               name += ("{" + this.state.input_option[optionIndex[j]].name + "}");
               price += this.state.input_option[optionIndex[j]].price;
            }
         }
         goodsName += name + "_" + price + "_" + this.state.bucket[i].quantity + "\\";
      }
      goodsName = goodsName.slice(0, goodsName.length - 1);
      
      if (goodsName.length >= 100) {
         alert("카카오페이에서 지원하는 상품 종류 개수보다 많습니다. (최대 4~6개)");
         return;
      }

      var requestOptions = {
         method: 'POST',
         headers: {
            "Authorization": "KakaoAK 20844e233fd06b067fb74eb6d63468f3",
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
         }
      };
      //package.json의 proxy 기반
      //원래는 https://kapi.kakao.com/v1/payment/ready ~~
      fetch("/v1/payment/ready"
         + "?cid=" + this.state.store_info[0].kakaoId
         + "&partner_order_id=hyun"
         + "&partner_user_id=user"
         + "&item_name=KWIN"
         + "&quantity=1"
         + "&item_code=" + goodsName
         + "&total_amount=" + this.state.totalPrice
         + "&tax_free_amount=0"
         + "&approval_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/success"
         + "&fail_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/fail"
         + "&cancel_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/cancel", requestOptions)
         .then(response => response.json())
         .then(result => {
            console.log(result);
            document.cookie = "tid=" + result.tid + ";path=/store";
            // this.setState({ tid: result.tid });
            // localStorage.tid = result.tid;
            window.open(result.next_redirect_pc_url);
         })
         .catch(error => console.log('error', error));
   }

   /* 상품 수량 추가 이벤트 */
   addGoods(bucketIndex) {
      var list = this.state.bucket;
      var price = this.state.totalPrice;
      
      // 상품 가격 추가
      price += this.state.goods_info[list[bucketIndex].id].price;

      // 옵션 가격 추가
      if (list[bucketIndex].option !== undefined) {
         var optionIndex = list[bucketIndex].option.split('/');

         for (var j = 1; j < optionIndex.length; j++) {
            price += (this.state.input_option[optionIndex[j]].price);
         }
      }

      // 리스트 및 수량 반영
      list[bucketIndex].quantity = list[bucketIndex].quantity + 1;

      this.setState({ totalPrice: price, bucket: list });
   }

   /* 상품 수량 빼기 이벤트 */
   deleteGoods(bucketIndex) {
      var list = this.state.bucket;
      var price = this.state.totalPrice;

      // 상품 가격 빼기
      price -= this.state.goods_info[list[bucketIndex].id].price;

      // 옵션 가격 빼기
      if (list[bucketIndex].option !== undefined) {
         var optionIndex = list[bucketIndex].option.split('/');

         for (var j = 1; j < optionIndex.length; j++) {
            price -= (this.state.input_option[optionIndex[j]].price);
         }
      }

      // 리스트 및 수량 반영
      if (bucketIndex === 0) { //첫번째 상품
         if (list[bucketIndex].quantity === 1) {
            list.shift();
         } else {
            list[bucketIndex].quantity = list[bucketIndex].quantity - 1;
         }
      } else if (bucketIndex === list.length - 1) { //마지막 상품
         if (list[bucketIndex].quantity === 1) {
            list.pop();
         } else {
            list[bucketIndex].quantity = list[bucketIndex].quantity - 1;
         }
      } else {        //나머지 상품
         if (list[bucketIndex].quantity === 1) {
            list.splice(bucketIndex, 1);
         } else {
            list[bucketIndex].quantity = list[bucketIndex].quantity - 1;
         }
      }

      this.setState({ totalPrice: price, bucket: list });
   }

   callGetApi() {
      // 상품 정보
      fetch(url + "/api/goods/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            console.log("상품", value);
            this.setState({ goods_info: value.data });

            // 옵션 정보
            fetch(url + "/api/goods/option/" + this.state.store_id)
               .then(response => response.json())
               .then(value => {
                  console.log("옵션", value);
                  this.setState({ input_option: value.data });
                  this.getTotal();
               })
               .catch(err => alert("옵션 정보를 불러오는데 실패하였습니다."));
         })
         .catch(err => { console.log(err); alert("설정값을 불러오는데 실패하였습니다.") });

      // 가맹점 정보 요청 (카카오, 아임포트)
      fetch(url + "/api/account/store-inform/simple/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            console.log("store-Info", value);
            this.setState({ store_info: value.data });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
   }

   componentDidMount() {
      this.callGetApi();
      console.log("장바구니", this.state.bucket);
   }

   render() {
      if (this.state.bucket === null || this.state.bucket.length === 0) {
         return (
            <div style={{ minHeight: '80vh', textAlign: 'center' }}>
               <h1>장바구니가 비었습니다.</h1>
            </div>
         );
      } else {
         return (
            <div style={{ minHeight: '80vh' }}>
               <h2 style={{ margin: '20px 0px' }}>장바구니</h2>
               <Bucket
                  bucket={this.state.bucket}
                  goods_info={this.state.goods_info}
                  input_option={this.state.input_option}
                  addGoods={this.addGoods.bind(this)}
                  deleteGoods={this.deleteGoods.bind(this)}
               />
               <div style={{ textAlign: 'right', marginTop: '20px' }}>
                  <span
                     style={{
                        fontSize: '20px',
                        color: '#3C4856', fontWeight: '700'
                     }}
                  >총 {this.state.totalPrice.toLocaleString()}원
                        </span>
                  <img
                     src="/images/kakaoPay.png"
                     style={{ cursor: 'pointer', margin: '0px 10px' }}
                     onClick={this.kakaoPay.bind(this)}
                  />
                  <button onClick={this.requestPay} className="customButton3" style={{ width: '80px', height: '50px', padding: '0', margin:'0px 10px' }}>일반결제</button>
               </div>
            </div>
         );
      }
   }
}

class Bucket extends Component {
   constructor(props) {
      super(props)
   }

   render() {
      if (this.props.goods_info.length === 0) {
         return (
            <div>loading...</div>
         );
      } else {
         var list = [];
         for (var i = 0; i < this.props.bucket.length; i++) {
            var name = this.props.goods_info[this.props.bucket[i].id].name;
            var price = this.props.goods_info[this.props.bucket[i].id].price;
            //옵션이 있을 경우
            if (this.props.bucket[i].option !== undefined) {
               var optionIndex = this.props.bucket[i].option.split('/');

               for (var j = 1; j < optionIndex.length; j++) {

                  // 옵션 데이터가 없으면 break;
                  if (this.props.input_option.length <= 0) { break;}

                  name += (" {" + this.props.input_option[optionIndex[j]].name + "}");
                  price += this.props.input_option[optionIndex[j]].price;
               }
            }


            list.push(
               <tr key={i}>
                  <td>{i + 1}</td>
                  <td><img width="100px" src={this.props.goods_info[this.props.bucket[i].id].image} /></td>
                  <td>{name}</td>
                  <td>
                     <div style={{ fontWeight: '600' }}>{this.props.bucket[i].quantity}</div>
                     {price}
                  </td>
                  <td>
                     <div>
                        <button
                           className="customButton2"
                           onClick={this.props.deleteGoods.bind(this, i)}
                           style={{ margin: '5px' }}
                        >-</button>
                        <button
                           className="customButton2"
                           onClick={this.props.addGoods.bind(this, i)}
                           style={{ margin: '2px' }}
                        >+</button>
                     </div>
                  </td>
               </tr>
            );
         }
         return (
            <div>
               <table style={{ width: '100%', textAlign: 'center' }}>
                  <tbody>
                     <tr>
                        <th style={{ width: '40px' }}>번호</th>
                        <th style={{ width: '110px' }}>상품 이미지</th>
                        <th>상품정보</th>
                        <th>수량/가격</th>
                        <th style={{ width: '65px' }}></th>
                     </tr>
                     {list}
                  </tbody>
               </table>
            </div>
         );
      }
   }
}