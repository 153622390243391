import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import $ from 'jquery'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class SpotPay extends Component {
   constructor(props) {
      super(props);
      this.state = ({
         step: 1,
         store_id: this.props.match.params.storeId,

         is_member: false,
         showModal: false,
         clickedIndex_basket: -1,
         clickedIndex_course: -1,
         check_email: false,
         selected_date: new Date(),      // 선택된 날짜
         user_information: [],           // 멤버 정보
         input_course: [],               // 사물함 정보
         input_setting: [],              // 예약설정 (찾아가는시간, 맡기는시간)

         setTime_hour: "",
         setTime_min: "",
         getTime_hour: "",
         getTime_min: "",
         is_delivery: false,

         input_simple_pw: ""
      });
      this.onChangePw = this.onChangePw.bind(this)
   }

   // step 변경
   nextStep() {
      if (this.state.step === 1) {
         if (this.state.clickedIndex_course === -1) {
            alert("예약하실 코스를 선택해 주세요.");
            return;
         } else {
            this.setState({ step: this.state.step + 1 });
         }
      } else if (this.state.step === 2) {
         this.setState({
            step: this.state.step + 1,
            setTime_hour: $('.setTime_hour').val(),
            setTime_min: $('.setTime_min').val(),
            getTime_hour: $('.getTime_hour').val(),
            getTime_min: $('.getTime_min').val()
         });
      } else {
         this.setState({ step: this.state.step + 1 });
      }
   }
   
   prevStep() {
      if (this.state.step === 2) {
         this.setState({
            step: this.state.step - 1,
            setTime_hour: $('.setTime_hour').val(),
            setTime_min: $('.setTime_min').val(),
            getTime_hour: $('.getTime_hour').val(),
            getTime_min: $('.getTime_min').val()
         });
      } else {
         this.setState({ step: this.state.step - 1 });
      }
   }

   /* 간편비밀번호 숫자만 허용 */
   onChangePw(e) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         this.setState({ input_simple_pw: e.target.value })
      }
   }

//    // 배달 여부 변경
//    changeIsDelivery(isDelivery) {
//       this.setState({ is_delivery: isDelivery });
//    }

   // 모달창 열기
   openModal(index) {
      this.setState({
         clickedIndex_course: index
      });
   }
   // 모달창 종료 버튼
   closeModal() {
      this.setState({
         clickedIndex_basket: -1,
         clickedIndex_course: -1,
         showModal: false
      });
   }
   // 모달창 확인 버튼
   confirmModal() {
      this.setState({ showModal: false });
   }

   // 코스 버튼 선택
//    checkCourseIndex(index) {
//       this.setState({ clickedIndex_course: index });
//    }

   /* 예약하기 버튼 이벤트 */
   tryReservation() {
      // 비밀번호가 6자리가 안될 경우
      if ($('#reservation_pw').val().length < 6) {
         alert("비밀번호 6자리를 입력해 주세요.");
         return;
      }

      if (this.state.clickedIndex_course === -1) {
         alert("이용하실 사물함과 코스를 선택해 주세요.");
         return;
      }

      window.sessionStorage.clear();
      window.sessionStorage.setItem("bucket", JSON.stringify({
         receiptNumber: this.state.selected_date.toISOString(),
         storeId: this.state.store_id,
         courseId: this.state.input_course[this.state.clickedIndex_course].id,
         totalPrice: this.state.input_course[this.state.clickedIndex_course].price,
         description: $('#description').val(),
         tel: $('#input_tel1').val() + $('#input_tel2').val() + $('#input_tel3').val(),
         email: $('#sending_email').val(),
         password: $('#reservation_pw').val()
      }));
      window.location.href = "/store/" + this.state.store_id + "/payment/spot";
   }

   /* 비회원 예약 버튼 */
   notMemberReserve() {
      $('.loginBtn').prop('hidden', true);
      $('.input_email').prop('hidden', false);
      $('.sendingEmailBtn').prop('hidden', false);
   }

   /* 이메일 인증번호 전송 */
   sendingMail() {
      // 이메일 체크
      var email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;

      if (email.test($('.input_email').val().trim())) {
         $('.input_email').css("border", "1px solid black");
      } else {
         $('.input_email').css("border", "2px solid #B10000");
         alert("이메일 형식이 아닙니다.");
         return;
      }
      $('.sendingEmailBtn').prop('disabled', true);
      $('.sendingEmailBtn').text("전송중");

      var requestOptions = {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      fetch(url + "/api/members/email/" + $('.input_email').val(), requestOptions)
         .then(response => response.json())
         .then(value => {
            alert("인증번호가 전송되었습니다.");
            $('.input_email_key').prop('hidden', false);
            $('.checkingEmailBtn').prop('hidden', false);

            $('.sendingEmailBtn').prop('disabled', false);
            $('.sendingEmailBtn').text("재전송");
         })
         .catch(err => alert("err: " + err));
   }

   /* 이메일 인증번호 확인 */
   checkingEmail() {
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      fetch(url + "/api/members/email/" + $('.input_email_key').val(), requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value === true) {
               alert("이메일 인증이 완료되었습니다.");
               this.setState({ check_email: true });
            } else {
               alert("인증번호가 다릅니다.");
            }
         })
         .catch(err => alert("err: " + err));
   }

   /* 가맹점 예약 조회 */
   callGetApi() {
      var requestOptions = {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      // 코스 정보
      fetch(url + "/api/wash/course/" + this.state.store_id, requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               this.setState({
                  input_course: value.data
               });
            }
         })
         .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });
      // 가맹점 정보(맡기는시간 찾는시간 배달설정)
    //   fetch(url + "/api/store-setting/reservation/wash/" + this.state.store_id, requestOptions)
    //      .then(response => response.json())
    //      .then(value => {
    //         console.log(value);
    //         if (value.status === 200) {
    //            var input_endSetTime = new Date();
    //            var now = new Date();
    //            input_endSetTime.setHours(parseInt(value.data[0].endSettime.split(':')[0]));
    //            input_endSetTime.setMinutes(parseInt(value.data[0].endSettime.split(':')[1]));
    //            input_endSetTime.setSeconds(parseInt(value.data[0].endSettime.split(':')[2]));
    //            console.log(input_endSetTime, now);

    //            if (now < input_endSetTime) {
    //               console.log("오늘로 하자!");
    //            } else {
    //               now.setDate(now.getDate() + 1);

    //               console.log("내일로 하자!");
    //            }
    //            this.setState({
    //               input_setting: value.data,
    //               selected_date: now
    //            });

    //            // 예약 정보
    //         }
    //      })
    //      .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });

      // 회원정보
      fetch(url + "/api/members/reservation", requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               this.setState({
                  is_member: true,
                  user_information: value.data[0]
               });
            }
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });

   }

   componentDidMount() {
      this.callGetApi();
   }

render() {
      if (this.state.is_member || this.state.check_email) {

        //  // 선택된 사물함의 코스 리스트
        //  var course_list = [];
        // if (this.state.clickedIndex_basket !== -1) { 

        //      for (var j = 0; j < this.state.input_basket[this.state.clickedIndex_basket].washCourse.length; j++) {
        //        course_list.push(
        //           <button key={j} onClick={this.checkCourseIndex.bind(this, j)} className="btn btn-dark" style={{ margin: '5px' }}>{this.state.input_basket[this.state.clickedIndex_basket].washCourse[j].name}({this.state.input_basket[this.state.clickedIndex_basket].washCourse[j].price + this.state.input_basket[this.state.clickedIndex_basket].price}원)</button>
        //        );
        //     }
        //  }

        //  // 사물함-코스 정보 출력
        //  var checkedCourse = null;
        //  if (this.state.clickedIndex_course !== -1) {
        //     checkedCourse =
        //        <p style={{ color: 'blue' }}>
        //           {this.state.input_basket[this.state.clickedIndex_basket].name} - {this.state.input_basket[this.state.clickedIndex_basket].washCourse[this.state.clickedIndex_course].name}
        //        </p>;
        //  }


         var now_date = this.state.selected_date.toISOString().slice(0, 4) + "년 "
            + this.state.selected_date.toISOString().slice(5, 7) + "월"
            + this.state.selected_date.toISOString().slice(8, 10) + "일"

         return (
            <div style={{ minHeight: '80vh' }}>
               <h1 style={{ margin: '20px 0px' }}> 세탁기 현장 구매</h1>
               <Modal show={this.state.showModal} centered backdrop="static" >
                  <Modal.Header style={{ background: "#A7C1CC" }}>
                     <h2 style={{ margin: '0', fontWeight: 'bold', fontSize: '25px', color: '#F5F5F5' }}>이용권을 선택해주세요. </h2>
                     <span onClick={this.closeModal.bind(this)} style={{ cursor: 'pointer', fontSize: '20px', color: 'white' }} className="pull-right">X</span>
                  </Modal.Header>
                  <Modal.Body>
                     {/* <div style={{ textAlign: 'center' }}>
                        {course_list}
                     </div> */}
                  </Modal.Body>
                  <Modal.Footer>
                     {/* {checkedCourse} */}
                     <button className="btn btn-info" onClick={this.confirmModal.bind(this)}>확인</button>
                     <button className="btn btn-danger" onClick={this.closeModal.bind(this)}>취소</button>
                  </Modal.Footer>
               </Modal>
               <div style={{ textAlign: 'center' }}>
                  <h3>예약 날짜: <span style={{ color: "#DD3124", fontWeight: '700' }}>{now_date}</span></h3>
                  <InnerData
                     step={this.state.step}
                     nextStep={this.nextStep.bind(this)}
                     prevStep={this.prevStep.bind(this)}

                     input_course={this.state.input_course}
                     openModal={this.openModal.bind(this)}
                     clickedIndex_course={this.state.clickedIndex_course}
                     clickedIndex_basket={this.state.clickedIndex_basket}

                     input_setting={this.state.input_setting}

                     is_member={this.state.is_member}  
                     user_information={this.state.user_information}
                     check_email={this.state.check_email}
                     input_simple_pw={this.state.input_simple_pw}
                     onChangePw={this.onChangePw.bind(this)}

                     tryReservation={this.tryReservation.bind(this)}
                  />
               </div>
            </div>
         );
      } else {
         var login_url = "/login?rtn=" + document.location.href;
         return (
            <div style={{ minHeight: '80vh', alignContent: 'center' }}>
               <div style={{ width: '100%', margin: '0 auto', textAlign: 'center', paddingTop: '200px' }}>
                  <a href={login_url}>
                     <button className="customButton loginBtn"
                        style={{ marginRight: '20px', width: '120px', height: '120px', fontSize: '20px', borderRadius: '15px' }}
                     >로그인</button>
                  </a>
                  <button className="customButton2 notMemberBtn"
                     style={{ width: '120px', height: '120px', fontSize: '20px', borderRadius: '15px' }}
                     onClick={this.notMemberReserve}
                  >비회원 예약</button>
                  <br />
                  <input
                     type="text"
                     className="input_email"
                     placeholder="이메일을 입력해주세요."
                     hidden
                     style={{ width: '50%', margin: '30px auto', border: '2px solid', fontSize: '17px', padding: '5px' }}
                  />
                  <button
                     className="sendingEmailBtn customButton3"
                     hidden
                     style={{ width: '100px', height: '50px', marginLeft: '10px' }}
                     onClick={this.sendingMail}
                  >전송하기</button>
                  <input
                     type="text"
                     className="input_email_key"
                     hidden
                     style={{ width: '50%', margin: '30px auto', border: '2px solid', fontSize: '17px', padding: '5px' }}
                  />
                  <button
                     className="checkingEmailBtn customButton3"
                     hidden
                     style={{ width: '100px', height: '50px', marginLeft: '10px' }}
                     onClick={this.checkingEmail.bind(this)}
                  >확인하기</button>
               </div>
            </div>
         );
      }
    }
}

class InnerData extends Component {
   render() {
      // 사물함-코스 정보 출력
    //   var checkedCourse = null;
    //   if (this.props.clickedIndex_course !== -1) {
    //      if (this.props.is_delivery) {
    //         checkedCourse =
    //            <h3 style={{ color: 'red' }}>
    //               배달: {this.props.input_basket[this.props.clickedIndex_basket].name} - {this.props.input_basket[this.props.clickedIndex_basket].washCourse[this.props.clickedIndex_course].name}
    //            </h3>;
    //      } else {
    //         checkedCourse =
    //            <h3 style={{ color: 'red' }}>
    //               방문수령: {this.props.input_basket[this.props.clickedIndex_basket].name} - {this.props.input_basket[this.props.clickedIndex_basket].washCourse[this.props.clickedIndex_course].name}
    //            </h3>;
    //      }
    //   }

      // 코스 선택
      if (this.props.step === 1) {

         // 코스 리스트
         var basket_list = [];
         var dry_list = [];
         var laundry_list = [];
         var low_list = [];
         var low_dry_list = [];
         for (var i = 0; i < this.props.input_course.length; i++) {
            
            if (this.props.clickedIndex_course === i) {
               if(this.props.input_course[i].type == 1){
                  laundry_list.push(
                     <button key={i}
                      className="btn btn-success"
                       onClick={this.props.openModal.bind(this, i)}
                      style={{
                         width: '150px', height: '70px',
                         fontSize: '15px', margin:'5px',
                         border: '2px solid gray'
                      }}
                   >{this.props.input_course[i].name}</button>
                  );
               }else if (this.props.input_course[i].type == 2){
                  dry_list.push(
                     <button key={i}
                      className="btn btn-success"
                       onClick={this.props.openModal.bind(this, i)}
                      style={{
                         width: '150px', height: '70px',
                         fontSize: '15px', margin:'5px',
                         border: '2px solid gray'
                      }}
                   >{this.props.input_course[i].name}</button>
                  );
               }else if (this.props.input_course[i].type == 3 || this.props.input_course[i].type == 4){
                  low_list.push(
                     <button key={i}
                      className="btn btn-success"
                       onClick={this.props.openModal.bind(this, i)}
                      style={{
                         width: '150px', height: '70px',
                         fontSize: '15px', margin:'5px',
                         border: '2px solid gray'
                      }}
                   >{this.props.input_course[i].name}</button>
                  );
               }
             } else { 
               if(this.props.input_course[i].type == 1){
                  laundry_list.push(
                     <button key={i}
                      className="btn btn-light"
                       onClick={this.props.openModal.bind(this, i)}
                      style={{
                         width: '150px', height: '70px',
                         fontSize: '15px', margin:'5px',
                         border: '2px solid gray'
                      }}
                   >{this.props.input_course[i].name}</button>
                  );
               }else if (this.props.input_course[i].type == 2){
                  dry_list.push(
                     <button key={i}
                      className="btn btn-light"
                       onClick={this.props.openModal.bind(this, i)}
                      style={{
                         width: '150px', height: '70px',
                         fontSize: '15px', margin:'5px',
                         border: '2px solid gray'
                      }}
                   >{this.props.input_course[i].name}</button>
                  );
               }else if (this.props.input_course[i].type == 3){
                  low_list.push(
                     <button key={i}
                      className="btn btn-light"
                       onClick={this.props.openModal.bind(this, i)}
                      style={{
                         width: '150px', height: '70px',
                         fontSize: '15px', margin:'5px',
                         border: '2px solid gray'
                      }}
                   >{this.props.input_course[i].name}</button>
                  );
               }else if(this.props.input_course[i].type == 4){
                  low_dry_list.push(
                     <button key={i}
                      className="btn btn-light"
                       onClick={this.props.openModal.bind(this, i)}
                      style={{
                         width: '150px', height: '70px',
                         fontSize: '15px', margin:'5px',
                         border: '2px solid gray'
                      }}
                   >{this.props.input_course[i].name}</button>
                  );
               }
             }
         }

         return (
            <div>
               <div style={{ height: '600px', overflow: 'auto' }}>
                  <h3>세탁코스 이용권 선택</h3>
                  <hr />
                  {/* <p>(* 빨간색 : 사용 불가능)</p> */}
                  <p>초대형 세탁기</p>
                  <div>
                     {laundry_list}
                  </div>
                  <p>초대형 건조기</p>
                  <div>
                     {dry_list}
                  </div>
                  <p>저용량 세탁기</p>
                  <div>
                     {low_list}
                  </div>
                  <p>저용량 건조기</p>
                  <div>
                     {low_dry_list}
                  </div>
               </div>
               {/* {checkedCourse} */}
               <button style={{ fontSize: '20px', margin: '0px 10px' }} onClick={this.props.nextStep} className="btn btn-info" >다음 →</button>
            </div>
         );
      }

    //   // 방문 시간 선택
    //   else if (this.props.step === 2) {

    //      // 찾아가는 시간, 맡기는 시간 
    //      var set_during_time = null, get_during_time = null;
    //      var set_select = [], get_select = [];
    //      // 배달지 또는 요청
    //      var select_delivery = null;
    //      if (this.props.input_setting.length > 0) {
    //         var temp_setting = this.props.input_setting[0];

    //         set_during_time = <div style={{ fontSize: '14px', margin: '0px' }}>({temp_setting.startSettime.slice(0, 5)}~{temp_setting.endSettime.slice(0, 5)})</div>;
    //         get_during_time = <div style={{ fontSize: '14px', margin: '0px' }}>({temp_setting.startGettime.slice(0, 5)}~{temp_setting.endGettime.slice(0, 5)})</div>;

    //         var startSettime = parseInt(temp_setting.startSettime.slice(0, 2));
    //         var startGetTime = parseInt(temp_setting.startGettime.slice(0, 2));
    //         var endSetTime = parseInt(temp_setting.endSettime.slice(0, 2));
    //         var endGetTime = parseInt(temp_setting.endGettime.slice(0, 2));

    //         if (startSettime <= endSetTime) {
    //            for (var time = startSettime; time < endSetTime; time++) {
    //               set_select.push(<option value={time} key={time}>{time}</option>);
    //            }
    //         } else {
    //            for (var time = startSettime; time < 24; time++) {
    //               set_select.push(<option value={time} key={time}>{time}</option>);
    //            }
    //            for (var time2 = 0; time2 < endSetTime; time2++) {
    //               set_select.push(<option value={time2} key={time2}>{time2}</option>);
    //            }
    //         }

    //         if (startGetTime <= endGetTime) {
    //            for (var time = startGetTime; time < endGetTime; time++) {
    //               get_select.push(<option value={time} key={time}>{time}</option>);
    //            }
    //         } else {
    //            for (var time = startGetTime; time < 24; time++) {
    //               get_select.push(<option value={time} key={time}>{time}</option>);
    //            }
    //            for (var time2 = 0; time2 < endGetTime; time2++) {
    //               get_select.push(<option value={time2} key={time2}>{time2}</option>);
    //            }
    //         }

    //         // 배달 버튼
    //         if (this.props.input_setting[0].isUseDelivery) {
    //            // 배달이 선택됨
    //            if (this.props.is_delivery) {
    //               select_delivery = (
    //                  <div style={{ textAlign: 'center', margin: '30px 0px' }}>
    //                     <button className="btn btn-success"
    //                        style={{ fontSize: '20px', margin: '0px 15px', width: '130px', height: '70px', border: '2px solid gray' }}
    //                        onClick={this.props.changeIsDelivery.bind(this, true)}
    //                     >배달
    //                     </button>
    //                     <button className="btn btn-light"
    //                        style={{ fontSize: '20px', margin: '0px 15px', width: '130px', height: '70px', border: '2px solid gray' }}
    //                        onClick={this.props.changeIsDelivery.bind(this, false)}
    //                     >방문수령
    //                     </button>
    //                  </div>
    //               );
    //            } else { //방문수령이 선택됨
    //               select_delivery = (
    //                  <div style={{ textAlign: 'center', margin: '30px 0px' }}>
    //                     <button className="btn btn-light"
    //                        style={{ fontSize: '20px', margin: '0px 15px', width: '130px', height: '70px', border: '2px solid gray' }}
    //                        onClick={this.props.changeIsDelivery.bind(this, true)}
    //                     >배달
    //                     </button>
    //                     <button className="btn btn-success"
    //                        style={{ fontSize: '20px', margin: '0px 15px', width: '130px', height: '70px', border: '2px solid gray' }}
    //                        onClick={this.props.changeIsDelivery.bind(this, false)}
    //                     >방문수령
    //                     </button>
    //                  </div>
    //               );
    //            }
    //         }
    //      }

    //      return (
    //         <div>
    //            <div style={{ height: '600px', overflow: 'auto' }}>
    //               <div>
    //                  <img src="/images/reservation_icon_2.png" style={{ minWidth: '250px', width: '90%', maxWidth: '450px' }} alt="" />
    //               </div>
    //               <h3>2. 방법 및 시간 선택</h3>
    //               <hr />

    //               <div>
    //                  {select_delivery}
    //                  <table style={{ margin: '0 auto', width: '90%', fontSize: '20px' }}>
    //                     <tbody>
    //                        <tr>
    //                           <th>2. 맡기실 시간을 선택해 주세요.{set_during_time}</th>
    //                           <td style={{ fontSize: '15px', padding: '10px 0px' }}>
    //                              <select className="setTime_hour"
    //                                 defaultValue={this.props.setTime_hour}
    //                                 style={{ fontSize: '17px', padding: '5px', border: '1px solid', margin: '0px 5px' }}
    //                              >
    //                                 {set_select}
    //                              </select>시
    //                              <select className="setTime_min"
    //                                 defaultValue={this.props.setTime_min}
    //                                 style={{ fontSize: '17px', padding: '5px', border: '1px solid', margin: '0px 5px' }}
    //                              >
    //                                 <option key={0}>0</option>
    //                                 <option key={30}>30</option>
    //                              </select>분
    //                           </td>
    //                        </tr>
    //                        <tr>
    //                           <th>3. 찾아가실 시간을 선택해 주세요.{get_during_time}</th>
    //                           <td style={{ fontSize: '15px', padding: '10px 0px' }}>
    //                              <select className="getTime_hour"
    //                                 defaultValue={this.props.getTime_hour}
    //                                 style={{ fontSize: '17px', padding: '5px', border: '1px solid', margin: '0px 5px' }}
    //                              >
    //                                 {get_select}
    //                              </select>시
    //                              <select className="getTime_min"
    //                                 defaultValue={this.props.getTime_min}
    //                                 style={{ fontSize: '17px', padding: '5px', border: '1px solid', margin: '0px 5px' }}
    //                              >
    //                                 <option key={0}>0</option>
    //                                 <option key={30}>30</option>
    //                              </select>분
    //                           </td>
    //                        </tr>

    //                     </tbody>
    //                  </table>


    //               </div>
    //            </div>
    //            {/* {checkedCourse} */}
    //            <button style={{ fontSize: '20px', margin: '0px 10px' }} className="btn btn-info" onClick={this.props.prevStep}>← 이전</button>
    //            <button style={{ fontSize: '20px', margin: '0px 10px' }} className="btn btn-info" onClick={this.props.nextStep}>다음 →</button>
    //         </div>
    //      );
    //   }

      // 정보 입력
      else {
         // 이메일 전화번호 초기값
         var default_email = "";
         var default_tel1 = "", default_tel2 = "", default_tel3 = "";
         if (this.props.is_member) {  //회원이면
            default_email = this.props.user_information.email;
            default_tel1 = this.props.user_information.tel.slice(0, 3);
            default_tel2 = this.props.user_information.tel.slice(3, this.props.user_information.tel.length - 4);
            default_tel3 = this.props.user_information.tel.slice(this.props.user_information.tel.length - 4, this.props.user_information.tel.length);
         } else if (this.props.check_email) {
            default_email = $('.input_email').val();
         }

         // description 데이터
         var description_name = "요청사항"
         if (this.props.is_delivery) {
            description_name = "주소 (상세하게)"
         }

         return (
            <div>
               <div style={{ height: '600px', overflow: 'auto' }}>
                  <h3>이용자 정보 입력</h3>
                  <hr />

                  <div>
                     <div style={{ textAlign: 'left', width: '100%', maxWidth: '500px', margin: '0 auto' }}>
                        <label className="customLabel">핸드폰 번호</label>
                        <input id="input_tel1"
                           className="customInput"
                           defaultValue={default_tel1}
                           maxLength='3'
                           type="text"
                           style={{ width: '30%', display: 'inline-block' }}
                        /> -
                        <input id="input_tel2"
                           className="customInput"
                           defaultValue={default_tel2}
                           maxLength='4'
                           type="text"
                           style={{ width: '30%', display: 'inline-block' }}
                        /> -
                        <input id="input_tel3"
                           className="customInput"
                           defaultValue={default_tel3}
                           maxLength='4'
                           type="text"
                           style={{ width: '30%', display: 'inline-block' }}
                        />

                        <label className="customLabel">이메일</label>
                        <input id="sending_email"
                           className="customInput"
                           defaultValue={default_email}
                           type="text"
                           style={{ width: '100%' }}
                        />

                        <label className="customLabel">비밀번호 6자리 <span style={{ fontSize: '12px' }}>(예약 조회 때 사용합니다.)</span></label>
                        <input id="reservation_pw"
                           className="customInput"
                           type="text"
                           maxLength='6'
                           onChange={this.props.onChangePw}
                           value={this.props.input_simple_pw}
                           style={{ width: '100%' }}
                        />

                        <label className="customLabel">{description_name}</label>
                        <textarea id="description"
                           className="customInput"
                           type="text"
                           style={{ width: '100%' }}
                        />

                     </div>
                  </div>
               </div>
               {/* {checkedCourse} */}
               <button style={{ fontSize: '20px', margin: '0px 10px' }} className="btn btn-info" onClick={this.props.prevStep}>← 이전</button>
               <button style={{ fontSize: '20px', margin: '0px 10px' }} className="btn btn-danger" onClick={this.props.tryReservation}>예약하기</button>
            </div>
         );
      }
   }
}