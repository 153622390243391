/* IE 11 이 부분 추가 */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
/* ---------- */


import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
