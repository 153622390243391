import React, { Component } from 'react'
import $ from 'jquery'

import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ko from 'date-fns/locale/ko'

registerLocale('ko', ko);

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class Reservation extends Component {
   constructor(props) {
      super(props);

      var tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate()+1);
      this.state = ({
         store_id: this.props.match.params.storeId,
         is_member: false,
         check_email: false,
         selected_date: tomorrow,
         tomorrow: tomorrow,
         reservation_information: [],    // 예약 정보
         user_information: [],           // 멤버 정보
         room_list: [],                  // 룸 정보
         reservation_setting: [],        // 예약 설정 정보
         input_openTime: "",             // 가맹점 영업 시작시간
         input_closeTime: "",            // 가맹점 영업 마감시간
         input_simple_pw: "",
         roomIndex: 0
      });
      this.handleDateChange = this.handleDateChange.bind(this);
      this.onChangePw = this.onChangePw.bind(this)
   }

   /* 예약하기 버튼 이벤트 */
   tryReservation() {
      var startTimeHour = parseInt($('.start_hour').val());
      var startTimeMin = parseInt($('.start_min').val());
      var endTimeHour = parseInt($('.end_hour').val());
      var endTimeMin = parseInt($('.end_min').val());

      if (startTimeMin === 30) startTimeMin = 0.5;
      else startTimeMin = 0;
      if (endTimeMin === 30) endTimeMin = 0.5;
      else endTimeMin = 0;

      // 시작시간이 종료시간보다 클 경우 
      var timeDiff = (endTimeHour + endTimeMin) - (startTimeHour + startTimeMin);
      if(timeDiff<= 0) {
         alert("시작시간이 종료시간보다 늦습니다.");
         return;
      }
      // 해당 룸의 최소 시간보다 작을 경우
      if(timeDiff < this.state.room_list[this.state.roomIndex].minTime) {
         alert(this.state.room_list[this.state.roomIndex].name + "의 최소 시간은 " + this.state.room_list[this.state.roomIndex].minTime+"시간 입니다.");
         return;
      }

      // 시작시간이 이미 지난 시간일 경우
      var today = new Date();
      if (this.state.selected_date.getMonth() === today.getMonth()) {
         if (this.state.selected_date.getDate() === today.getDate()) {
            if (today.getHours() > startTimeHour) {
               alert("이미 지난 시간입니다.");
               return;
            } else if (today.getHours() === startTimeHour) {
               if(startTimeMin === 0.5) startTimeMin = 30;
               if (today.getMinutes() > startTimeMin) {
                  alert("이미 지난 시간입니다.");
                  return;
               }
            }
         }
      }

      // 비밀번호가 6자리가 안될 경우
      if ($('.reservation_pw').val().length < 6) {
         alert("비밀번호 6자리를 입력해 주세요.");
         return;
      }

      // 인원수 예외처리
      if ($('.number_of_people').val() > this.state.room_list[this.state.roomIndex].maxNumPerson
         || $('.number_of_people') <= 0) {
         alert("인원수를 확인해 주세요.");
         return;
      }

      window.sessionStorage.clear();
      window.sessionStorage.setItem("bucket", JSON.stringify({
         receiptNumber: this.state.selected_date.toISOString(),
         storeId: this.state.store_id,
         roomId: this.state.room_list[this.state.roomIndex].id,
         startDate: this.state.selected_date.toISOString().slice(0, 10) + "T" + $('.start_hour').val() + ":" + $('.start_min').val() + ":00.000Z",
         endDate: this.state.selected_date.toISOString().slice(0, 10) + "T" + $('.end_hour').val() + ":" + $('.end_min').val() + ":00.000Z",
         numOfPeople: $('.number_of_people').val(),
         tel: $('.input_tel1').val() + $('.input_tel2').val() + $('.input_tel3').val(),
         email: $('.sending_email').val(),
         password: $('.reservation_pw').val()
      }));
      window.location.href = "/store/" + this.state.store_id + "/payment/room";
   }

   /* 날짜 선택 이벤트 */
   handleDateChange(date) {
      this.setState({
         selected_date: date
      });

      var selectedDate = date.toISOString().slice(0, 10);

      fetch(url + "/api/reservation/room/store/" + this.state.store_id + "/" + selectedDate)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            this.setState({ reservation_information: value.data });    //메인설정 정보
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
   }

   /* 룸 선택 이벤트 */
   handleRoomChange() {
      this.setState({ roomIndex: $('.selectRoom').val() });
   }

   /* 간편비밀번호 숫자만 허용 */
   onChangePw(e) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         this.setState({ input_simple_pw: e.target.value })
      }
   }

   /* 비회원 예약 버튼 */
   notMemberReserve() {
      $('.loginBtn').prop('hidden', true);
      $('.input_email').prop('hidden', false);
      $('.sendingEmailBtn').prop('hidden', false);
   }

   /* 이메일 인증번호 전송 */
   sendingMail() {
      // 이메일 체크
      var email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (email.test($('.input_email').val().trim())) {
         $('.input_email').css("border", "1px solid black");
      } else {
         $('.input_email').css("border", "2px solid #B10000");
         alert("이메일 형식이 아닙니다.");
         return;
      }
      $('.sendingEmailBtn').prop('disabled', true);
      $('.sendingEmailBtn').text("전송중");

      var requestOptions = {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      fetch(url + "/api/members/email/" + $('.input_email').val(), requestOptions)
         .then(response => response.json())
         .then(value => {
            alert("인증번호가 전송되었습니다.");
            $('.input_email_key').prop('hidden', false);
            $('.checkingEmailBtn').prop('hidden', false);

            $('.sendingEmailBtn').prop('disabled', false);
            $('.sendingEmailBtn').text("재전송");
         })
         .catch(err => alert("err: " + err));
   }

   /* 이메일 인증번호 확인 */
   checkingEmail() {
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      fetch(url + "/api/members/email/" + $('.input_email_key').val(), requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value === true) {
               alert("이메일 인증이 완료되었습니다.");
               this.setState({ check_email: true });
            } else {
               alert("인증번호가 다릅니다.");
            }
         })
         .catch(err => alert("err: " + err));
   }

   /* 가맹점 예약 조회 */
   callGetApi() {
      var tomorrow = this.state.tomorrow.toISOString().slice(0, 10);

      // 예약 정보
      fetch(url + "/api/reservation/room/store/" + this.state.store_id + "/" + tomorrow)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            this.setState({ reservation_information: value.data });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });

      // 룸 정보
      fetch(url + "/api/room/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ room_list: value.data });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });

      // 가맹점 정보
      fetch(url + "/api/account/store-inform/simple/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({
               input_openTime: value.data[0].openTime.slice(0, 5),
               input_closeTime: value.data[0].closeTime.slice(0, 5)
            });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });

      var requestOptions = {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      // 회원정보
      fetch(url + "/api/members/reservation", requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               this.setState({
                  is_member: true,
                  user_information: value.data[0]
               });
            }
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
      // 예약 설정 정보
      fetch(url + "/api/store-setting/reservation/" + this.state.store_id, requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               this.setState({
                  reservation_setting: value.data
               });
            }
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
   }

   componentDidMount() {
      this.callGetApi();
   }

   render() {
      // 날짜 선택
      const ExampleCustomInput = ({ value, onClick }) => (
         <button className="customButton3" style={{ padding: '10px' }} onClick={onClick}>
            예약 날짜: {value}
         </button>
      );

      // 예약 현황 리스트
      var reservation_list = [];
      if (this.state.reservation_information) {
         for (var i = 0; i < this.state.reservation_information.length; i++) {
            // 결제 안된 예약은 표시 X
            if (!this.state.reservation_information[i].isPaid) { continue; }

            var roomName = "";
            for (var j = 0; j < this.state.room_list.length; j++) {
               if (this.state.room_list[j].id === this.state.reservation_information[i].roomId) {
                  roomName = this.state.room_list[j].name;
               }
            }
            reservation_list.push(
               <tr key={i}>
                  <th style={{ border: '1px solid' }}>
                     {roomName}
                  </th>
                  <th style={{ border: '1px solid' }}>
                     {this.state.reservation_information[i].startDate.slice(11, 16)} ~ {this.state.reservation_information[i].endDate.slice(11, 16)}
                  </th>
               </tr>
            );
         }
      }
      if (reservation_list.length < 1) {
         reservation_list.push(
            <tr key={1}>
               <th colSpan='2' style={{ border: '1px solid' }}>해당 기기의 예약이 없습니다.</th>
            </tr>
         );
      }

      // 룸 선택 or 인원수 
      var room_list = [];
      var maxNumPerson = "";
      for (var i = 0; i < this.state.room_list.length; i++) {
         if(this.state.room_list[i].available) {
            room_list.push(
               <option value={i} key={i}>
                  {this.state.room_list[i].name}
               </option>
            );
            maxNumPerson = this.state.room_list[this.state.roomIndex].maxNumPerson;
         }
      }

      // 이메일 전화번호 초기값
      var default_email = "";
      var default_tel1 = "", default_tel2 = "", default_tel3 = "";
      if (this.state.is_member) {  //회원이면
         default_email = this.state.user_information.email;
         default_tel1 = this.state.user_information.tel.slice(0, 3);
         default_tel2 = this.state.user_information.tel.slice(3, this.state.user_information.tel.length - 4);
         default_tel3 = this.state.user_information.tel.slice(this.state.user_information.tel.length - 4, this.state.user_information.tel.length);
      } else if (this.state.check_email) {
         default_email = $('.input_email').val();
      }

      // 영업 시간
      var startHour = [], endHour = [];
      if (this.state.input_openTime !== "") {
         var rooms_minTime = 0;
         if (this.state.room_list.length > 0)
            rooms_minTime = this.state.room_list[this.state.roomIndex].minTime;
         var input_openHour = this.state.input_openTime.slice(0, 2);
         var input_closeHour = this.state.input_closeTime.slice(0, 2);

         //시작 시간
         for (var i = parseInt(input_openHour); i <= parseInt(input_closeHour) - parseInt(rooms_minTime); i++) {
            var hourStr = i.toString();
            if (hourStr.length === 1) { hourStr = "0" + hourStr; }

            startHour.push(<option key={i} value={hourStr}>{i}</option>);
         }
         // 종료 시간
         for (var j = parseInt(input_openHour) + parseInt(rooms_minTime); j <= parseInt(input_closeHour); j++) {
            var hourStr = j.toString();
            if (hourStr.length === 1) { hourStr = "0" + hourStr; }

            endHour.push(<option key={j} value={hourStr}>{j}</option>);
         }
      }

      var maxDate = new Date();
      if (this.state.reservation_setting.length > 0) {
         maxDate.setDate(maxDate.getDate() + this.state.reservation_setting[0].maxDate);
      }

      if (this.state.is_member || this.state.check_email) {
         return (
            <div style={{ minHeight: '80vh' }}>
               <h1 style={{ margin: '20px 0px' }}>예약하기</h1>
               <div style={{ textAlign: 'center' }}>
                  <DatePicker
                     dateFormat="yyyy/MM/dd(eee)"
                     minDate={this.state.tomorrow}
                     selected={this.state.selected_date}
                     locale='ko'
                     onChange={this.handleDateChange}
                     customInput={<ExampleCustomInput />}
                     maxDate={maxDate}
                  />
                  <p style={{ color: 'orange' }}>
                     <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-up-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                     </svg>
                            날짜를 선택하세요
                            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-caret-up-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                     </svg>
                  </p>
               </div>
               <div className="row">
                  <div className="col-md-5" style={{ textAlign: 'center' }}>
                     <h3 style={{ marginTop: '20px' }}>예약현황</h3>
                     <table style={{ width: '100%' }}>
                        <tbody>
                           <tr>
                              <th style={{ border: '1px solid' }}>날짜</th>
                              <th style={{ border: '1px solid' }}>시간</th>
                           </tr>
                           {reservation_list}
                        </tbody>
                     </table>
                  </div>
                  <div className="col-md-7" style={{ textAlign: 'center' }}>
                     <h3 style={{ marginTop: '20px' }}>예약하기</h3>
                     <table style={{ width: '100%' }}>
                        <tbody>
                           <tr>
                              <td style={{ border: '1px solid' }}>선택</td>
                              <td style={{ border: '1px solid' }}>
                                 <select
                                    className="selectRoom"
                                    style={{ marginLeft: '5px' }}
                                    onChange={this.handleRoomChange.bind(this)}
                                 >
                                    {room_list}
                                 </select>
                              </td>
                           </tr>
                           <tr>
                              <td style={{ border: '1px solid' }}>날짜</td>
                              <td style={{ border: '1px solid' }}>
                                 {this.state.selected_date.toISOString().slice(0, 10)}
                              </td>
                           </tr>
                           <tr>
                              <td style={{ border: '1px solid' }}>시간</td>
                              <td style={{ border: '1px solid' }}>
                                 <select className="start_hour">
                                    {startHour}
                                 </select>
                                 <span> : </span>
                                 <select className="start_min">
                                    <option value="00">0</option>
                                    <option value="30">30</option>
                                 </select>
                                 <span> ~ </span>
                                 <select className="end_hour">
                                    {endHour}
                                 </select>
                                 <span> : </span>
                                 <select className="end_min">
                                    <option value="00">0</option>
                                    <option value="30">30</option>
                                 </select>
                              </td>
                           </tr>
                           <tr>
                              <td style={{ border: '1px solid' }}>인원수</td>
                              <td style={{ border: '1px solid' }}>
                                 <input
                                    type="number"
                                    className="number_of_people"
                                    max={maxNumPerson}
                                    min='1'
                                 />
                              </td>
                           </tr>
                           <tr>
                              <td style={{ border: '1px solid' }}>전화번호</td>
                              <td style={{ border: '1px solid' }}>
                                 <input
                                    className="input_tel1"
                                    type="text"
                                    defaultValue={default_tel1}
                                    style={{ width: '50px' }} maxLength='3'
                                 />
                                 <span> - </span>
                                 <input
                                    className="input_tel2"
                                    type="text"
                                    defaultValue={default_tel2}
                                    style={{ width: '50px' }} maxLength='4'
                                 />
                                 <span> - </span>
                                 <input
                                    className="input_tel3"
                                    type="text"
                                    defaultValue={default_tel3}
                                    style={{ width: '50px' }} maxLength='4'
                                 />
                              </td>
                           </tr>
                           <tr>
                              <td style={{ border: '1px solid' }}>이메일</td>
                              <td style={{ border: '1px solid' }}>
                                 <input
                                    type="text"
                                    className="sending_email"
                                    defaultValue={default_email}
                                 />
                              </td>
                           </tr>
                           <tr>
                              <td style={{ border: '1px solid' }}>비밀번호 6자리<br/><span style={{ fontSize: '12px' }}>(예약 조회 때 사용합니다.)</span></td>
                              <td style={{ border: '1px solid' }}>
                                 <input
                                    type="text"
                                    className="reservation_pw"
                                    maxLength='6'
                                    onChange={this.onChangePw}
                                    value={this.state.input_simple_pw}
                                 />
                              </td>
                           </tr>
                        </tbody>
                     </table>
                  </div>
               </div>
               <div style={{ textAlign: 'center', marginTop: '50px' }}>
                  <button className="customButton"
                     style={{fontSize:'20px', padding:'15px 10px'}}
                     onClick={this.tryReservation.bind(this)}
                  >예약하기</button>
               </div>
            </div>
         );
      } else {
         var login_url = "/login?rtn=" + document.location.href;
         return (
            <div style={{ minHeight: '80vh', alignContent: 'center' }}>
               <div style={{ width: '100%', margin: '0 auto', textAlign: 'center', paddingTop: '200px' }}>
                  <a href={login_url}>
                     <button className="customButton loginBtn"
                        style={{ marginRight: '20px', width: '120px', height: '120px', fontSize: '20px', borderRadius: '15px' }}
                     >로그인</button>
                  </a>
                  <button className="customButton2 notMemberBtn"
                     style={{ width: '120px', height: '120px', fontSize: '20px', borderRadius: '15px' }}
                     onClick={this.notMemberReserve}
                  >비회원 예약</button>
                  <br />
                  <input
                     type="text"
                     className="input_email"
                     placeholder="이메일을 입력해주세요."
                     hidden
                     style={{ width: '50%', margin: '30px auto', border: '2px solid', fontSize: '17px', padding: '5px' }}
                  />
                  <button
                     className="sendingEmailBtn customButton3"
                     hidden
                     style={{ width: '100px', height: '50px', marginLeft: '10px' }}
                     onClick={this.sendingMail}
                  >전송하기</button>
                  <input
                     type="text"
                     className="input_email_key"
                     hidden
                     style={{ width: '50%', margin: '30px auto', border: '2px solid', fontSize: '17px', padding: '5px' }}
                  />
                  <button
                     className="checkingEmailBtn customButton3"
                     hidden
                     style={{ width: '100px', height: '50px', marginLeft: '10px' }}
                     onClick={this.checkingEmail.bind(this)}
                  >확인하기</button>
               </div>
            </div>
         );
      }
   }
}