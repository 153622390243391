import React, { Component, useEffect } from 'react'
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';
import $ from 'jquery'
import { Map, MapMarker } from 'react-kakao-maps-sdk';


export default class Root extends Component {
  constructor(props) {
    super(props)

    this.state = ({
      input_information: [],
      input_detail:[],
      lat: 0,
      lng: 0,
      addr: "",
      is_service_selected: false
    })
  }


  enterPage(index) {
    if (index === 1) {
      document.location.href = "/wash";
    } else if(index === 2) {
      document.location.href = "/cafe";
    }
  }

  render() {
    //가맹점 버튼

    return (
      <div>
        {/* Top Navigation */}
        <div style={{ background: '#112', padding: '20px', position: 'fixed', width: '100%', zIndex: '10' }}>
          <h3 style={{ color: '#8DAFCE', fontSize: '45px', fontFamily: 'Dancing Script' }} href="#header">KW innovation</h3>
        </div>

        {/* Selecting Service */}
        <div id="view1" className="tiledBackground" style={{ marginTop: '-60px', background: 'url(../img/intro-bg.jpg) no-repeat center/cover', overflowY: 'auto' }}>
          <div style={{ textAlign: 'center' }}>
            <div className="container" style={{ height: '500px' }}>
              <div style={{ height: '150px' }} />
              <h2 style={{ color: '#BBE' }}>서비스를 선택해 주세요.</h2>
              <hr style={{ borderColor: '#C97' }} />

              <div className="row" style={{ margin: '20px 0px' }}>
                <div className="col-md-6">
                  <button className="customButton3"
                    onClick={this.enterPage.bind(this, 1)}
                    style={{ width: '200px', fontSize: '25px', padding: '20px 0px', margin: '20px' }}
                  >
                    <img src="./img/laundry-icon.png" width='100px' alt="" />
                    <hr style={{borderColor:'#999'}}/>
                    세탁
                  </button>
                </div>
                <div className="col-md-6">
                  <button className="customButton3"
                    onClick={this.enterPage.bind(this, 2)}
                    style={{ width: '200px', fontSize: '25px', padding: '20px 0px', margin: '20px' }}
                  >
                    <img src="./img/cafe-icon.png" width='100px' alt="" />
                    <hr style={{borderColor:'#999'}}/>
                    카페
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>

        {/* Footer */}
        <div id="footer">
          <div className="text-center row" style={{ margin: '0px', background: '#112' }}>

          <div className="col-md-12">
              <p style={{ margin: '0px', color: '#8DAFCE' }}>상호명 : 모지트 | 주소 : 서울특별시 광진구 군자로 149 1층  </p>              
              <p style={{ margin: '0px', color: '#8DAFCE' }}>대표자명:윤왕근 | 사업자번호 : 616-55-00178 </p>           
              <p style={{ margin: '0px', color: '#8DAFCE' }}>고객센터 전화 :02-464-5024 </p>
              <p style={{ margin: '0px', color: '#8DAFCE' }}> &copy; 2020 KWINNOVATION. All rights reserved</p>
            
            </div>

          </div>
        </div>
      </div>
    );
  }
}
