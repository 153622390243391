import React, { Component, useEffect } from 'react'
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';
import $ from 'jquery'
import { Map, MapMarker } from 'react-kakao-maps-sdk';

export default class Root extends Component {
	constructor(props) {
		super(props)
		this.state = ({
			input_information: [],
			input_detail: [],
		})
	}
	callGetApi = () =>{
		alert("인증이 완료되었습니다");
	}

	componentDidMount() {
    		this.callGetApi();
  	}

	render() {
		return (<div></div>)
	}

}