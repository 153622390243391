import React, { Component } from 'react'
import $ from 'jquery'

export default class FindAccount extends Component {
   constructor(props) {
      super(props)
      this.state = ({
         input_tel: "",
         selected_number: 1      //페이지 번호 (1: 아이디찾기, 2: 비밀번호 찾기)
      })
      this.telOnChange = this.telOnChange.bind(this)
   }

   // 전화번호 숫자만 허용
   telOnChange(e) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         this.setState({ input_tel: e.target.value })
      }
   }

   // 페이지 변경 (1: 아이디찾기, 2: 비밀번호 찾기)
   changePage(num) {
      if (num === 1) {
         $('.accountButton').css('background', '#FFECD3');
         $('.accountButton').css('borderBottom', 'none');

         $('.passwordButton').css('background', '#ABB792');
         $('.passwordButton').css('borderBottom', '1px solid');
      } else {
         $('.passwordButton').css('background', '#FFECD3');
         $('.passwordButton').css('borderBottom', 'none');

         $('.accountButton').css('background', '#ABB792');
         $('.accountButton').css('borderBottom', '1px solid');
      }

      // 값 초기화
      $('.input_account').val("");
      $('.input_email').val("");
      $('.input_tel').val("");
      this.setState({ selected_number: num, input_tel: "" });
   }

   // 뒤로가기 버튼
   goBack() {
      document.location.href = "./";     //이전페이지로 이동
   }

   // 아이디 찾기 버튼
   findId() {
      // 공백 확인
      if ($('.input_email').val() === "" || $('.input_tel').val() === "") {
         alert("입력란을 전부 채워주세요.");
         return;
      }

      // 이메일 형식 확인
      var email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (!email.test($('.input_email').val().trim())) {
         alert("이메일 형식이 아닙니다.");
         return;
      }

      // 버튼 변경
      $('.loadingButton').prop('hidden', false);
      $('.findAccountButton').prop('hidden', true);

      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            email: $('.input_email').val(),
            tel: $('.input_tel').val()
         }),
         mode: 'cors',
         credentials: 'include'
      };

      fetch(this.props.url + "/api/members/find/account", requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) alert("전송 성공! 이메일을 확인해주세요.");
            else if (value.status === 401) alert(value.error + "");
            else alert("통신 오류");

            // 버튼 변경
            $('.loadingButton').prop('hidden', true);
            $('.findAccountButton').prop('hidden', false);
         })
         .catch(err => alert("err: " + err));
   }

   // 비밀번호 초기화 버튼
   findPassword() {
      // 공백 확인
      if ($('.input_email').val() === "" || $('.input_tel').val() === "" || $('.input_account').val() === "") {
         alert("입력란을 전부 채워주세요.");
         return;
      }

      // 이메일 형식 확인
      var email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (!email.test($('.input_email').val().trim())) {
         alert("이메일 형식이 아닙니다.");
         return;
      }

      // 버튼 변경
      $('.loadingButton').prop('hidden', false);
      $('.findAccountButton').prop('hidden', true);

      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            email: $('.input_email').val(),
            tel: $('.input_tel').val()
         }),
         mode: 'cors',
         credentials: 'include'
      };

      fetch(this.props.url + "/api/members/find/password/" + $('.input_account').val(), requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) alert("전송 성공! 이메일을 확인해주세요.");
            else if (value.status === 401) alert(value.error + "");
            else alert("통신 오류");

            // 버튼 변경
            $('.loadingButton').prop('hidden', true);
            $('.findAccountButton').prop('hidden', false);
         })
         .catch(err => alert("err: " + err));
   }

   componentDidMount() {
      $('body').css('background', 'linear-gradient(to top, #accbee 0%, #e7f0fd 100%) no-repeat center center fixed');
   }

   render() {
      // page content
      var content = [];

      if (this.state.selected_number === 1) {
         content = (
            <div>
               <h3 style={{paddingTop:'30px', paddingBottom:'15px'}}>이메일 인증</h3>
               <div style={{ textAlign: 'left', marginLeft: '10px', marginBottom: '30px' }}>
                  <p style={{margin:'0px'}}>✔ 가입하실 때 입력하셨던 <b>이메일</b>과 <b>생년월일</b>을 입력해 주세요.</p>
                  <p style={{margin:'0px'}}>✔ 아이디 찾기 버튼을 클릭 후 이메일에서 <b>받은 메일함</b>을 확인해 주세요.</p>
               </div>
               <table style={{width:'100%', fontSize:'18px'}}>
                  <tbody>
                     <tr>
                        <th style={{padding:'10px 0px'}}>이메일</th>
                        <th>
                           <input
                              className="input_email"
                              placeholder="aaaa@bbb.com"
                              style={{ padding: '5px 0px', width: '200px', border:'1px solid gray' }}
                           />
                        </th>
                     </tr>
                     <tr>
                        <th style={{padding:'10px 0px'}}>전화번호</th>
                        <th>
                           <input
                              className="input_tel"
                              value={this.state.input_tel}
                              onChange={this.telOnChange}
                              maxLength='11'
                              placeholder="'-' 빼고 입력"
                              style={{ padding: '5px 0px', width: '200px', border:'1px solid gray' }}
                           />
                        </th>
                     </tr>
                  </tbody>
               </table>
               
               <br />
               <button
                  style={{ margin: '30px 10px', padding: '10px 15px', fontSize: '20px' }}
                  className="customButton findAccountButton"
                  onClick={this.findId.bind(this)}
               >아이디 찾기</button>
               <button
                  style={{ margin: '30px 10px', padding: '10px 15px', fontSize: '20px' }}
                  className="customButton3 findAccountButton"
                  onClick={this.goBack}
               >뒤로가기</button>
               <button
                  hidden
                  style={{ margin: '30px 0px', padding: '10px 15px', fontSize: '20px' }}
                  className="customButton3 loadingButton"
               >확인중</button>
            </div>
         );
      } else {
         content = (
            <div>
               <h3 style={{ paddingTop: '30px', paddingBottom: '15px' }}>이메일 인증</h3>
               <div style={{ textAlign: 'left', marginLeft: '10px', marginBottom: '30px' }}>
                  <p style={{ margin: '0px' }}>✔ 가입하실 때 입력하셨던 <b>아이디</b>와 <b>이메일, 생년월일</b>을 입력해 주세요.</p>
                  <p style={{ margin: '0px' }}>✔ 비밀번호 초기화 버튼을 클릭 후 이메일에서 <b>받은 메일함</b>을 확인해 주세요.</p>
               </div>
               <table style={{ width: '100%', fontSize: '18px' }}>
                  <tbody>
                     <tr>
                        <th style={{ padding: '10px 0px' }}>아이디</th>
                        <th>
                           <input
                              className="input_account"
                              placeholder="아이디"
                              style={{ padding: '5px 0px', width: '200px', border: '1px solid gray' }}
                           />
                        </th>
                     </tr>
                     <tr>
                        <th style={{ padding: '10px 0px' }}>이메일</th>
                        <th>
                           <input
                              className="input_email"
                              placeholder="aaaa@bbb.com"
                              style={{ padding: '5px 0px', width: '200px', border: '1px solid gray' }}
                           />
                        </th>
                     </tr>
                     <tr>
                        <th style={{ padding: '10px 0px' }}>전화번호</th>
                        <th>
                           <input
                              className="input_tel"
                              value={this.state.input_tel}
                              onChange={this.telOnChange}
                              maxLength='11'
                              placeholder="'-' 빼고 입력"
                              style={{ padding: '5px 0px', width: '200px', border: '1px solid gray' }}
                           />
                        </th>
                     </tr>
                  </tbody>
               </table>

               <br />
               <button
                  style={{ margin: '30px 10px', padding: '10px 15px', fontSize: '20px' }}
                  className="customButton findAccountButton"
                  onClick={this.findPassword.bind(this)}
               >비밀번호 초기화</button>
               <button
                  style={{ margin: '30px 10px', padding: '10px 15px', fontSize: '20px' }}
                  className="customButton3 findAccountButton"
                  onClick={this.goBack}
               >뒤로가기</button>
               <button
                  hidden
                  style={{ margin: '30px 0px', padding: '10px 15px', fontSize: '20px' }}
                  className="customButton3 loadingButton"
               >확인중</button>
            </div>
         );
      }


      return (
         <div style={{ textAlign: 'center' }}>
            <div
               className="text-center"
               style={{
                  textAlign: 'center', margin: '0px auto', paddingTop: '50px',
                  maxWidth: '600px', minWidth: '250px', width: '90%'
               }}
            >
               <h1 style={{ marginBottom: '30px', color: '#6610f2' }}>KW INNOVATION</h1>
               <div style={{ background: "#ABB792", border:'1px solid' }}>
                  {/* navigation */}
                  <div>
                     <span className="accountButton"
                        onClick={this.changePage.bind(this, 1)}
                        style={{
                           float: 'left', border: '1px solid',
                           fontSize: '20px', cursor: 'pointer',
                           padding: '5px 10px', background: '#FFECD3', borderBottom: 'none'
                        }}
                     >아이디 찾기</span>
                     <span className="passwordButton"
                        onClick={this.changePage.bind(this, 2)}
                        style={{
                           float: 'left', border: '1px solid',
                           fontSize: '20px', cursor: 'pointer',
                           padding: '5px 10px'
                        }}
                     >비밀번호 찾기</span>
                  </div>

                  <div className="clearfix" />

                  {/* content */}
                  <div style={{ background: '#FFECD3' }}>
                     {content}
                  </div>
               </div>
            </div>
         </div>
      );
   }
}