import React, { Component } from 'react';

export default class Footer extends Component {
    // onClick() {
    //     document.cookie = "storeInfo=; expires=Thu, 01 Jan 1999 00:00:10 GMT;"
    // }
    render() {
        return (
            <footer className="bg-dark" style={{marginTop:'30px', padding:'30px 0px'}}>
                <div className="container">
                    <p className="m-0 text-center text-white">Copyright &copy; <a href="/">KW Innovation</a> 2020</p>
                </div>
            </footer>
        );
    }
}
