import React, { Component } from 'react';
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class About extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            input_information: [],
            store_id: this.props.match.params.storeId
        })
    }

    callGetApi() {
        fetch(url + "/api/account/store-inform/simple/" + this.state.store_id)
            .then(response => response.json())
            .then(value => {
                this.setState({ input_information: value.data });    //메인설정 정보
            })
            .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });
    }
    componentDidMount() {
        this.callGetApi();
    }

    render() {
        var data = [];
        if (this.state.input_information.length > 0) {
            data.push(
                <div key={1} className="col-md-3">
                    <h2>{this.state.input_information[0].storeName}</h2>
                    <br />
                    <p>주소: {this.state.input_information[0].storeAddress}</p>
                    <p>연락처: {this.state.input_information[0].storeTel}</p>
                    <p>영업시간: {this.state.input_information[0].openTime.slice(0,5)} ~ {this.state.input_information[0].closeTime.slice(0,5)}</p>
                </div>
            );
        }

        return (
            <div style={{ minHeight: '80vh' }}>
                <h1 style={{margin:'30px 0px'}}>가게 정보</h1>
                <div className="row">
                    <div className="col-md-8">
                        <RenderAfterNavermapsLoaded
                            ncpClientId={'iraqumfj7a'} // 자신의 네이버 계정에서 발급받은 Client ID
                            error={<p>Maps Load Error</p>}
                            loading={<p>Maps Loading...</p>}
                        >
                            <NaverMapAPI
                                temp="hi"
                                data={this.state.input_information}
                            />
                        </RenderAfterNavermapsLoaded>
                    </div>
                    {data}
                </div>
            </div>
        );
    }
}

class NaverMapAPI extends Component {
    render() {
        const navermaps = window.naver.maps;

        if(this.props.data.length>0) {
            var input_lat = this.props.data[0].lat;
            var input_lng = this.props.data[0].lng;
        }

        return (
            <NaverMap
                mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
                style={{
                    width: '100%', // 네이버지도 가로 길이
                    height: '500px' // 네이버지도 세로 길이
                }}
                defaultCenter={{ lat: input_lat, lng: input_lng}} // 지도 초기 위치
                defaultZoom={15} // 지도 초기 확대 배율
            >
                <Marker
                    position={new navermaps.LatLng(input_lat, input_lng)}
                    // onClick={() => { alert(this.props.temp); }}
                    title="hi"
                    content="<p>ghihi</p>"
                />
            </NaverMap>
        );
    }
}