import React, { Component } from 'react';

export default class Cancel extends Component {
    callGetApi() {
        // var requestOptions = {
        //     headers: {
        //         "Authorization": "KakaoAK 20844e233fd06b067fb74eb6d63468f3",
        //         "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
        //     }
        // };

        // //package.json의 proxy 기반
        // //원래는 https://kapi.kakao.com/v1/payment/ready ~~
        // fetch("/v1/payment/ready"
        //     + "?cid=TC0ONETIME"
        //     + "&partner_order_id=hyun"
        //     + "&partner_user_id=user"
        //     + "&item_name=아메리카노"
        //     + "&quantity=1"
        //     + "&total_amount=" + this.props.totalPrice
        //     + "&tax_free_amount=0"
        //     + "&approval_url=http://kwininno.iptime.org"
        //     + "&fail_url=http://kwininno.iptime.org"
        //     + "&cancel_url=http://kwininno.iptime.org", requestOptions)
        //     .then(response => response.json())
        //     .then(result => {
        //         console.log(result);
        //         window.open(result.next_redirect_pc_url);
        //         this.setState({ tid: result.tid });
        //         // localStorage.tid = result.tid;
        //     })
        //     .catch(error => console.log('error', error));
    }

    componentDidMount() {
        this.callGetApi();
    }

    render() {
        return (
            <div style={{ minHeight: '80vh' }}>
                <h1>결제가 취소되었습니다.</h1>
            </div>
        );
    }
}