import React, { Component } from 'react'
import GoogleLogin from 'react-google-login';
import KaKaoLogin from 'react-kakao-login';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import FacebookLogin from '@greatsumini/react-facebook-login';
import NaverLogin from 'react-naver-login';
import $ from 'jquery'

const ServerConfig = require('../ServerConfig');
const { naver } = window;

const KaKaoBtn = styled(KaKaoLogin)`
  padding: 0;
  width: 300px;
  height: 45px;
  line-height: 44px;
  color: #783c00;
  background-color: #ffeb00;
  border: 1px solid transparent;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.2);
  }
`;

export default class Login extends Component {


   onFailGoogle(result){
      console.log(result);
      //document.location.href = "./";
   }

   onSuccessGoogle(response){
      console.log(response.profileObj)
      // public String email;
      // public String googleId;
      // public String familyName;
      // public String givenName;
      // public String imageUrl;
      // public String name;
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            email: response.profileObj.email,
            googleId: response.profileObj.googleId,
            familyName: response.profileObj.familyName,
            givenName: response.profileObj.givenName,
            imageUrl: response.profileObj.imageUrl,
            name: response.profileObj.name
         }),
         mode: 'cors',
         credentials: 'include'
      };

      fetch(this.props.url + "/api/members/oauth/google", requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               var queryStr = document.location.href.split('?');

               if(queryStr.length > 1) {
                  document.location.href = queryStr[1].split('=')[1];
                  
               } else {
                  document.location.href = "./";
               }
               // document.cookie="name=" + value.data + "; path=/";
               // document.location.href = document.referrer;     //이전페이지로 이동
            }else if(value.status == 401){
               window.sessionStorage.clear();
                  window.sessionStorage.setItem("bucket", JSON.stringify({
                     email: response.profileObj.email,
                     googleId: response.profileObj.googleId,
                     familyName: response.profileObj.familyName,
                     givenName: response.profileObj.givenName,
                     imageUrl: response.profileObj.imageUrl,
                     name: response.profileObj.name,
                     type: "google"
                  }));
               document.location.href = "/signup"
            } 
            else {
               alert("다시 로그인해 주세요");
            }
         })
         .catch(err => console.log(err));
   };
   onSuccessNaver(result){
      console.log(result);
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
             email: result.email,
         }),
         mode: 'cors',
         credentials: 'include'
      };

      fetch( "https://kwinnovation.kr/api/members/oauth/naver", requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               var queryStr = document.location.href.split('?');

               if(queryStr.length > 1) {
                  document.location.href = queryStr[1].split('=')[1];
                  
               } else {
                  document.location.href = "./";
               }
               // document.cookie="name=" + value.data + "; path=/";
               // document.location.href = document.referrer;     //이전페이지로 이동
            }else if(value.status == 401){
                window.sessionStorage.clear();
                   window.sessionStorage.setItem("bucket", JSON.stringify({
                      email: result.email,
                      type: "naver"
                  }));
               document.location.href = "/signup"
            } 
            else {
               alert("다시 로그인해 주세요");
            }
         })
         .catch(err => console.log(err));
   }

   onFailNaver(result){
      
   }

   onSuccessKakao(result){
      console.log(result);
      var requestOptions = {
         method: 'GET',
         credentials: 'include'
      };

      fetch("https://kwinnovation.kr/api/members/oauth/kakao/" + result.response.access_token, requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               var queryStr = document.location.href.split('?');

               if(queryStr.length > 1) {
                  document.location.href = queryStr[1].split('=')[1];
                  
               } else {
                  document.location.href = "./";
               }
               // document.cookie="name=" + value.data + "; path=/";
               // document.location.href = document.referrer;     //이전페이지로 이동
            }else if(value.status == 202){
               //console.log(value.data[0])
               window.sessionStorage.clear();
                  window.sessionStorage.setItem("bucket", JSON.stringify({
                     email: value.data[0],
                     googleId: "",
                     familyName: "",
                     givenName: "",
                     imageUrl: "",
                     name: "",
                     type: "kakao"
                  }));
               document.location.href = "/signup"
            } 
            else {
               alert("다시 로그인해 주세요");
            }
         })
         .catch(err => console.log(err));
      console.log(result);
   }

   onSuccessFacebook(result){
      console.log(result)
      // public String email;
      // public String googleId;
      // public String familyName;
      // public String givenName;
      // public String imageUrl;
      // public String name;
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
             email: result.email,
            // googleId: response.profileObj.googleId,
            // familyName: response.profileObj.familyName,
            // givenName: response.profileObj.givenName,
            // imageUrl: response.profileObj.imageUrl,
            // name: response.profileObj.name
         }),
         mode: 'cors',
         credentials: 'include'
      };
      //'http://localhost:443'
      fetch( this.props.url+ "/api/members/oauth/facebook", requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               var queryStr = document.location.href.split('?');

               if(queryStr.length > 1) {
                  document.location.href = queryStr[1].split('=')[1];
                  
               } else {
                  document.location.href = "./";
               }
               // document.cookie="name=" + value.data + "; path=/";
               // document.location.href = document.referrer;     //이전페이지로 이동
            }else if(value.status == 401){
                window.sessionStorage.clear();
                   window.sessionStorage.setItem("bucket", JSON.stringify({
                      email: result.email,
                      type: "facebook"
                  }));
               document.location.href = "/signup"
            } 
            else {
               alert("다시 로그인해 주세요");
            }
         })
         .catch(err => console.log(err));
   }

   onFailKakao(){
      document.location.href = "./";
   }

   /* 회원가입 버튼 */
   clickSignUp() {
      window.sessionStorage.clear();
      window.sessionStorage.setItem("bucket", JSON.stringify({
         email: "",
         googleId: "",
         familyName: "",
         givenName: "",
         imageUrl: "",
         name: "",
         type: "NONE"
      }));
      document.location.href = "/signup";
   }

   /* ID/PW찾기 버튼 */
   findAccount() {
      document.location.href = "/find-account";
   }

   /* 로그인 버튼 */
   requsetLogin() {
      if ($(`.Loginid`).val() === "" || $(`.Loginpw`).val() === "") {
         alert("아이디 비밀번호를 입력해주세요.");
         return;
      }

      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            account: $(`.Loginid`).val(),
            password: $(`.Loginpw`).val(),
         }),
         mode: 'cors',
         credentials: 'include'
      };

      fetch(this.props.url + "/api/members/login", requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               var queryStr = document.location.href.split('?');

               if(queryStr.length > 1) {
                  document.location.href = queryStr[1].split('=')[1];
               } else {
                  document.location.href = "./";
               }
               // document.cookie="name=" + value.data + "; path=/";
               // document.location.href = document.referrer;     //이전페이지로 이동
            } else {
               alert("다시 로그인해 주세요");
            }
         })
         .catch(err => console.log(err));
   }

   /* 엔터키 이벤트 */
   onEnterPress = (e) => {
      if (e.key === 'Enter')
         this.requsetLogin();
   }

   componentDidMount() {
      $('body').css('background', 'linear-gradient(to top, #accbee 0%, #e7f0fd 100%) no-repeat center center fixed');
   }

   render() {
      // div태그의 marginTop을 60으로 해야 top=0 이다.
      return (
         <div style={{ textAlign: 'center' }}>
            <div
               className="text-center"
               style={{
                  textAlign: 'center', margin: '0px auto', paddingTop: '50px',
                  maxWidth: '450px', minWidth: '300px', width: '200%',
               }}
            >
               <h2 style={{ marginBottom: '30px', color: '#93ADCF' }}>KW INNOVATION</h2>
               <h3 style={{ marginBottom: '50px', width: '100%' }}>로그인</h3>
               <input
                  type="text"
                  className="Loginid"
                  placeholder="아이디"
                  style={{ width: '70%', margin: '10px auto', height: '40px', fontSize: '15px' }}
               ></input>
               <br />
               <input
                  type="password"
                  className="Loginpw"
                  placeholder="비밀번호"
                  style={{ width: '70%', marginBottom: '30px', height: '40px', fontSize: '15px' }}
                  onKeyPress={this.onEnterPress.bind(this)}
               ></input>
               <br />
               <button
                  onClick={this.requsetLogin.bind(this)}
                  className="customButton3"
                  style={{ width: '60%', padding: '12px 0px', marginBottom: '20px' }}
               >로그인</button>
               <button
                  onClick={this.clickSignUp}
                  className="customButton3"
                  style={{ width: '60%', padding: '12px 0px', marginBottom: '7px' }}
               >회원가입</button>
               <button
                  onClick={this.findAccount}
                  className="customButton3"
                  style={{ width: '60%', padding: '12px 0px', marginBottom: '7px' }}
               >ID/PW 찾기</button>
               <div>
                  <GoogleLogin
                     clientId = {ServerConfig.google.client_id}
                     buttonText="구글 로그인"
                     render={(renderProps) => (
                        <button onClick={renderProps.onClick}
                           disabled={renderProps.disabled}
                           style={{
                              padding: '0',
                              width: '220px',
                              height: '45px',
                              'line-height': '44px',
                              'color': '#000000',
                              'background-color': '#FFFFFF',
                              border: '1px solid transparent',
                              'border-radius': '3px',
                              'font-size': '14px',
                              'font-weight': 'bold',
                              'text-align': 'center',
                              'cursor': 'pointer'
                           }}>
                          구글 로그인
                        </button>
                      )}              
                     onSuccess={result => this.onSuccessGoogle(result)}
                     onFailure={result => console.log(result)}
                  />
               </div>
               <div>
                  <KaKaoBtn
                     //styled component 통해 style을 입혀 줄 예정 
                     token={'1692298bed51dfccb71ce1792cb3cc90'}
                     //카카오에서 할당받은 jsKey를 입력
                     buttonText='카카오 계정으로 로그인'
                     //로그인 버튼의 text를 입력
                     onSuccess={this.onSuccessKakao}
                     onFail={this.onFailKakao}
                  />
               </div>
               <div>
                  <FacebookLogin
                     appId='1754461204919918'
                     onProfileSuccess={(response) => {
                        this.onSuccessFacebook(response);
                     }}
                     onFail={() => {
                        alert('비정상적인 결과입니다. 다시 시도해주세요!');
                     }}
                     render={(renderProps) => (
                        <button onClick={renderProps.onClick}
                           disabled={renderProps.disabled}
                           style={{
                              padding: '0',
                              width: '220px',
                              height: '45px',
                              'line-height': '44px',
                              'color': '#000000',
                              'background-color': '#3b5998',
                              border: '1px solid transparent',
                              'border-radius': '3px',
                              'font-size': '14px',
                              'font-weight': 'bold',
                              'text-align': 'center',
                              'cursor': 'pointer',
                              'color': 'white'
                           }}>
                          페이스북 로그인
                        </button>
                      )}           
                  />
               </div>
               <div>
                  <NaverLogin
                     clientId="Z1iDpcmJ6fDyQ7euhIPu"
                     callbackUrl="https://wash.kwinnovation.kr/login"
                     render={(props) => <button
                        onClick={props.onClick}
                        style={{
                           padding: '0',
                           width: '220px',
                           height: '45px',
                           'line-height': '44px',
                           'color': '#FFFFFF',
                           'background-color': '#03c75a',
                           border: '1px solid transparent',
                           'border-radius': '3px',
                           'font-size': '14px',
                           'font-weight': 'bold',
                           'text-align': 'center',
                           'cursor': 'pointer'}}
                     >네이버 로그인</button>}
                     onSuccess={this.onSuccessNaver}
                     onFailure={() => console.log('Naver Login Fail')}
               />
               </div>
            </div>
         </div>
      );
   }
}