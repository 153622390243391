import React, { Component } from 'react'
import $ from 'jquery'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class Point extends Component {
   constructor(props) {
      super(props)
      this.state = ({
         is_auth: false,             //인증 여부
         input_information: [],      //입력 데이터
         selected_storeName: ""      //선택된 가맹점
      });
   }

   /* 비밀번호 확인 및 포인트 조회 */
   changeIsAuth() {
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            password: $(`.input_pw`).val(),
         }),
         mode: 'cors',
         credentials: 'include'
      };

      fetch(url + "/api/point/list", requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               console.log(value);
               if (value.data.length === 0) {
                  this.setState({
                     is_auth: true,
                     input_information: value.data
                  });
               } else {
                  this.setState({
                     is_auth:true,
                     input_information: value.data,
                     selected_storeName: value.data[0].storeName
                  });
               }
            } else {
               alert("비밀번호가 틀렸습니다.");
            }
         })
         .catch(err => console.log(err));
   }

   changeStore() {
      console.log($('.input_storeName').val());
      this.setState({selected_storeName:$('.input_storeName').val()});
   }

   render() {
      if (this.state.is_auth) {
         let store_list = [];      //포인트 가맹점 선택 리스트
         let store_select_list = [];
         let point_list = [];
         let totalPoint = 0;

         //가맹점 리스트 출력
         for (var i = 0; i < this.state.input_information.length; i++) {
            let flag = 0;
            for (var j = 0; j < store_list.length; j++) {
               if (store_list[j] === this.state.input_information[i].storeName) { flag++; }
            }
            if (flag === 0) {
               store_list.push(this.state.input_information[i].storeName);
            }
         }
         for(var j=0; j<store_list.length; j++) {
            store_select_list.push(<option key={j} value={store_list[j]}>{store_list[j]}</option>)
         }

         //포인트 리스트 출력
         for(var z=0; z<this.state.input_information.length; z++) {
            if(this.state.selected_storeName === this.state.input_information[z].storeName) {
               totalPoint += this.state.input_information[z].point;
               point_list.push(
                  <tr key={z}>
                     <td style={{padding:'5px'}}>{this.state.input_information[z].comment}</td>
                     <td>{this.state.input_information[z].point.toLocaleString()}</td>
                     <td>{this.state.input_information[z].createdDate.slice(0,10)}</td>
                     <td>{this.state.input_information[z].endDate.slice(0,10)}</td>
                  </tr>
               );
            }
         }

         // 포인트 리스트가 없을 경우
         if (point_list.length === 0) {
            point_list.push(
               <tr key={1} style={{ textAlign: 'center' }}>
                  <th colSpan="4">데이터가 없습니다</th>
               </tr>
            );
         }

         return (
            <div style={{ minHeight: '80vh' }}>

               {/* Top */}
               <h2 style={{ margin: '30px 0px' }}>
                  포인트
                  <select className="input_storeName" style={{margin:'20px', fontSize:'18px'}} onChange={this.changeStore.bind(this)}>
                     {store_select_list}
                  </select>
                  <span style={{fontSize:'15px'}}>← 포인트를 선택해 주세요</span>
               </h2>
               
               <div className="row" style={{ textAlign: 'center' }}>
                  <div
                     style={{
                        minWidth: '200px', width: '60%',
                        margin: '0 auto', padding: '20px 0px',
                        background: '#EFEFEF', borderRadius:'15px'
                     }}
                  >
                     <h4>총 {totalPoint.toLocaleString()}포인트</h4>
                  </div>
               </div>

               {/* Middle */}
               <div className="row" style={{ margin: '10px 0px', color: 'white' }}>
                  <h4>통합 포인트</h4>
                  <table style={{ width: '100%', background: '#546e7a' }}>
                     <tbody>
                        <tr style={{ background: '#29434e' }}>
                           <th style={{ padding: '5px', paddingLeft: '5px' }}>내용</th>
                           <th>포인트</th>
                           <th>적립일</th>
                           <th>만료일</th>
                        </tr>
                        {point_list}
                     </tbody>
                  </table>
               </div>

            </div>
         );
      } else {
         return (
            <div style={{ minHeight: '80vh', textAlign: 'center' }}>
                    <h2 style={{ marginTop: '50px', padding: '5px 10px', fontSize: '40px' }}>
                     포인트 조회</h2>
               <div
                  style={{
                     border: '1px solid', borderRadius: '15px',
                     minWidth: '200px', maxWidth: '600px', width: '100%',
                     margin: '0px auto', marginTop: '50px', padding: '50px 0px'
                  }}
               >

                  <h3 style={{ marginBottom: '50px' }}>비밀번호 확인</h3>
                  <input
                     className="input_pw"
                     type="password"
                     placeholder="비밀번호"
                     style={{ fontSize: '17px' }}
                  />
                  <br />
                  <button
                     style={{ marginTop: '30px', padding: '5px 10px', fontSize: '20px' }}
                     onClick={this.changeIsAuth.bind(this)}
                     className="customButton"
                  >
                     check
                        </button>
               </div>
            </div>
         );
      }
   }
}