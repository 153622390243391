import React, { Component } from 'react'
import Carousel from 'react-bootstrap/Carousel'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class Main extends Component {
   constructor(props) {
      super(props)
      this.state = {
         url1: "",
         url2: "",
         url3: "",
         store_id: this.props.match.params.storeId,
         store_name: ""
      }
   }

   callGetApi() {
      // 웹 이미지
      fetch(url + "/api/store-setting/web-image/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({     //이미지 정보
               url1: value.data[0].image1,
               url2: value.data[0].image2,
               url3: value.data[0].image3
            });
         })
         .catch(err => alert("설정값을 불러오는데 실패하였습니다."));

      // 가맹점명
      fetch(url + "/api/account/store-inform/storename/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ store_name: value.data[0] });
         })
         .catch(err => { console.log(err); });
   }

   componentDidMount() {
      this.callGetApi();
   }

   render() {
      var url1 = this.state.url1;
      var url2 = this.state.url2;
      var url3 = this.state.url3;

      //가맹점명
      var storeName = "";
      if (this.state.store_name !== "")
         storeName = this.state.store_name;

      return (
         <div style={{ minHeight: '80vh', textAlign: 'center' }}>
            <h1 style={{ margin: '50px 0px' }}>{storeName}에 오신걸 환영합니다.</h1>
            <Carousel interval={80} pause="false">
               <Carousel.Item>
                  <img
                     className="w-100"
                     src={url1}
                     alt="First slide"
                     style={{ height: '700px' }}
                  />
               </Carousel.Item>
               <Carousel.Item>
                  <img
                     className="w-100"
                     src={url2}
                     alt="Second slide"
                     style={{ height: '700px' }}
                  />
               </Carousel.Item>
               <Carousel.Item>
                  <img
                     className="w-100"
                     src={url3}
                     alt="Third slide"
                     style={{ height: '570px' }}
                  />
               </Carousel.Item>
            </Carousel>
            {/* <p>hi</p> */}
         </div>
      );
   }
}