import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import Order from './pageContent/Order.js'
import MonitoringLaundry from './pageContent/MonitoringLaundry.js'
import Main from './pageContent/Main.js'
import About from './pageContent/About.js'
import Printing from './pageContent/Printing.js'
import Reservation from './pageContent/Reservation.js'
import ReservationWash from './pageContent/ReservationWash.js'
import ReservationLaundry from './pageContent/ReservationLaundry.js'

import CheckingReservation from './pageContent/CheckingReservation.js'

import MonitoringRoom from './pageContent/MonitoringRoom.js'
import MonitoringOtt from './pageContent/MonitoringOtt.js'

import Profile from './pageContent/Profile.js'
import Point from './pageContent/Point.js'
import Ticket from './pageContent/Ticket.js'
import Spot from './pageContent/SpotPay.js';
import PointCharge from './pageContent/PointCharge.js';

import PaymentGoods from './pageContent/payment/PaymentGoods.js'
import PaymentRoom from './pageContent/payment/PaymentRoom.js'
import PaymentWash from './pageContent/payment/PaymentWash.js'
import PaymentLaundry from './pageContent/payment/PaymentLaundry.js'
import PaymentSpot from './pageContent/payment/PaymentSpot.js'
import PaymentPoint from './pageContent/payment/PaymentPoint.js'


import Success from './pageContent/kakaoPay/Success.js'
import Fail from './pageContent/kakaoPay/Fail.js'
import Cancel from './pageContent/kakaoPay/Cancel.js'

import NotFound from './pageContent/NotFound.js'

export default class PageContent extends Component {
   render() {
      return (
         <div className="container">
            <Router>
               <Switch>
                  <Route path="/store/:storeId" exact component={Main} />
                  <Route path="/store/:storeId/order" exact component={Order} />
                  <Route path="/store/:storeId/about" exact component={About} />
                  <Route path="/store/:storeId/printing" exact component={Printing} />

                  <Route path="/store/:storeId/reservation" exact component={Reservation} />
                  <Route path="/store/:storeId/reservation/ticket" exact component={CheckingReservation} />
                  <Route path="/store/:storeId/reservation-wash" exact component={ReservationWash} />
                  <Route path="/store/:toreId/reservation-laundry" exact component={ReservationLaundry}/>

                  <Route path="/store/:storeId/monitoring/laundry" exact component={MonitoringLaundry} />
                  <Route path="/store/:storeId/monitoring/room" exact component={MonitoringRoom} />
                  <Route path="/store/:storeId/monitoring/ott" exact component={MonitoringOtt} />

                  <Route path="/store/:storeId/profile" exact component={Profile} />
                  <Route path="/store/:storeId/ticket" exact component={Ticket} />
                  <Route path="/store/:storeId/point" exact component={Point} />
                  <Route path="/store/:storeId/spotpay" exact component={Spot}/>
                  <Route path="/store/:storeId/pointcharge" exact component={PointCharge}/>

                  <Route path="/store/:storeId/payment/goods" exact component={PaymentGoods} />
                  <Route path="/store/:storeId/payment/room" exact component={PaymentRoom} />
                  <Route path="/store/:storeId/payment/wash" exact component={PaymentWash} />
                  <Route path="/store/:storeId/payment/laundry" exact component={PaymentLaundry}/>
                  <Route path="/store/:storeId/payment/spot" exact component={PaymentSpot}/>
                  <Route path="/store/:storeId/payment/point" exact component={PaymentPoint}/>
                  

                  <Route path="/store/:storeId/kakao/success" exact component={Success} />
                  <Route path="/store/:storeId/kakao/fail" exact component={Fail} />
                  <Route path="/store/:storeId/kakao/cancel" exact component={Cancel} />

                  <Route path="/store" exact component={NotFound} />
                  <Redirect to="/store" />

                  {/* <Route path="/store" exact
                                render={() => <Main storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/order" exact
                                render={() => <Order storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/about" exact
                                render={() => <About storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/printing" exact
                                render={() => <Printing storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/reservation" exact
                                render={() => <Reservation storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/reservation/ticket" exact
                                render={() => <CheckingReservation storeName={storeName} storeId={storeId} />}
                            ></Route>

                            <Route path="/store/monitoring/laundry" exact
                                render={() => <Laundry storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/monitoring/room" exact
                                render={() => <MonitoringRoom storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/monitoring/ott" exact
                                render={() => <MonitoringOtt storeName={storeName} storeId={storeId} />}
                            ></Route>

                            <Route path="/store/profile" exact
                                render={() => <Profile storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/ticket" exact
                                render={() => <Ticket storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/point" exact
                                render={() => <Point storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/password" exact
                                render={() => <Password storeName={storeName} storeId={storeId} />}
                            ></Route>

                            <Route path="/store/payment/goods" exact
                                render={() => <PaymentGoods storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/payment/room" exact
                                render={() => <PaymentRoom storeName={storeName} storeId={storeId} />}
                            ></Route>


                            <Route path="/store/kakao/success" exact
                                render={() => <Success  storeName={storeName} storeId={storeId} />}
                            ></Route>
                            <Route path="/store/kakao/fail" exact component={Fail}></Route>
                            <Route path="/store/kakao/cancel" exact component={Cancel}></Route>

                            <Redirect to="/store"></Redirect> */}
               </Switch>
            </Router>
         </div>
      );
   }
}