import React, { Component } from 'react'
import Timer from "react-compound-timer";

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class MonitoringOtt extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store_id: this.props.match.params.storeId,
            input_information: []   //정보
        }
    }

    callGetApi() {
        //첫 화면 띄우기
        fetch(url + "/api/ott/" + this.state.store_id)
            .then(response => response.json())
            .then(value => {
                this.setState({ input_information: value.data });    //메인설정 정보
            })
            .catch(err => {
                console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다.");
            });
    }

    componentDidMount() {
        this.callGetApi();
    }

    render() {
        var today = new Date();
        var nowHour = today.getHours();            //현재시간: 시
        var nowMin = today.getMinutes();           //현재시간: 분
        var nowSec = today.getSeconds();           //현재시간: 초

        return (
            <div style={{ minHeight: '80vh', marginTop:'30px' }}>
                {/* 페이지 제목 시작 */}
                <div className="row">
                    <h2 style={{ width: '70%' }}>Ott 현황</h2>
                    <Timer
                        formatValue={value => `${value < 10 ? `0${value}` : value}`}            //항상 두글자씩 표현
                        initialTime={((nowHour * 60 * 60) + (nowMin * 60) + (nowSec)) * 1000}   //시작시간
                    >
                        <React.Fragment>
                            <span
                                style={{ fontSize: '25px', borderRadius: '25px', border: '1px solid', padding: '10px 20px' }}
                            >
                                <Timer.Hours />시 <Timer.Minutes />분 <Timer.Seconds />초
                                </span>
                        </React.Fragment>
                    </Timer>
                </div>
                <br />
                {/* 페이지 제목 종료 */}
                {/* OTT 정보 시작 */}
                <Ott data={this.state.input_information} />
                {/* OTT 정보 종료 */}
                <div className="clearfix"></div>
            </div>
        )
    }
}

class Ott extends Component {
    render() {
        var list = [];
        var datas = this.props.data;    //OTT 정보
        var i = 0;
        var imgSrc = '';
        var today = new Date();         //현재 시각

        while (i < datas.length) {
            var dateJS = new Date(datas[i].endDate);        //받아온 OTT 종료 시간 JS 변수로 파싱 (SDK활용)
            var timeDiff = dateJS - today;                  //종료까지 남은시간
            var available = '';

            var machine1 = [];
            var machine2 = [];

            /* 이미지 넣기 */
            if (timeDiff > 0) {
                imgSrc = "/images/ottON.png"
            } else {
                imgSrc = "/images/ottOFF.png"
            }
            machine1 = (
                <div className="card h-100" style={{ width: '48%' }}>
                    <div className="card-header">
                        <h6 style={{ fontWeight: 'bold' }}>{datas[i].name}</h6>
                    </div>
                    <img className="card-img-top" src={imgSrc} alt="" style={{ height: '200px' }} />
                    <div className="card-body">
                        <h5 className="card-title">
                            <Timer
                                formatValue={value => `${value < 10 ? `0${value}` : value}`}            //항상 두글자씩 표현
                                initialTime={timeDiff}   //종료까지 남은시간
                                direction="backward"
                                lastUnit="h"
                            >
                                <React.Fragment>
                                    <div style={{ fontSize: '25px', textAlign: 'center', width: '100%', margin: '20px 0px' }}>
                                        {/* (남은 시간>24) => 다시 0시부터 count */}
                                        <Timer.Hours />시 <Timer.Minutes />분 <Timer.Seconds />초
                                        </div>
                                </React.Fragment>
                            </Timer>
                        </h5>
                    </div>
                    <div className="card-footer">
                        {available}
                    </div>
                </div>
            );

            if (i + 1 < datas.length) {
                i = i + 1;
                dateJS = new Date(datas[i].endDate);        //받아온 기기 종료 시간 JS 변수로 파싱 (SDK활용)
                timeDiff = dateJS - today;                  //종료까지 남은시간

                /* 이미지 넣기 */
                if (timeDiff > 0) {
                    imgSrc = "/images/ottON.png"
                } else {
                    imgSrc = "/images/ottOFF.png"
                }
                machine2 = (
                    <div className="card h-100" style={{ width: '48%', marginLeft: '5px' }}>
                        <div className="card-header">
                            <h6 style={{ fontWeight: 'bold' }}>{datas[i].name}</h6>
                        </div>
                        <img className="card-img-top" src={imgSrc} alt="" style={{ height: '200px' }} />
                        <div className="card-body">
                            <h5 className="card-title">
                                <Timer
                                    formatValue={value => `${value < 10 ? `0${value}` : value}`}            //항상 두글자씩 표현
                                    initialTime={timeDiff}   //종료까지 남은시간
                                    direction="backward"
                                    lastUnit="h"
                                >
                                    <React.Fragment>
                                        <div style={{ fontSize: '25px', textAlign: 'center', width: '100%', margin: '20px 0px' }}>
                                            {/* (남은 시간>24) => 다시 0시부터 count */}
                                            <Timer.Hours />시 <Timer.Minutes />분 <Timer.Seconds />초
                            </div>
                                    </React.Fragment>
                                </Timer>
                            </h5>
                        </div>
                        <div className="card-footer">
                            {available}
                        </div>
                    </div>
                );
            }

            /* 데이터 푸시 */
            list.push(
                <div key={i} className="col-md-6" style={{ marginTop: '10px' }}>
                    <div className="row">
                        {machine1}
                        {machine2}
                    </div>
                </div>
            );
            i = i + 1;
        }
        return (
            <div className="row">
                {list}
            </div>
        );
    }
}