import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';  //bootstrap
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

//Custom component
import Store from './Store.js'
import Login from './Member/Login'
import SignUp from './Member/SignUp'
import OAuthSignUp from './Member/OAuthSignUp'
import FindAccount from './Member/FindAccount'
import WashPage from './Wash'
import CafePage from './Cafe'
import Root from './Root'
import QRCode from './QRCode'
import ValidPage from './Valid'

// 메인 서버 URL
const ServerConfig = require('./ServerConfig');
const url = ServerConfig.url;

export default class App extends Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" exact render={() => <Root url={url} />} />

            <Route path="/login" render={() => <Login url={url} />} />
            <Route path="/signup" render={() => <SignUp url={url} />} />
            <Route path="/oauth" render={() => <OAuthSignUp url={url}/>}/>
            <Route path="/find-account" render={() => <FindAccount url={url} />} />

            <Route path="/store" render={() => <Store url={url} />} />

            <Route path="/qr-code" exact render={() => <QRCode url={url} />} />
            <Route path="/wash" exact render={() => <WashPage url={url}/>}/>
            <Route path="/cafe" exact render={() => <CafePage url={url}/>}/>
            <Route path="/valid" exact render={() => <ValidPage url={url}/>}/>

            <Redirect to="/"></Redirect>
          </Switch>
        </Router>
      </div>
    );
  }
}