import React, { Component } from 'react'
import $ from 'jquery'
import Barcode from 'react-barcode'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class CheckingReservation extends Component {
   constructor(props) {
      super(props)
      this.state = ({
         store_id: this.props.match.params.storeId,
         input_information: [],
         room_list: [],
         basket_list: [],
         module_information: [],
         is_checked: false,
         selected_service: "",
      });
   }

   check() {
      // 이메일 체크
      var email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (!email.test($('.input_email').val())) {
         alert("이메일 형식이 아닙니다.");
         return;
      }

      fetch(url + "/api/reservation/" + $('.selected_service').val() + "/customer/"
         + $('.input_email').val() + "/" + $('.input_pw').val())
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               this.setState({
                  input_information: value.data,
                  is_checked: true,
                  selected_service: $('.selected_service').val()
               });
            } else {
               alert("예약 내역이 없습니다.");
            }
         })
         .catch(err => alert("err: " + err));
   }

   callGetApi() {
      // 룸 정보
      fetch(url + "/api/room/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ room_list: value.data });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
      // 세탁 사물함
      fetch(url + "/api/wash/basket/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ basket_list: value.data });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
      // 모듈 설정
      fetch(url + "/api/store-setting/service-module/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ module_information: value.data[0] });    //메인설정 정보
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
   }
   componentDidMount() {
      this.callGetApi();
   }

   render() {
      if (this.state.is_checked) {
         var list = [];
         if (this.state.input_information) {
            for (var i = 0; i < this.state.input_information.length; i++) {
               var name = "", start = "", end = "";

               if (this.state.selected_service === "room") {
                  start = <p>시작 : {this.state.input_information[i].startDate.slice(0, 10)} / {this.state.input_information[i].startDate.slice(11, 16)}</p>;
                  end = <p>종료 : {this.state.input_information[i].endDate.slice(0, 10)} / {this.state.input_information[i].endDate.slice(11, 16)}</p>
                  if (this.state.room_list) {
                     for (var j = 0; j < this.state.room_list.length; j++) {
                        if (this.state.input_information[i].roomId === this.state.room_list[j].id) {
                           name = this.state.room_list[j].name;
                           break;
                        }
                     }
                  }
               } else {
                  start = <p>시작 : {this.state.input_information[i].setTime.slice(0, 10)} / {this.state.input_information[i].setTime.slice(11, 16)}</p>;
                  end = <p>종료 : {this.state.input_information[i].getTime.slice(0, 10)} / {this.state.input_information[i].getTime.slice(11, 16)}</p>
                  if (this.state.basket_list) {
                     for (var j = 0; j < this.state.basket_list.length; j++) {
                        if (this.state.input_information[i].basketId === this.state.basket_list[j].id) {
                           name = this.state.basket_list[j].name;
                           for (var z = 0; this.state.basket_list[j].washCourse.length; z++) {
                              if (this.state.input_information[i].courseId === this.state.basket_list[j].washCourse[z].id) {
                                 name += "-" + this.state.basket_list[j].washCourse[z].name;
                                 break;
                              }
                           }
                           break;
                        }
                     }
                  }
               }
               var isRefund = ""
               if (this.state.input_information[i].isRefund)
                  isRefund = <p style={{ color: 'red', fontWeight: '700', margin: '0px' }}>환불된 예약입니다.</p>
               else if (!this.state.input_information[i].isPaid)
                  isRefund = <p style={{ color: 'red', fontWeight: '700', margin: '0px' }}>아직 결제되지 않았습니다.</p>

               list.push(
                  <div key={i} style={{ textAlign: 'center', border: '1px solid', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
                     <h4>{name}</h4>
                     {isRefund}
                     <Barcode value={this.state.input_information[i].receiptNumber}
                        format="CODE128"
                        fontSize={12}
                        width={1.4}
                     />
                     {start}
                     {end}
                  </div>
               );
            }
         }

         return (
            <div style={{ minHeight: '80vh' }}>
               <h1 style={{ margin: '20px 0px' }}>예약 조회</h1>
               {list}
            </div>
         );

      } else {
         var options = []
         if (this.state.module_information.isReservationRoom)
            options.push(<option key={1} value="room">룸</option>);
         if (this.state.module_information.isReservationWash)
            options.push(<option key={2} value="wash">세탁</option>);

         return (
            <div style={{ minHeight: '80vh', alignContent: 'center' }}>
               <div style={{ width: '50%', minWidth: '500px', margin: '0 auto', textAlign: 'center', paddingTop: '170px' }}>
                  <div style={{ border: '1px solid', padding: '50px', borderRadius: '10px' }}>
                     <h1>예약 조회</h1>
                     <div>
                        <select className="selected_service" style={{ fontSize: '20px', margin: '10px 0px' }}>
                           {options}
                        </select>
                     </div>
                     <input type="text"
                        className="input_email"
                        placeholder="이메일"
                        style={{ width: '70%', margin: '10px auto', height: '40px', fontSize: '17px', border: '1px solid', marginTop: '30px' }}
                     ></input>
                     <br />
                     <input type="text"
                        className="input_pw"
                        placeholder="비밀번호 (숫자 6자리)"
                        maxLength="6"
                        style={{ width: '70%', marginBottom: '30px', height: '40px', fontSize: '17px', border: '1px solid' }}
                     ></input>
                     <br />
                     <button
                        onClick={this.check.bind(this)}
                        className="customButton3"
                        style={{ width: '60%', padding: '12px 0px', marginBottom: '20px', fontSize: '20px' }}
                     >예약 조회</button>
                  </div>
               </div>
            </div>
         );
      }
   }
}