import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'

const ServerConfig = require('../../ServerConfig');
const url = ServerConfig.url;

export default class PaymentWash extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store_id: this.props.match.params.storeId,
            bucket: JSON.parse(window.sessionStorage.getItem("bucket")),
            totalPrice: 0,
            input_course: [],
            input_setting: [],
            store_info: [],
            importModal: false  // 아임포트 결제 후 서버 통신중 모달창
        }
    }

    /* 총 가격 계산 */
    getPrice() {
        var total = 0;

        total += this.state.bucket.totalPrice;
        console.log(this.state.bucket.totalPrice);
        /*
        if(this.state.input_setting.length !== 0) {
              //배달비  : 임시 주석 처리
           total += this.state.input_setting[0].deliveryFee;
        }
        */
        
        this.setState({ totalPrice: total });
    }

    /* 아임포트 결제 */
    requestPay = () => {

        if (this.state.totalPrice <= 0) {
            alert("결제할 금액이 없습니다.");
            return;
        }

        /* 서버 예약 요청 */
        var tempDate1 = new Date();
        tempDate1.setHours(tempDate1.getHours() + 9);
        var pk = tempDate1.toISOString().slice(11, 25);

        var courseId = null;
        var coursePrice = null;
        for (var i = 0; i < this.state.input_course.length; i++) {
            if (this.state.bucket.basketId === this.state.input_course[i].id) {
                courseId = this.state.input_course[i].washCourse[this.state.bucket.clickedIndex_course].id;
                //console.log(this.state.input_course[i].washCourse[this.state.bucket.clickedIndex_course].price);
                //this.state.totalPrice = this.state.input_course[i].washCourse[this.state.bucket.clickedIndex_course].price;
            }
        }

        // var tempDate = new Date(this.state.bucket.selected_date);
        // tempDate.setUTCSeconds(0);
        // tempDate.setUTCMilliseconds(0);

        // tempDate.setUTCHours(parseInt(this.state.bucket.setTime_hour));
        // tempDate.setUTCMinutes(parseInt(this.state.bucket.setTime_min));
        // var input_setTime = tempDate.toISOString();

        // tempDate.setUTCHours(parseInt(this.state.bucket.getTime_hour));
        // tempDate.setUTCMinutes(parseInt(this.state.bucket.getTime_min));
        // var input_getTime = tempDate.toISOString();

        // var KWrequestOption = {
        //     method: 'POST',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json'
        //     },
        //     body: JSON.stringify({
        //         receiptNumber: pk,
        //         storeId: this.state.bucket.storeId,
        //         basketId: this.state.bucket.basketId,
        //         courseId: courseId,
        //         setTime: input_setTime,
        //         getTime: input_getTime,
        //         tel: this.state.bucket.tel,
        //         email: this.state.bucket.email,
        //         password: this.state.bucket.password,
        //         description: this.state.bucket.description,
        //         isDelivery: this.state.bucket.is_delivery,
        //         isPaid: false,
        //         isUsed: 0,
        //         isRefund: false
        //     }),
        //     mode: 'cors',
        //     credentials: 'include'
        // };
        var IMP = window.IMP; // 생략해도 괜찮습니다.

        IMP.init(this.state.store_info[0].importId); // 발급받은 "가맹점 식별코드"를 사용합니다.

        IMP.request_pay({ // param
            pay_method: "card",
       /*     merchant_uid:'merchant_' + new Date().getTime(), */
            merchant_uid: `mid_${new Date().getTime()}` ,
            name:"주문명:Spot",
            amount:this.state.totalPrice,
            buyer_tel:"010-3135-3713"
        }, rsp => {
            console.log(rsp);
            /* 서버에 결제결과 전송 */
            if (rsp.success) {
                this.setState({importModal:true});
                var tempDate2 = new Date(rsp.paid_at * 1000);
                tempDate2.setHours(tempDate2.getHours() + 9);
                var date = tempDate2.toISOString();

                // 예약 이름
                var basketName = "", courseID = "";
                for (var i = 0; i < this.state.input_course.length; i++) {
                    if (this.state.bucket.courseId === this.state.input_course[i].id) {
                        basketName = this.state.input_course[i].name;
                        courseID = this.state.input_course[i].id;
                        //courseName = this.state.input_course[i].washCourse[this.state.bucket.clickedIndex_course].name;
                    }
                }
                basketName += "_" + rsp.paid_amount + "_1_" + rsp.paid_amount;

                console.log(basketName);

                var requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        receiptNumber: rsp.merchant_uid,
                        storeId: this.state.store_id,
                        salesMachineId: 80,
                        tel: rsp.butyer_tel,
                        goodsName: basketName,
                        totalPrice: rsp.paid_amount,
                        cardName: "PG " + rsp.card_name,
                        cardNumber: rsp.card_number,
                        cardInstallment: rsp.card_quota,
                        approvalCode: rsp.apply_num,
                        approvalDate: date,
                        goodsServed: 1,
                        refund: 1,
                        endDate: date,
                        accessCount: 5,       // 서버에서 받아오기
                        division: "wash",    // 음료만 결제
                        importId: rsp.imp_uid,
                        importPgId: rsp.pg_tid,
                        selectedItem: courseID
                    }),
                    mode: 'cors',
                    credentials: 'include'
                };
                fetch(url + "/api/sales", requestOptions)
                    .then(response => response.json())
                    .then(value => {
                        console.log(value);
                        if (value.status === 200) {
                            /* 예약 결제 완료 처리 */
                            requestOptions = {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                mode: 'cors',
                                credentials: 'include'
                            };

                            fetch("https://dev.flare.moe/" + "A" + value.data[0] + "/" + this.state.bucket.tel, requestOptions )
                            .then(response => response.json())
                            .then(value => {
                                console.log(value);
                                if (value.status === 200) {
                                    alert("결제 성공");
                                    window.location.href =  "/store/" + this.state.store_id + "/reservation/ticket";
                                } else {
                                    alert("서버 전송 실패");
                                }
                            });
                        } else {
                            alert("서버 전송 실패");
                        }
                    })
                    .catch(err => console.log(err));
            } else {

                var msg = '결제에 실패하였습니다.';
                msg += '에러내용 : ' + rsp.error_msg;

                alert("결제 실패"+ msg);
            }
        });

    }

    /* 카카오페이 결제 */
    kakaoPay() {
        if (this.state.totalPrice <= 0) {
            alert("결제할 금액이 없습니다.");
            return;
        }
        // 룸 이름
        var basketName = "";
        var courseId = null;
        for (var i = 0; i < this.state.input_course.length; i++) {
            if (this.state.bucket.basketId === this.state.input_course[i].id) {
                basketName = this.state.input_course[i].name;
                courseId = this.state.input_course[i].washCourse[this.state.bucket.clickedIndex_course].id;
            }
        }
        basketName += "_" + this.state.totalPrice + "_1_" + this.state.totalPrice;

        // if (basketName.length >= 100) {
        //     alert("카카오페이에서 지원하는 상품 종류 개수보다 많습니다. (최대 4~6개)");
        //     return;
        // }

        var tempDate1 = new Date();
        tempDate1.setHours(tempDate1.getHours() + 9);
        var pk = tempDate1.toISOString().slice(11, 25);

        var tempDate = new Date(this.state.bucket.selected_date);
        tempDate.setUTCSeconds(0);
        tempDate.setUTCMilliseconds(0);

        tempDate.setUTCHours(parseInt(this.state.bucket.setTime_hour));
        tempDate.setUTCMinutes(parseInt(this.state.bucket.setTime_min));
        var input_setTime = tempDate.toISOString();

        tempDate.setUTCHours(parseInt(this.state.bucket.getTime_hour));
        tempDate.setUTCMinutes(parseInt(this.state.bucket.getTime_min));
        var input_getTime = tempDate.toISOString();

        /* 서버 예약 요청 */
        var KWrequestOption = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                receiptNumber: pk,
                storeId: this.state.bucket.storeId,
                basketId: this.state.bucket.basketId,
                courseId: courseId,
                setTime: input_setTime,
                getTime: input_getTime,
                tel: this.state.bucket.tel,
                email: this.state.bucket.email,
                password: this.state.bucket.password,
                description: this.state.bucket.description,
                isDelivery: this.state.bucket.is_delivery,
                isPaid: false,
                isUsed: 0,
                isRefund: false
            }),
            mode: 'cors',
            credentials: 'include'
        };
        fetch(url + "/api/reservation/wash", KWrequestOption)
            .then(response => response.json())
            .then(value => {
                console.log(value);
                if (value.status === 400) {
                    alert("이미 예약이 있습니다.");
                    window.location.href = "/store/" + this.state.bucket.storeId + "/reservation-wash";
                } else if (value.status === 200) {
                    var requestOptions = {
                        method: 'POST',
                        headers: {
                            "Authorization": "KakaoAK 20844e233fd06b067fb74eb6d63468f3",
                            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
                        }
                    };
                    /* 카카오페이 요청 */
                    //package.json의 proxy 기반
                    //원래는 https://kapi.kakao.com/v1/payment/ready ~~
                    fetch("/v1/payment/ready"
                        + "?cid=" + this.state.store_info[0].kakaoId
                        + "&partner_order_id=hyun"
                        + "&partner_user_id=user"
                        + "&item_name=KWIN"
                        + "&quantity=1"
                        + "&item_code=" + basketName
                        + "&total_amount=" + this.state.totalPrice
                        + "&tax_free_amount=0"
                        + "&approval_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/success?wash=" + pk
                        + "&fail_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/fail"
                        + "&cancel_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/cancel", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            console.log(result);
                            document.cookie = "tid=" + result.tid + ";path=/store";
                            window.open(result.next_redirect_pc_url);
                        })
                        .catch(error => console.log('error', error));
                }
                else {
                    alert("알수 없는 오류로 실패하였습니다. 입력값을 확인하시고 다시 시도해 주세요.");
                    window.location.reload(false);      //페이지 새로고침
                }
            })
            .catch(err => {
                console.log(err);
                alert("예약에 실패하였습니다. 다시 시도해 주세요.");
                window.location.reload(false);      //페이지 새로고침
            });


    }

   callGetApi() {
      // 사물함 정보
      fetch(url + "/api/wash/course/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               this.setState({ input_course: value.data });

               // 설정 정보
               fetch(url + "/api/store-setting/reservation/wash/" + this.state.store_id)
                  .then(response => response.json())
                  .then(value => {
                     console.log(value);
                     if (value.status === 200) {
                        this.setState({ input_setting: value.data });
                        this.getPrice()
                     }
                  })
                  .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });
            }
         })
         .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });

       // 가맹점 정보 요청 (카카오, 아임포트)
       fetch(url + "/api/account/store-inform/simple/" + this.state.store_id)
           .then(response => response.json())
           .then(value => {
               console.log("store-Info", value);
               this.setState({ store_info: value.data });
           })
           .catch(err => {
               console.log(err);
               alert("서버로부터 데이터를 받아오지 못하였습니다.");
           });
   }

    componentDidMount() {
        this.callGetApi();
    }

    render() {
        if (this.state.bucket === null || this.state.bucket.length === 0) {
            return (
                <div style={{ minHeight: '80vh', textAlign: 'center' }}>
                    <h1>결제 정보가 비었습니다. 다시 시도해 주세요.</h1>
                </div>
            );
        } else {
            var basketName = null, courseName = null, description_name = "요청사항";
            for (var i = 0; i < this.state.input_course.length; i++) {
                if (this.state.bucket.courseId === this.state.input_course[i].id) {
                    courseName = this.state.input_course[i].name;
                    //courseName = this.state.input_course[i].washCourse[this.state.bucket.clickedIndex_course].name;
                }
            }
            if(this.state.bucket.is_delivery) {
               description_name="배송지";
            }

            return (
                <div style={{ minHeight: '80vh' }}>
                    <h2 style={{ margin: '20px 0px' }}>예약 확인</h2>
                    <Modal show={this.state.importModal} centered backdrop="static" >
                        <Modal.Header style={{ background: "#A7C1CC" }}>
                            <h2 style={{ margin: '0', fontWeight: 'bold', fontSize: '25px', color: '#F5F5F5' }}>결제 진행중입니다.</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{textAlign:'center'}}>
                                <h4>결제 처리중입니다.</h4>
                                <h5>페이지를 벗어나지 말아주세요</h5>
                                <img src="/images/Spinner.gif" />
                            </div>
                        </Modal.Body>
                    </Modal>
                    <table style={{ width: '100%', textAlign: 'center', fontSize: '17px' }}>
                        <tbody>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>코스</th>
                                <td style={{ border: '1px solid' }}>
                                    {courseName}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>전화번호</th>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.tel}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>이메일</th>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.email}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>비밀번호(숫자 6자리)</th>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.password}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>{description_name}</th>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.description}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'right', marginTop: '20px' }}>
                        <span
                            style={{
                                fontSize: '20px',
                                color: '#3C4856', fontWeight: '700'
                            }}
                        >총 {this.state.totalPrice.toLocaleString()}원
                        </span>
                        <img
                            src="/images/kakaoPay.png"
                            style={{ cursor: 'pointer', margin: '0px 10px' }}
                            onClick={this.kakaoPay.bind(this)}
                        />
                        <button onClick={this.requestPay} className="customButton3" style={{ width: '80px', height: '50px', padding: '0', margin: '0px 10px' }}>일반결제</button>
                    </div>
                    {/* <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ 결제시도 후에는 3분간 예약이 불가능합니다.</p>
                    <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ 결제가 완료되면 예약 완료 화면이 뜰 때 까지 기다려 주세요.</p> */}
                </div>
            );
        }
    }
}
