import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';  //bootstrap

//Custom component
import PageContent from './PageContent.js';
import Footer from './Footer.js';
import TopNav from './TopNav'

export default class Store extends Component {
    render() {
        return (
            <div>
                <TopNav url={this.props.url} />
                <PageContent url={this.props.url} />
                <Footer />
            </div>
        );
    }
}