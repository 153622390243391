import React, { Component } from 'react'
import Barcode from 'react-barcode'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class Ticket extends Component {
    constructor(props) {
        super(props)
        this.state = ({
            input_information: []
        });
    }

    callGetApi() {
        var requestOptions = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            mode: 'cors',
            credentials: 'include'
        };
        fetch(url + "/api/season/barcode", requestOptions)
            .then(response => response.json())
            .then(value => {
                this.setState({
                    input_information: value.data
                });
            })
            .catch(err => console.log(err));
    }
    componentDidMount() {
        this.callGetApi();
    }

    render() {
        var list = [];
        if (this.state.input_information) {
            for (var i = 0; i < this.state.input_information.length; i++) {
                list.push(
                    <div key={i} style={{ borderTop: '1px solid blue', marginTop: '50px' }}>
                        <Barcode value={this.state.input_information[i].receiptNumber}
                            format="CODE128"
                            fontSize={12}
                            width={1.4}
                        />
                        <p style={{ margin: '5px 0px' }}>{this.state.input_information[i].storeName}</p>
                        <p style={{ margin: '5px 0px' }}>{this.state.input_information[i].ticketName}</p>
                        <p style={{ margin: '5px 0px' }}>{this.state.input_information[i].endDate}까지</p>
                    </div>
                );
            }
            if (this.state.input_information.length === 0) {
                list = <p>정기권이 없습니다.</p>
            }
        }
        return (
            <div style={{ minHeight: '75vh', textAlign: 'center' }}>
                <h3 style={{ margin: '30px 0px' }}>정기권</h3>
                {list}
            </div>
        );
    }
}