import React, { Component } from 'react';

export default class NotFound extends Component {
    render() {
        return (
            <div style={{ minHeight: '80vh'}}>
                <h1>NOT FOUND</h1>
                <h1>404</h1>
            </div>
        );
    }
}