import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'

const ServerConfig = require('../../ServerConfig');
const url = ServerConfig.url;

export default class PaymentPoint extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store_id: this.props.match.params.storeId,
            point: JSON.parse(window.sessionStorage.getItem("point")),
            totalPrice: 0,
            point_list: [],
            input_setting: [],
            store_info: [],
            store_name: "",
            importModal: false  // 아임포트 결제 후 서버 통신중 모달창
        }
    }

    /* 총 가격 계산 */
    getPrice() {
        var total = 0;
        total += this.state.point.totalPrice;
        console.log(this.state.point.totalPrice);
        this.setState({ totalPrice: total });
    }

    /* 아임포트 결제 */
    requestPay = () => {

        if (this.state.totalPrice <= 0) {
            alert("결제할 금액이 없습니다.");
            return;
        }

       var IMP = window.IMP; // 생략해도 괜찮습니다.
       IMP.init(this.state.store_info[0].importId); // 발급받은 "가맹점 식별코드"를 사용합니다.
       //IMP.init('imp19424728'); //imp19424728 임시 코드.
        IMP.request_pay({ // param
            pay_method: "card",
       /*     merchant_uid:'merchant_' + new Date().getTime(), */
            merchant_uid: `mid_${new Date().getTime()}` ,
            name:"주문명:Point",
            amount:this.state.totalPrice,
            //amount:100,
            buyer_tel: this.state.point.tel
        }, rsp => {
            console.log(rsp);
            /* 서버에 결제결과 전송 */
            if (rsp.success) {
                this.setState({importModal:true});
                var tempDate2 = new Date(rsp.paid_at * 1000);
                tempDate2.setHours(tempDate2.getHours() + 9);
                var date = tempDate2.toISOString();

                // 예약 이름
                var pointName = "", pointId = "", point = "";
                for (var i = 0; i < this.state.point_list.length; i++) {
                    if (this.state.point.pointId === this.state.point_list[i].id) {
                        pointName = this.state.point_list[i].name;
                        pointId = this.state.point_list[i].id;
                        point = this.state.point_list[i].point;
                    }
                }
                pointName += "_" + rsp.paid_amount + "_1_" + rsp.paid_amount;

                console.log(pointName);
                var now = new Date();
                var expireday = new Date(now.setMonth(now.getMonth() + 1));

                var requestOptions = {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        receiptNumber: rsp.merchant_uid,
                        storeId: this.state.store_id,
                        salesMachineId: 80,
                        tel: rsp.butyer_tel,
                        goodsName: pointName,
                        totalPrice: rsp.paid_amount,
                        cardName: rsp.card_name,
                        cardNumber: rsp.card_number,
                        cardInstallment: rsp.card_quota,
                        approvalCode: rsp.apply_num,
                        approvalDate: date,
                        goodsServed: 1,
                        refund: 1,
                        accessCount: 5,       // 서버에서 받아오기
                        division: "wash",    // 음료만 결제
                        importId: rsp.imp_uid,
                        importPgId: rsp.pg_tid,
                        selectedItem: pointId,
                        commnet :"1",
                        storeName :"2",
                        endDate: expireday
                    }),
                    mode: 'cors',
                    credentials: 'include'
                };
                //결제후 포인트 적립
                
                fetch(url + "/api/sales", requestOptions)
                    .then(response => response.json())
                    .then(value => {
                        console.log(value);
                        if (value.status === 200) {
                            alert("결제 성공");
                            var tempDate1 = new Date();
                            tempDate1.setHours(tempDate1.getHours() + 9);
                            var now = new Date();
                            var expireday = new Date(now.setMonth(now.getMonth() + 1));
                            var requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    tel: this.state.point.tel,
                                    store_id: this.state.store_id,
                                    comment :"1",
                                    storeName :"2",
                                    endDate: expireday,
                                    point : this.state.point.point,
                                    store_name : this.state.store_name,

                                }),
                                mode: 'cors',
                                credentials: 'include'
                            };
                            //결제후 포인트 적립
                            fetch(url + "/api/point/save", requestOptions)
                                .then(response => response.json())
                                .then(value => {
                                    console.log(value);
                                    if (value.status === 200) {
                                        window.location.href =  "/store/" + this.state.store_id + "/point";
                                    }});
                        } else {
                            alert("결제 실패");
                        }
                    })
                    .catch(err => console.log(err));
            } else {

                var msg = '결제에 실패하였습니다.';
                msg += '에러내용 : ' + rsp.error_msg;

                alert("결제 실패"+ msg);

            }
        });

    }

    /* 카카오페이 결제 */
    kakaoPay() {
        if (this.state.totalPrice <= 0) {
            alert("결제할 금액이 없습니다.");
            return;
        }
        // 포인트 이름
        var pointName = "";
        var pointId = null;
        for (var i = 0; i < this.state.point_list.length; i++) {
            if (this.state.point.pointId === this.state.point_list[i].id) {
                pointName = this.state.point_list[i].name;
                pointId = this.state.point_list[i].id;
            }
        }
        pointName += "_" + this.state.totalPrice;

        // if (pointName.length >= 100) {
        //     alert("카카오페이에서 지원하는 상품 종류 개수보다 많습니다. (최대 4~6개)");
        //     return;
        // }

        var tempDate1 = new Date();
        tempDate1.setHours(tempDate1.getHours() + 9);     
        var pk = tempDate1.toISOString().slice(11, 25);
        var now = new Date();
        var expireday = new Date(now.setMonth(now.getMonth() + 1));

        var requestOptions = {
            method: 'POST',
            headers: {
                "Authorization": "KakaoAK 20844e233fd06b067fb74eb6d63468f3",
                "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
            },
            body: JSON.stringify({
                tel: this.state.point.tel,
                store_id: this.state.store_id,
                comment :"1",
                storeName :"2",
                endDate: expireday,
                point : this.state.point.point,
                store_name : this.state.store_name,
            }),
        };
        /* 카카오페이 요청 */
        //package.json의 proxy 기반
        //원래는 https://kapi.kakao.com/v1/payment/ready ~~
        fetch("/v1/payment/ready"
            + "?cid=" + this.state.store_info[0].kakaoId
            + "&partner_order_id=hyun"
            + "&partner_user_id=user"
            + "&item_name=KWIN"
            + "&quantity=1"
            + "&item_code=" + pointName
            + "&total_amount=" + this.state.totalPrice
            + "&tax_free_amount=0"
            + "&approval_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/success?point="+pk
            + "&fail_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/fail"
            //+ "&cancel_url=http://localhost/store/" + this.state.store_id + "/kakao/success?point="+pk, requestOptions)
            + "&cancel_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/cancel", requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result);
                document.cookie = "tid=" + result.tid + ";path=/store";

                window.sessionStorage.setItem("point", JSON.stringify({
                    tel: this.state.point.tel,
                    store_id: this.state.store_id,
                    comment :"1",
                    storeName :"2",
                    endDate: expireday,
                    point : this.state.point.point,
                    store_name : this.state.store_name,
                 }));
                if(! /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                    //window.open(result.next_redirect_pc_url);
                    window.location.href = result.next_redirect_pc_url;
                }else{
                    window.open(result.next_redirect_mobile_url);
                }
            })
            .catch(error => console.log('error', error));

    }

   callGetApi() {
      // point list 정보
      fetch(url + "/api/goods/charging/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               this.setState({ point_list: value.data });
               this.getPrice();
            }
         })
         .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });

       // 가맹점 정보 요청 (카카오, 아임포트)
       fetch(url + "/api/account/store-inform/simple/" + this.state.store_id)
           .then(response => response.json())
           .then(value => {
               console.log("store-Info", value);
               this.setState({ store_info: value.data });
           })
           .catch(err => {
               console.log(err);
               alert("서버로부터 데이터를 받아오지 못하였습니다.");
           });

        fetch(url + "/api/account/store-inform/storename/" +  this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ store_name: value.data[0] });
         })
         .catch(err => { console.log(err); });
     
   }
    // 가맹점명  
   
    componentDidMount() {
        this.callGetApi();
    }

    render() {
        if (this.state.point === null || this.state.point.length === 0) {
            return (
                <div style={{ minHeight: '80vh', textAlign: 'center' }}>
                    <h1>결제 정보가 비었습니다. 다시 시도해 주세요.</h1>
                </div>
            );
        } else {
            var  pointName = null, description_name = "요청사항";
            for (var i = 0; i < this.state.point_list.length; i++) {
                if (this.state.point.pointId === this.state.point_list[i].id) {
                    pointName = this.state.point_list[i].name;
                    //courseName = this.state.point_list[i].washCourse[this.state.point.clickedIndex_course].name;
                }
            }

            return (
                <div style={{ minHeight: '80vh' }}>
                    <h2 style={{ margin: '20px 0px' }}>포인트 결제 확인</h2>
                    <Modal show={this.state.importModal} centered backdrop="static" >
                        <Modal.Header style={{ background: "#A7C1CC" }}>
                            <h2 style={{ margin: '0', fontWeight: 'bold', fontSize: '25px', color: '#F5F5F5' }}>결제 진행중입니다.</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{textAlign:'center'}}>
                                <h4>결제 처리중입니다.</h4>
                                <h5>페이지를 벗어나지 말아주세요</h5>
                                <img src="/images/Spinner.gif" />
                            </div>
                        </Modal.Body>
                    </Modal>
                    <table style={{ width: '100%', textAlign: 'center', fontSize: '17px' }}>
                        <tbody>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>포인트</th>
                                <td style={{ border: '1px solid' }}>
                                    {pointName}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>전화번호</th>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.point.tel}
                                </td>
                            </tr>
                            <tr>
                                <th style={{ border: '1px solid', padding: '10px 0px' }}>이메일</th>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.point.email}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'right', marginTop: '20px' }}>
                        <span
                            style={{
                                fontSize: '20px',
                                color: '#3C4856', fontWeight: '700'
                            }}
                        >총 {this.state.totalPrice.toLocaleString()}원
                        </span>
                        {/* <img
                            src="/images/kakaoPay.png"
                            style={{ cursor: 'pointer', margin: '0px 10px' }}
                            onClick={this.kakaoPay.bind(this)}
                        /> */}
                        <button onClick={this.requestPay.bind(this)} className="customButton3" style={{ width: '80px', height: '50px', padding: '0', margin: '0px 10px' }}>결제</button>
                    </div>
                    {/* <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ 결제시도 후에는 3분간 예약이 불가능합니다.</p>
                    <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ 결제가 완료되면 예약 완료 화면이 뜰 때 까지 기다려 주세요.</p> */}
                </div>
            );
        }
    }
}