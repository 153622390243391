import React, { Component } from 'react';
import $ from "jquery"
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'

export default class TopNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentUrl: "",             //현재 페이지 (마지막 쿼리스트링)
      module_information: [],
      user_name: "",
      store_name: "",
      store_id: -1
    }
  }

  /* 마지막 url 받아오기 */
  getCurrentUrl() {
   var CurrentUrl = window.location.href.split('/');   //현재 url을 /로 나눠서 받아오기
   var queryString = CurrentUrl[CurrentUrl.length - 1];
   this.setState({ currentUrl: queryString });
  }

  // 5분전 알람받기 버튼 이벤트 (RN)
  postAlarm() {
    // 웹뷰 어플리케이션인지 체크
    const isWebView = navigator.userAgent.includes('wv')
    if (isWebView) {
       // 전화번호 입력받고 RN에 전송
       var input_tel = prompt("구매하실 때 입력하셨던 전화번호를 입력해주세요.");
       if (input_tel === null || input_tel === "") {
          alert("공백이 될 수 없습니다.");
          return;
       }
       var input_time = prompt("몇분 전에 알람을 받으시겠습니까? (숫자만 입력)");
       if (input_time === null || input_time === "") {
          input_time = "5";
       }

      var result = JSON.stringify({
         input_tel: input_tel,
         input_time: input_time
      });
      window.ReactNativeWebView.postMessage(result);
    }
    else { alert("어플리케이션에서만 실행 가능합니다."); }
  }

  mobileNavigation() {
    $('.mobileNav').slideToggle("slow");
  }

  tryLogout() {
    var requestOptions = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include'
    };
    fetch(this.props.url + "/api/members/logout", requestOptions)
      .then(response => response.json())
      .then(value => {
        document.cookie = 'name=; expires=Thu, 01 Jan 1999 00:00:10 GMT; path=/';
        alert("로그아웃 되었습니다.");
        window.location.reload();
      })
      .catch(err => alert("err: " + err));
  }

  callGetApi() {

    var storeId = window.location.href.split('store')[1].split('/')[1];

    this.setState({ store_id: storeId });

    var requestOptions = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      credentials: 'include'
    };

    
    // 기능 설정
    fetch(this.props.url + "/api/store-setting/service-module/" + storeId)
      .then(response => response.json())
      .then(value => {
        this.setState({ module_information: value.data[0] });    //메인설정 정보
      })
      .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });

    // 로그인 여부
    fetch(this.props.url + "/api/members/is-login", requestOptions)
      .then(response => response.text())
      .then(value => {
        if (value !== "no" && value.length < 50  ){
           this.setState({ user_name: value });
        }
      })
      .catch(err => { console.log(err); });

    // 가맹점명  
    fetch(this.props.url + "/api/account/store-inform/storename/" + storeId)
      .then(response => response.json())
      .then(value => {
        this.setState({ store_name: value.data[0] });
      })
      .catch(err => { console.log(err); });
  }

  componentDidMount() {

    this.callGetApi();
    this.getCurrentUrl();
  }

  render() {
    //가맹점명
    var storeName = "";
    if (this.state.store_name !== "")
      storeName = this.state.store_name;

    var storeId = -1;
    if (this.state.store_id !== -1)
      storeId = this.state.store_id;

    //로그인 여부
    var login_url = "/login?rtn=" + document.location.href;
    var needLogin = <Nav.Link href={login_url} style={{ color: 'rgba(255,200,200,0.7)' }}>로그인</Nav.Link>;
    var memberName = this.state.user_name;

    if (memberName) {
      needLogin =
        <NavDropdown title={memberName} id="basic-nav-dropdown">
          <NavDropdown.Item href={`/store/${storeId}/profile`}>내 정보</NavDropdown.Item>
          <NavDropdown.Item href={`/store/${storeId}/ticket`}>정기권 조회</NavDropdown.Item>
          <NavDropdown.Item href={`/store/${storeId}/point`}>포인트 조회</NavDropdown.Item>
          <NavDropdown.Item onClick={this.tryLogout.bind(this)}>로그아웃</NavDropdown.Item>
        </NavDropdown>
    }

    // 메뉴 목록
    var orderNav = "", reservationNav = "", printingNav = "";
    if (this.state.module_information.isCommodity) {
      orderNav = <Nav.Link href={`/store/${storeId}/order`}>주문하기</Nav.Link>;
    }
    if (this.state.module_information.isReservationRoom || this.state.module_information.isReservationWash) {
      var tempReservationBtn1 = null, tempReservationBtn2 = null;
      if (this.state.module_information.isReservationRoom)
        tempReservationBtn1 = <NavDropdown.Item href={`/store/${storeId}/reservation`}>룸 예약</NavDropdown.Item>;
      if (this.state.module_information.isReservationWash)
        tempReservationBtn2 = <NavDropdown.Item href={`/store/${storeId}/reservation-wash`}>세탁 예약</NavDropdown.Item>

      reservationNav =
        <NavDropdown title="예약하기" id="basic-nav-dropdown">
          {tempReservationBtn1}
          {tempReservationBtn2}
          <NavDropdown.Item href={`/store/${storeId}/reservation/ticket`}>예약 조회</NavDropdown.Item>
        </NavDropdown>;
    }
    if (this.state.module_information.isDocument || this.state.module_information.isPhoto) {
      printingNav = <Nav.Link href={`/store/${storeId}/printing`}>출력하기</Nav.Link>;
    }

    // 모니터링 목록
    var laundryNav = "", ottNav = "", roomNav = "";
    if (this.state.module_information.isServiceWash) {
      laundryNav = <NavDropdown.Item href={`/store/${storeId}/monitoring/laundry`}>세탁 현황</NavDropdown.Item>
    }
    if (this.state.module_information.isServiceOtt) {
      ottNav = <NavDropdown.Item href={`/store/${storeId}/monitoring/ott`}>태블릿 현황</NavDropdown.Item>
    }
    if (this.state.module_information.isServiceRoom) {
      roomNav = <NavDropdown.Item href={`/store/${storeId}/monitoring/room`}>룸 현황</NavDropdown.Item>
    }

    return (
       <Navbar bg="dark" expand='xl' variant="dark" fixed='top'>
        <Navbar.Brand style={{ fontSize: '25px' }} href={`/store/${storeId}`}>{storeName}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" style={{ fontSize: '20px' }}>
            <Nav.Link href={`/store/${storeId}/about`}>가맹점 정보</Nav.Link>
            <Nav.Link href={`/store/${storeId}/spotpay`}>현장결제</Nav.Link>
            <Nav.Link href={`/store/${storeId}/pointcharge`}>포인트 충전</Nav.Link>
            {orderNav}
            {printingNav}
            {reservationNav}
            <NavDropdown title="실시간 정보" id="basic-nav-dropdown">
              {laundryNav}
              {ottNav}
              {roomNav}
            </NavDropdown>
          </Nav>
          <Nav style={{ marginRight: '100px', fontSize: '20px' }}>
            <Nav.Link onClick={this.postAlarm}>푸시알림 받기</Nav.Link>
            {needLogin}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}