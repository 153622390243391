import React, { Component } from 'react'
import $ from 'jquery'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class Printing extends Component {
   constructor(props) {
      super(props)
      this.state = ({
         storeId: this.props.match.params.storeId,
         docu_tel: "",
         image_tel: "",
         selectedDocu: null,
         selectedImages: null,
         module_information: []
      })
   }

   /* 문서 전송 */
   submitDocument() {
      var docu_pw = $('.docu_pw').val();
      var docu_pw_re = $('.docu_pw_re').val();
      var docu_tel = this.state.docu_tel;

      if (docu_tel.length < 10 || docu_pw.length !== 4) {
         alert("전화번호와 비밀번호를 확인해 주세요.");
         return;
      }
      if (docu_pw !== docu_pw_re) {
         alert("비밀번호가 다릅니다.");
         return;
      }
      if (!this.state.selectedDocu) {
         alert("파일을 선택해 주세요.");
         return;
      }

      var fileInput = this.state.selectedDocu;
      var formData = new FormData();
      formData.append('file', fileInput);

      var extensionOfFile = fileInput.name.slice(fileInput.name.lastIndexOf(".") + 1).toLowerCase();

      if (extensionOfFile === "hwp" || extensionOfFile === "doc" || extensionOfFile === "docx"
         || extensionOfFile === "ppt" || extensionOfFile === "pptx" || extensionOfFile === "xls"
         || extensionOfFile === "xlsx" || extensionOfFile === "pdf") {
         var requestOptions = {
            method: 'POST',
            headers: {},
            mode: 'cors',
            body: formData
         }
         fetch("https://kwinnovation.kr/api/file/upload/document/" + this.state.storeId
            + "?tel=" + docu_tel
            + "&password=" + docu_pw, requestOptions
         )
            .then(response => response.json())
            .then(value => {
               console.log(value);
               if (value.status === 200) {
                  alert("문서가 전송되었습니다.");
               } else {
                  alert("문서 전송에 실패하였습니다.");
               }
               window.location.reload();
            })
            .catch(err => alert("문서 전송에 실패하였습니다."));
      } else {
         alert("지원하지 않는 파일 형식입니다.")
      }

   }

   /* 사진 전송 */
   submitImages() {
      var image_pw = $('.image_pw').val();
      var image_pw_re = $('.image_pw_re').val();
      var image_tel = this.state.image_tel;

      if (image_tel.length < 10 || image_pw.length !== 4) {
         alert("전화번호와 비밀번호를 확인해 주세요.");
         return;
      }
      if (image_pw !== image_pw_re) {
         alert("비밀번호가 다릅니다.");
         return;
      }
      if (!this.state.selectedImages) {
         alert("파일을 선택해 주세요.");
         return
      }

      var fileInput = this.state.selectedImages;
      var formData = new FormData();
      var i = 0;
      while (i < fileInput.length) {
         formData.append('file', fileInput[i]);
         i = i + 1;
      }

      var requestOptions = {
         method: 'POST',
         headers: {},
         mode: 'cors',
         body: formData
      }
      fetch("https://kwinnovation.kr/api/file/upload/images/" + this.state.storeId
         + "?tel=" + image_tel
         + "&password=" + image_pw, requestOptions
      )
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               alert("사진이 전송되었습니다.");
            } else {
               alert("사진 전송에 실패하였습니다.");
            }
            window.location.reload();
         })
         .catch(err => alert("사진 전송에 실패하였습니다."));
   }

   /* 문서 이름 출력 */
   showFileName(e) {
      var files = e.target.files;
      if (files[0]) {
         $('.docuList').text(files[0].name);
         $('#docuIcon').css('display', 'none');
         this.setState({ selectedDocu: e.target.files[0] });
      } else {
         $('.docuList').text("");
         $('#docuIcon').css('display', 'inline');
         this.setState({ selectedDocu: null });
      }
   }

   /* 사진 출력 */
   showImages(e) {
      var files = e.target.files;

      $('.imageList').text("");
      $(".imgs_wrap").empty();

      if (files.length > 0) {
         this.setState({ selectedImages: e.target.files });

         var i = 0;
         while (i < files.length) {
            var reader = new FileReader();

            $('.imageList').append(files[i].name + "<br />");

            reader.onload = function (e) {
               var img_html = "<img src=\"" + e.target.result + "\" width='90%' style=\"margin:15px 5px; border:5px solid white;\" />";
               $(".imgs_wrap").append(img_html);
            }
            reader.readAsDataURL(files[i]);

            i = i + 1;
         }
      } else {
         this.setState({ selectedImages: null });
      }
   }

   /* 문서 전화번호 숫자만 입력받기 */
   updateDocuTel = (e) => {
      if (e.target.validity.valid) {
         this.setState({ docu_tel: e.target.value });
      }
   }

   /* 사진 전화번호 숫자만 입력받기 */
   updateImageTel = (e) => {
      if (e.target.validity.valid) {
         this.setState({ image_tel: e.target.value });
      }
   }

   callGetApi() {
      // 모듈 설정
      fetch(url + "/api/store-setting/service-module/" + this.state.storeId)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            this.setState({ module_information: value.data });    //메인설정 정보
         })
         .catch(err => { console.log(err); alert("서버로부터 데이터를 받아오지 못하였습니다."); });
   }

   componentDidMount() {
      this.callGetApi();
   }

   render() {
      var print_docu = null, print_photo = null;

      if (this.state.module_information.length > 0) {

         // 전체 div 넓이 설정
         var whole_width = "75%";
         var whole_border = 'none'
         if (this.state.module_information[0].isDocument && this.state.module_information[0].isPhoto) {
            whole_width = "50%";
            whole_border = "1px solid"
         }

         if (this.state.module_information[0].isDocument) {
            print_docu = (
               <div style={{ borderRight: whole_border, width: whole_width, margin: '0 auto' }}>
                  <h2 style={{ borderBottom: '3px solid #8DAFCE', margin: '0px 20px', paddingBottom: '10px' }}>문서 출력</h2>

                  <div style={{ width: '100%', textAlign: 'center', margin: '20px 0px' }}>
                     <div style={{ border: '1px solid', width: '50%', maxWidth: '200px', minWidth: '100px', height: '200px', margin: '10px auto' }}>
                        <p>출력할 문서</p>
                        <p className="docuList"></p>
                        <svg display="inline" id="docuIcon" width="50%" height="50%" viewBox="0 0 16 16" className="bi bi-file-earmark-arrow-up-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                           <path fillRule="evenodd" d="M2 3a2 2 0 0 1 2-2h5.293a1 1 0 0 1 .707.293L13.707 5a1 1 0 0 1 .293.707V13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3zm7 2V2l4 4h-3a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z" />
                        </svg>
                     </div>
                     <input type="file" accept=".hwp, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .pdf" onChange={this.showFileName.bind(this)}></input>
                  </div>

                  <div style={{ textAlign: 'center' }}>
                     <input
                        type="text"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        placeholder="핸드폰번호"
                        value={this.state.docu_tel}
                        onChange={this.updateDocuTel}
                        maxLength="11"
                        style={{ margin: '5px 0px', width: '50%', padding: '5px 0px' }}
                     /><br />
                     <input
                        type="password"
                        className="docu_pw"
                        placeholder="비밀번호 (4자리 숫자)"
                        maxLength="4"
                        style={{ margin: '5px 0px', width: '50%', padding: '5px 0px' }}
                     /><br />
                     <input
                        type="password"
                        className="docu_pw_re"
                        placeholder="비밀번호 재입력"
                        maxLength="4"
                        style={{ margin: '5px 0px', width: '50%', padding: '5px 0px' }}
                     /><br />

                     <button
                        className="customButton"
                        onClick={this.submitDocument.bind(this)}
                        style={{ padding: '5px 15px', marginTop: '20px' }}
                     >문서 전송</button>
                  </div>
               </div>
            );
         }

         if (this.state.module_information[0].isPhoto) {
            print_photo = (
               <div style={{ width: whole_width, margin: '0 auto' }}>
                  <h2 style={{ borderBottom: '3px solid #8DAFCE', margin: '0px 20px', paddingBottom: '10px' }}>사진 인화</h2>

                  <div style={{ width: '100%', textAlign: 'center', margin: '20px 0px' }}>
                     <div style={{ border: '1px solid', width: '50%', maxWidth: '200px', minWidth: '100px', height: '200px', margin: '10px auto', overflow: 'auto' }}>
                        <p>인쇄할 사진</p>
                        <p className="imageList"></p>
                     </div>
                     <input type="file" onChange={this.showImages.bind(this)} multiple accept="image/*"></input>
                  </div>

                  <div style={{ textAlign: 'center' }}>
                     <input
                        type="text"
                        pattern="^-?[0-9]\d*\.?\d*$"
                        placeholder="핸드폰번호"
                        value={this.state.image_tel}
                        onChange={this.updateImageTel}
                        maxLength="11"
                        style={{ margin: '5px 0px', width: '50%', padding: '5px 0px' }}
                     /><br />
                     <input
                        type="password"
                        className="image_pw"
                        placeholder="비밀번호 (4자리 숫자)"
                        maxLength="4"
                        style={{ margin: '5px 0px', width: '50%', padding: '5px 0px' }}
                     /><br />
                     <input
                        type="password"
                        className="image_pw_re"
                        placeholder="비밀번호 재입력"
                        maxLength="4"
                        style={{ margin: '5px 0px', width: '50%', padding: '5px 0px' }}
                     /><br />

                     <button
                        className="customButton"
                        onClick={this.submitImages.bind(this)}
                        style={{ padding: '5px 15px', marginTop: '20px' }}
                     >사진 전송</button>
                     <div className="imgs_wrap" style={{ background: '#141E30', width: '100%', maxHeight: '700px', overflow: 'auto' }}></div>
                  </div>
               </div>
            );
         }
      }

      return (
         <div style={{ minHeight: '80vh' }}>
            <h1 style={{ margin: '30px 0px' }}>출력하기</h1>
            <div className="row" style={{ overflow: 'hidden' }}>
               {/* 문서 출력 */}
               {print_docu}

               {/* 사진 인화 */}
               {print_photo}
            </div>
         </div>
      );
   }
}