import React, { Component } from 'react';
import { Modal } from 'react-bootstrap'

const ServerConfig = require('../../ServerConfig');
const url = ServerConfig.url;

export default class PaymentRoom extends Component {
    constructor(props) {
        super(props)
        this.state = {
            store_id: this.props.match.params.storeId,
            bucket: JSON.parse(window.sessionStorage.getItem("bucket")),
            totalPrice: 0,
            room_list: [],
            discount: [],
            setting_reservation: [],
            store_info: [],
            is_holiday: false,
            importModal: false  // 아임포트 결제 후 서버 통신중 모달창
        }
    }

    /* 공휴일 구하고 가격 계산 실행 */
    getTotal() {
        // 주말 판별
        var is_holiday = false;
        // 요일 (0~6 : 일~토)
        var day = new Date(this.state.bucket.startDate).getDay();
        if (day === 0 || day === 6) is_holiday = true;

        // 주말이면
        if (is_holiday) {
            this.getPrice(true);
        }
        // 주중이면
        else {
            fetch(url + "/api/reservation/holiday/" + this.state.bucket.endDate.slice(0, 4) + "/" + this.state.bucket.endDate.slice(5, 7))
                .then(response => response.json())
                .then(value => {
                    //공휴일 판별
                    var result = value.data[0].response.body;
                    if (result.totalCount > 0) {
                        for (var i = 0; i < result.items.item.length; i++) {
                            console.log(result.items.item[i].locdate.toString().slice(6, 8), this.state.bucket.endDate.slice(8, 10));
                            if (result.items.item[i].locdate.toString().slice(6, 8) === this.state.bucket.endDate.slice(8, 10)) {
                                is_holiday = true;
                                break;
                            }
                        }
                    }
                    this.getPrice(is_holiday);
                })
                .catch(err => console.log(err));
        }
    }

    /* 총 가격 계산 */
    getPrice(is_holiday) {
        var timeDiff = (new Date(this.state.bucket.endDate).getTime() - new Date(this.state.bucket.startDate).getTime()) / 1000;
        var timeDiff_hour = Math.ceil(timeDiff / 3600);   //시간 올림

        // 룸 id에 해당하는 인덱스 구하기
        var roomIndex = 0;
        for (var i = 0; i < this.state.room_list.length; i++) {
            if (this.state.bucket.roomId === this.state.room_list[i].id) {
                roomIndex = i;
                break;
            }
        }

        // 인원 수 구하기 (기본인원 계산)
        var numOfPeople = 0;
        numOfPeople = this.state.room_list[roomIndex].basicNumPerson;
        if (this.state.bucket.numOfPeople > numOfPeople)
            numOfPeople = this.state.bucket.numOfPeople;


        // 총 가격 구하기
        var total = 0;
        if (is_holiday) {
            for (var i = 0; i < timeDiff_hour; i++) {
                // 차등 가격 설정
                var roomPrice = 0;
                if (i === 0) roomPrice = this.state.room_list[roomIndex].hprice1;
                else if (i === 1) roomPrice = this.state.room_list[roomIndex].hprice2;
                else if (i === 2) roomPrice = this.state.room_list[roomIndex].hprice3;
                else if (i === 3) roomPrice = this.state.room_list[roomIndex].hprice4;
                else roomPrice = this.state.room_list[roomIndex].hprice5;

                console.log(total + " + (" + roomPrice + " - " + this.state.setting_reservation[0].discountPerHour + ")" + "*" + numOfPeople);
                total = total + ((roomPrice - this.state.setting_reservation[0].discountPerHour) * numOfPeople);
            }

        } else {
            for (var i = 0; i < timeDiff_hour; i++) {
                // 차등 가격 설정
                var roomPrice = 0;
                if (i === 0) roomPrice = this.state.room_list[roomIndex].price1;
                else if (i === 1) roomPrice = this.state.room_list[roomIndex].price2;
                else if (i === 2) roomPrice = this.state.room_list[roomIndex].price3;
                else if (i === 3) roomPrice = this.state.room_list[roomIndex].price4;
                else roomPrice = this.state.room_list[roomIndex].price5;

                console.log(total + " + (" + roomPrice + " - " + this.state.setting_reservation[0].discountPerHour + ")" + "*" + numOfPeople);
                total = total + ((roomPrice - this.state.setting_reservation[0].discountPerHour) * numOfPeople);
            }
        }

        // 할인시간 적용
        var start_hour = parseInt(this.state.bucket.startDate.slice(11, 13));
        var end_hour = start_hour + timeDiff_hour;  //분단위 올림처리

        var discount_start_hour = parseInt(this.state.discount.startTime);
        var discount_end_hour = parseInt(this.state.discount.endTime);

        for (var i = start_hour; i < end_hour; i++) {
            if (discount_start_hour < discount_end_hour) {
                if (i >= discount_start_hour) {
                    console.log(total + " - " + parseInt(this.state.discount.discount) + "*" + numOfPeople)
                    total = total - (parseInt(this.state.discount.discount) * numOfPeople);
                }
            } else {
                if (i < discount_start_hour) {
                    console.log(total + " - " + parseInt(this.state.discount.discount) + "*" + numOfPeople)
                    total = total - (parseInt(this.state.discount.discount) * numOfPeople);
                }
            }
        }

        this.setState({ totalPrice: total });
    }

    /* 아임포트 결제 */
    requestPay = () => {
        if (this.state.totalPrice <= 0) {
            alert("결제할 금액이 없습니다.");
            return;
        }
        
        /* 서버 예약 요청 */
        var tempDate1 = new Date();
        tempDate1.setHours(tempDate1.getHours() + 9);
        var pk = tempDate1.toISOString().slice(11, 25);

        var KWrequestOption = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                receiptNumber: pk,
                storeId: this.state.bucket.storeId,
                roomId: this.state.bucket.roomId,
                startDate: this.state.bucket.startDate,
                endDate: this.state.bucket.endDate,
                numOfPeople: this.state.bucket.numOfPeople,
                tel: this.state.bucket.tel,
                email: this.state.bucket.email,
                password: this.state.bucket.password,
                isPaid: false,
                isUsed: false,
                isRefund: false
            }),
            mode: 'cors',
            credentials: 'include'
        };
        fetch(url + "/api/reservation/room", KWrequestOption)
            .then(response => response.json())
            .then(value => {
                console.log(value);
                if (value.status === 400) {
                    alert("이미 예약이 있습니다.");
                    window.location.href =  "/store/" + this.state.store_id + "/reservation";
                }
                else if (value.status === 200) {
                    /* 아임포트 결제 요청 */
                    var IMP = window.IMP; // 생략해도 괜찮습니다.
                    IMP.init(this.state.store_info[0].importId); // 발급받은 "가맹점 식별코드"를 사용합니다.

                    IMP.request_pay({ // param
                        pg: "",
                        pay_method: "card",
                        merchant_uid: "",
                        name: "KWIN",
                        // amount: 100
                        amount: this.state.totalPrice
                        // buyer_email: "gildong@gmail.com",
                        // buyer_name: "홍길동",
                        // buyer_tel: "010-4242-4242",
                        // buyer_addr: "서울특별시 강남구 신사동",
                        // buyer_postcode: "01181"
                    }, rsp => {
                        console.log(rsp);
                        /* 서버에 결제결과 전송 */
                        if (rsp.success) {
                            this.setState({importModal:true});
                            var tempDate = new Date(rsp.paid_at * 1000);
                            tempDate.setHours(tempDate.getHours() + 9);
                            var date = tempDate.toISOString();

                            // 룸 이름
                            var roomName = "";
                            var roomIndex = 0;
                            for (var i = 0; i < this.state.room_list.length; i++) {
                                if (this.state.bucket.roomId === this.state.room_list[i].id) {
                                    roomName = this.state.room_list[i].name;
                                    roomIndex = i;
                                }
                            }
                            // 시간
                            var timeDiff = (new Date(this.state.bucket.endDate).getTime() - new Date(this.state.bucket.startDate).getTime()) / 1000;
                            var timeDiif_hour = Math.ceil(timeDiff / 3600);   //시간 올림
                            // 사람 인원수
                            var numOfPeople = 0;
                            numOfPeople = this.state.room_list[roomIndex].basicNumPerson;
                            if (this.state.bucket.numOfPeople > numOfPeople)
                                numOfPeople = this.state.bucket.numOfPeople;

                            roomName += "(" + timeDiif_hour + "시간/" + numOfPeople + "인)";
                            roomName += "_" + this.state.totalPrice + "_1_" + this.state.totalPrice;

                            var requestOptions = {
                                method: 'POST',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    receiptNumber: rsp.merchant_uid,
                                    storeId: this.state.store_id,
                                    salesMachineId: 80,
                                    tel: rsp.butyer_tel,
                                    goodsName: roomName,
                                    totalPrice: rsp.paid_amount,
                                    cardName: rsp.card_name,
                                    cardNumber: rsp.card_number,
                                    cardInstallment: rsp.card_quota,
                                    approvalCode: rsp.apply_num,
                                    approvalDate: date,
                                    goodsServed: 1,
                                    refund: 1,
                                    endDate: date,
                                    accessCount: 5,       // 서버에서 받아오기
                                    division: "room",    // 음료만 결제
                                    importId: rsp.imp_uid,
                                    importPgId: rsp.pg_tid,
                                    selectedItem: this.state.bucket.roomId
                                }),
                                mode: 'cors',
                                credentials: 'include'
                            };
                            fetch(url + "/api/sales", requestOptions)
                                .then(response => response.json())
                                .then(value => {
                                    console.log(value);
                                    if (value.status === 200) {
                                        /* 예약 결제 완료 처리 */
                                        requestOptions = {
                                            method: 'PATCH',
                                            headers: {
                                                'Accept': 'application/json',
                                                'Content-Type': 'application/json'
                                            },
                                            mode: 'cors',
                                            credentials: 'include'
                                        };

                                        fetch(url + "/api/reservation/room/" + pk + "/" + value.data[0], requestOptions)
                                            .then(response => response.json())
                                            .then(value => {
                                                if (value.status === 200) {
                                                    alert("예약 성공");
                                                    window.location.href =  "/store/" + this.state.store_id + "/reservation/ticket";
                                                } else {
                                                    alert("서버 전송 실패");
                                                }
                                            })
                                            .catch(err => console.log(err));
                                    } else {
                                        alert("서버 전송 실패");
                                    }
                                })
                                .catch(err => console.log(err));
                        } else {
                            alert("결제 실패");
                        }
                    });
                }
            }).catch(err => { console.log(err); alert("예약 요청 실패"); });


    }

    /* 카카오페이 결제 */
    kakaoPay() {
        if (this.state.totalPrice <= 0) {
            alert("결제할 금액이 없습니다.");
            return;
        }
        // 룸 이름
        var roomName = "";
        var roomIndex = 0;
        for (var i = 0; i < this.state.room_list.length; i++) {
            if (this.state.bucket.roomId === this.state.room_list[i].id) {
                roomName = this.state.room_list[i].name;
                roomIndex = i;
            }
        }
        // 시간
        var timeDiff = (new Date(this.state.bucket.endDate).getTime() - new Date(this.state.bucket.startDate).getTime()) / 1000;
        var timeDiif_hour = Math.ceil(timeDiff / 3600);   //시간 올림
        // 사람 인원수
        var numOfPeople = 0;
        numOfPeople = this.state.room_list[roomIndex].basicNumPerson;
        if (this.state.bucket.numOfPeople > numOfPeople)
            numOfPeople = this.state.bucket.numOfPeople;

        roomName += "(" + timeDiif_hour + "시간/" + numOfPeople + "인)";
        roomName += "_" + this.state.totalPrice + "_1_" + this.state.totalPrice;

        // if (roomName.length >= 100) {
        //     alert("카카오페이에서 지원하는 상품 종류 개수보다 많습니다. (최대 4~6개)");
        //     return;
        // }

        var tempDate = new Date();
        tempDate.setHours(tempDate.getHours() + 9);
        var pk = tempDate.toISOString().slice(11, 25);
        /* 서버 예약 요청 */
        var KWrequestOption = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                receiptNumber: pk,
                storeId: this.state.bucket.storeId,
                roomId: this.state.bucket.roomId,
                startDate: this.state.bucket.startDate,
                endDate: this.state.bucket.endDate,
                numOfPeople: this.state.bucket.numOfPeople,
                tel: this.state.bucket.tel,
                email: this.state.bucket.email,
                password: this.state.bucket.password,
                isPaid: false,
                isUsed: false,
                isRefund: false
            }),
            mode: 'cors',
            credentials: 'include'
        };
        fetch(url + "/api/reservation/room", KWrequestOption)
            .then(response => response.json())
            .then(value => {
                console.log(value);
                if (value.status === 400) {
                    alert("이미 예약이 있습니다.");
                    window.location.href =  "/store/" + this.state.store_id + "/reservation";
                } else if (value.status === 200) {
                    var requestOptions = {
                        method: 'POST',
                        headers: {
                            "Authorization": "KakaoAK 20844e233fd06b067fb74eb6d63468f3",
                            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
                        }
                    };
                    /* 카카오페이 요청 */
                    //package.json의 proxy 기반
                    //원래는 https://kapi.kakao.com/v1/payment/ready ~~
                    fetch("/v1/payment/ready"
                        + "?cid=" + this.state.store_info[0].kakaoId
                        + "&partner_order_id=hyun"
                        + "&partner_user_id=user"
                        + "&item_name=KWIN"
                        + "&quantity=1"
                        + "&item_code=" + roomName
                        + "&total_amount=" + this.state.totalPrice
                        + "&tax_free_amount=0"
                        + "&approval_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/success?pk=" + pk
                        + "&fail_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/fail"
                        + "&cancel_url=http://kwininno.iptime.org/store/" + this.state.store_id + "/kakao/cancel", requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            console.log(result);
                            document.cookie = "tid=" + result.tid + ";path=/store";
                            window.open(result.next_redirect_pc_url);
                        })
                        .catch(error => console.log('error', error));
                }
                else {
                    alert("알수 없는 오류로 실패하였습니다. 입력값을 확인하시고 다시 시도해 주세요.");
                    window.location.reload(false);      //페이지 새로고침
                }
            })
            .catch(err => {
                console.log(err);
                alert("예약에 실패하였습니다. 다시 시도해 주세요.");
                window.location.reload(false);      //페이지 새로고침
            });
    }
   callGetApi() {
      // 룸 정보 요청
      fetch(url + "/api/room/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ room_list: value.data });

            //할인 정보 요청
            fetch(url + "/api/store-setting/discount-time/" + this.state.store_id)
               .then(response => response.json())
               .then(value => {
                  for (var i = 0; i < value.data.length; i++) {
                     if (value.data[i].division === "room") {
                        this.setState({ discount: value.data[i] });
                        this.getTotal();
                        break;
                     }
                  }
               })
               .catch(err => {
                  console.log(err);
                  alert("서버로부터 데이터를 받아오지 못하였습니다.");
               });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });

      // 예약 설정 값 요청
      fetch(url + "/api/store-setting/reservation/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            console.log("setting-reservation", value);
            this.setState({ setting_reservation: value.data });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });

      // 가맹점 정보 요청 (카카오, 아임포트)
      fetch(url + "/api/account/store-inform/simple/" + this.state.store_id)
         .then(response => response.json())
         .then(value => {
            console.log("store-Info", value);
            this.setState({ store_info: value.data });
         })
         .catch(err => {
            console.log(err);
            alert("서버로부터 데이터를 받아오지 못하였습니다.");
         });
   }

    componentDidMount() {
        this.callGetApi();
    }

    render() {
        if (this.state.bucket === null || this.state.bucket.length === 0) {
            return (
                <div style={{ minHeight: '80vh', textAlign: 'center' }}>
                    <h1>예약 정보가 비었습니다. 다시 시도해 주세요.</h1>
                </div>
            );
        } else {
            var roomName = "";
            for (var i = 0; i < this.state.room_list.length; i++) {
                if (this.state.bucket.roomId === this.state.room_list[i].id) {
                    roomName = this.state.room_list[i].name;
                }
            }

            /* 안내문 만들기 */
            // 휴일 여부
            var is_holiday = "평일";
            if (this.state.is_holiday) is_holiday = "휴일"

            // 예약 할인 여부
            var reservation_discount = "";
            if (this.state.setting_reservation.length > 0) {
                if (this.state.setting_reservation[0].discountPerHour !== 0)
                    reservation_discount = this.state.setting_reservation[0].discountPerHour + "";
            }

            var discount_information = "";
            var holiday_information = "";
            var reservation_discount_information = "";

            // 안내문 데이터
            if (this.state.discount) {
                if (this.state.discount.startTime !== this.state.discount.endTime)
                    discount_information = <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ {this.state.discount.startTime}시부터 {this.state.discount.endTime}시까지는 특가시간이며 시간과 인원에 따라 {this.state.discount.discount}원씩 할인됩니다.</p>
                holiday_information = <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ {is_holiday} 가격으로 계산되었습니다.</p>
                if (reservation_discount !== "")
                    reservation_discount_information = <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ 예약은 시간당 {reservation_discount}원씩 할인됩니다.</p>
            }

            return (
                <div style={{ minHeight: '80vh' }}>
                    <h2 style={{ margin: '20px 0px' }}>예약 확인</h2>
                    <Modal show={this.state.importModal} centered backdrop="static" >
                        <Modal.Header style={{ background: "#A7C1CC" }}>
                            <h2 style={{ margin: '0', fontWeight: 'bold', fontSize: '25px', color: '#F5F5F5' }}>결제 진행중입니다.</h2>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{textAlign:'center'}}>
                                <h4>결제 및 예약 처리중입니다.</h4>
                                <h5>페이지를 벗어나지 말아주세요</h5>
                                <img src="/images/Spinner.gif" />
                            </div>
                        </Modal.Body>
                    </Modal>
                    <table style={{ width: '100%', textAlign: 'center', fontSize: '17px' }}>
                        <tbody>
                            <tr>
                                <td style={{ border: '1px solid', padding: '10px 0px' }}>방</td>
                                <td style={{ border: '1px solid' }}>
                                    {roomName}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', padding: '10px 0px' }}>날짜</td>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.startDate.slice(0, 10)}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', padding: '10px 0px' }}>시간</td>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.startDate.slice(11, 16)} ~ {this.state.bucket.endDate.slice(11, 16)}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', padding: '10px 0px' }}>인원수</td>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.numOfPeople}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', padding: '10px 0px' }}>전화번호</td>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.tel}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', padding: '10px 0px' }}>이메일</td>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.email}
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '1px solid', padding: '10px 0px' }}>비밀번호(숫자 6자리)</td>
                                <td style={{ border: '1px solid' }}>
                                    {this.state.bucket.password}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{ textAlign: 'right', marginTop: '20px' }}>
                        <span
                            style={{
                                fontSize: '20px',
                                color: '#3C4856', fontWeight: '700'
                            }}
                        >총 {this.state.totalPrice}원
                        </span>
                        <img
                            src="/images/kakaoPay.png"
                            style={{ cursor: 'pointer', margin: '0px 10px' }}
                            onClick={this.kakaoPay.bind(this)}
                        />
                        <button onClick={this.requestPay.bind(this)} className="customButton3" style={{ width: '80px', height: '50px', padding: '0', margin:'0px 10px' }}>일반결제</button>
                    </div>
                    {discount_information}
                    {holiday_information}
                    {reservation_discount_information}
                    <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ 결제시도 후에는 3분간 예약이 불가능합니다.</p>
                    <p style={{ color: '#C20000', fontSize: '17px', fontWeight: '700' }}>※ 결제가 완료되면 예약 완료 화면이 뜰 때 까지 기다려 주세요.</p>
                </div>
            );
        }
    }
}