import React, { Component } from 'react'
import $ from "jquery"
import { Modal } from 'react-bootstrap'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

// let filter = "win16|win32|win64|mac|macintel";
// if(0 > filter.indexOf(navigator.platform.toLowerCase())){
//     console.log("Client platform : Mobile");
// }else{
//     console.log("Client platform : PC");
// }

export default class Order extends Component {
   constructor(props) {
      super(props)
      this.state = {
         storeId: this.props.match.params.storeId,
         input_goods: [],        //상품 정보
         input_category: [],     //카테고리 정보
         input_option: [],       //옵션 정보
         selected_category: 1,   //현재 선택된 카테고리 번호
         showBucket: true,       //장바구니 표시 여부
         innerBucket: [],        //장바구니 내용
         clicked_option: [],     //선택된 상품의 옵션 리스트
         selected_option: [],    //선택된 옵션 리스트
         totalPrice: 0,          //전체 주문 가격
         modal: false            //옵션 모달창 출력 여부
      }
   }

   // 옵션 선택창 종료
   modalClose() {
      this.setState({ modal: false, clicked_option: [], selected_option: [] });
   }

   // 옵션 선택 이벤트
   optionClick(option_id) {
      var temp = this.state.selected_option;

      for (var i = 0; i < this.state.clicked_option.length; i++) {
         if (option_id === this.state.clicked_option[i].id) {
            var flag = 0;
            var index = -1;
            for (var j = 0; j < temp.length; j++) {
               if (temp[j].id === option_id) {
                  flag++;
                  index = j;
                  break;
               }
            }
            //이미 선택 리스트에 있으면 삭제
            if (flag === 1)
               temp.splice(index, 1);
            else
               temp.push(this.state.clicked_option[i])

            break;
         }
      }

      $('.allOption').css('background', '#222');
      for (var z = 0; z < temp.length; z++) {
         $(`.option${temp[z].id}`).css('background', '#0F782A');
      }

      this.setState({ selected_option: temp });
   }

   putIntoBucketWithOption() {
      if (this.state.innerBucket.length === 0)
         alert("상품이 추가됐습니다. 좌측 하단의 장바구니를 확인해 주세요.");

      //상품 인덱스 찾기
      var goodsIndex = 0;
      for (var j = 0; j < this.state.input_goods.length; j++) {
         if (this.state.input_goods[j].id === this.state.clicked_option[0].goodsId) {
            goodsIndex = j;
            break;
         }
      }

      //옵션 인덱스 찾기
      var optionIndex = "";
      var option_totalPrice = 0;
      for (var z = 0; z < this.state.selected_option.length; z++) {
         for (var k = 0; k < this.state.input_option.length; k++) {
            if (this.state.input_option[k].id === this.state.selected_option[z].id) {
               optionIndex += ("/" + k);
            }
         }
         option_totalPrice += this.state.selected_option[z].price;
      }

      // 해당 상품이 이미 장바구니에 있는지 검사
      var list = this.state.innerBucket;
      var i = 0;
      while (i < list.length) {
         if (list[i].id === goodsIndex && list[i].option === optionIndex) {
            list[i].quantity = list[i].quantity + 1;
            this.setState({
               modal: false,
               clicked_option: [],
               selected_option: [],
               innerBucket: list,
               totalPrice: this.state.totalPrice + this.state.input_goods[goodsIndex].price + option_totalPrice
            });
            return;
         }
         i = i + 1;
      }

      // 장바구니에 없으면 추가
      list.push({
         id: goodsIndex,
         option: optionIndex,
         quantity: 1
      });

      this.setState({
         modal: false,
         clicked_option: [],
         selected_option: [],
         innerBucket: list,
         totalPrice: this.state.totalPrice + this.state.input_goods[goodsIndex].price + option_totalPrice
      });
   }

   /* 세션 저장 및 주문페이지 이동 */
   goPayemnt() {
      window.sessionStorage.removeItem("bucket");
      window.sessionStorage.setItem("bucket", JSON.stringify(this.state.innerBucket));
      window.location.href = "/store/" + this.state.storeId + "/payment/goods";
   }

   /* 카테고리 버튼 이벤트 */
   selectingCategory(category) {
      //state 변경
      this.setState({ selected_category: category });

      //모든 버튼 CSS 초기화
      $('.list-group-item').css('background', 'white');
      $('.list-group-item').css('color', 'black');

      //눌린 버튼 하이라이트
      $(`.category${category}`).css('background', '#545a70');
      $(`.category${category}`).css('color', 'white');
   }

   /* 장바구니 열기/닫기 버튼 이벤트 */
   showBucket() {
      if (this.state.showBucket === false) {
         $('.bucket').slideUp("slow");
         $('.bi').animate({ bottom: '2%' }, 590);
         this.setState({ showBucket: true });
      }
      else {
         $('.bucket').slideDown("slow");
         $('.bi').animate({ bottom: '+=220px' }, 590);
         this.setState({ showBucket: false });
      }
   }

   /* 상품 클릭이벤트 (장바구니에 넣기) */
   putIntoBucket(goodsIndex) {
      // 해당 상품의 옵션 확인
      var option_list = [];
      for (var j = 0; j < this.state.input_option.length; j++) {
         if (this.state.input_goods[goodsIndex].id === this.state.input_option[j].goodsId) {
            option_list.push(this.state.input_option[j]);
         }
      }

      // 옵션이 없으면 장바구니에 추가
      if (option_list.length === 0) {
         if (this.state.innerBucket.length === 0)
            alert("상품이 추가됐습니다. 좌측 하단의 장바구니를 확인해 주세요.");

         var list = this.state.innerBucket;
         var i = 0;

         // 해당 상품이 이미 장바구니에 있는지 검사
         while (i < list.length) {
            if (list[i].id === goodsIndex) {
               list[i].quantity = list[i].quantity + 1;
               this.setState({ innerBucket: list, totalPrice: this.state.totalPrice + this.state.input_goods[goodsIndex].price });

               return;
            }
            i = i + 1;
         }

         // 장바구니에 없으면 추가
         list.push({
            id: goodsIndex,
            quantity: 1
         });

         this.setState({ innerBucket: list, totalPrice: this.state.totalPrice + this.state.input_goods[goodsIndex].price });
      }
      // 옵션이 있으면 modal창 열기
      else {
         this.setState({ modal: true, clicked_option: option_list });
      }
   }

   /* 상품 수량 추가 이벤트 */
   addGoods(bucketIndex) {
      var list = this.state.innerBucket;

      this.setState({ totalPrice: this.state.totalPrice + this.state.input_goods[list[bucketIndex].id].price });
      list[bucketIndex].quantity = list[bucketIndex].quantity + 1;
   }

   /* 상품 수량 빼기 이벤트 */
   deleteGoods(bucketIndex) {
      var list = this.state.innerBucket;

      if (bucketIndex === 0) { //첫번째 상품
         if (list[bucketIndex].quantity === 1) {
            this.setState({ totalPrice: this.state.totalPrice - this.state.input_goods[list[bucketIndex].id].price })
            list.shift();
         } else {
            this.setState({ totalPrice: this.state.totalPrice - this.state.input_goods[list[bucketIndex].id].price })
            list[bucketIndex].quantity = list[bucketIndex].quantity - 1;
         }
      } else if (bucketIndex === list.length - 1) { //마지막 상품
         if (list[bucketIndex].quantity === 1) {
            this.setState({ totalPrice: this.state.totalPrice - this.state.input_goods[list[bucketIndex].id].price })
            list.pop();
         } else {
            this.setState({ totalPrice: this.state.totalPrice - this.state.input_goods[list[bucketIndex].id].price })
            list[bucketIndex].quantity = list[bucketIndex].quantity - 1;
         }
      } else {        //나머지 상품
         if (list[bucketIndex].quantity === 1) {
            this.setState({ totalPrice: this.state.totalPrice - this.state.input_goods[list[bucketIndex].id].price })
            list.splice(bucketIndex, 1);
         } else {
            this.setState({ totalPrice: this.state.totalPrice - this.state.input_goods[list[bucketIndex].id].price })
            list[bucketIndex].quantity = list[bucketIndex].quantity - 1;
         }
      }
   }

   /* 장바구니 비우기 이벤트 */
   makeBucketEmpty() {
      this.setState({ innerBucket: [], totalPrice: 0 })
   }

   callGetApi() {
      // 상품 정보
      fetch(url + "/api/goods/" + this.state.storeId)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200)
               this.setState({ input_goods: value.data });
            else if (value.status === 204)
               alert("등록된 상품 정보가 없습니다.")
            else
               alert("설정값을 불러오는데 실패하였습니다.");
         })
         .catch(err => alert("설정값을 불러오는데 실패하였습니다."));

      // 카테고리 정보
      fetch(url + "/api/goods/category/" + this.state.storeId)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200 || value.status===204)
               this.setState({ input_category: value.data });
            else
               alert("설정값을 불러오는데 실패하였습니다.");
            this.selectingCategory(value.data[0].id);         //화면 렌더링 시 첫번째 카테고리 자동 선택
         })
         .catch(err => alert("카테고리 정보를 불러오는데 실패하였습니다."));

      // 옵션 정보
      fetch(url + "/api/goods/option/" + this.state.storeId)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200 || value.status===204)
               this.setState({ input_option: value.data });
            else
               alert("설정값을 불러오는데 실패하였습니다.");
         })
         .catch(err => alert("카테고리 정보를 불러오는데 실패하였습니다."));
   }

   componentDidMount() {
      this.callGetApi();
   }

   render() {
      var list = [];
      var t = 0;
      // 상품 리스트 생성
      while (t < this.state.input_goods.length) {
         if (this.state.input_goods[t].category === this.state.selected_category) {
            if (this.state.input_goods[t].hidden === false) {
               var imageSource = "";

               if (this.state.input_goods[t].image != null)
                  imageSource = this.state.input_goods[t].image;
               else
                  imageSource = "http://placehold.it/700x400";

               list.push(
                  <div key={t} style={{ width: '29%', margin: '10px' }}>
                     <div className="card h-100">
                        <div className="card-header">
                           <h6 style={{ fontWeight: 'bold' }}>{this.state.input_goods[t].name}</h6>
                        </div>
                        <img className="card-img-top" src={imageSource} alt=""
                           style={{ width: '100%' }} />
                        <div className="card-body">
                           <h5 className="card-title">
                              {this.state.input_goods[t].price.toLocaleString()}원
                        </h5>
                           <p className="card-text">{this.state.input_goods[t].comment}</p>
                        </div>
                        <div className="card-footer">
                           <button
                              className="customButton"
                              style={{ width: "100%" }}
                              onClick={this.putIntoBucket.bind(this, t)}
                           >장바구니에 담기</button>
                        </div>
                     </div>
                  </div>
               );
            }
         }
         t++;
      }

      // 옵션 리스트 생성
      var type_list = [];
      var options_list = [];
      var print_option = [];
      for (var j = 0; j < this.state.clicked_option.length; j++) {
         var flag = 0;
         for (var z = 0; z < type_list.length; z++) {
            if (type_list[z] === this.state.clicked_option[j].type) {
               flag++;
               break;
            }
         }
         if (flag === 0) {
            type_list.push(this.state.clicked_option[j].type);
            options_list.push([]);
         }
      }

      for (var k = 0; k < this.state.clicked_option.length; k++) {
         for (var z = 0; z < type_list.length; z++) {
            if (type_list[z] === this.state.clicked_option[k].type) {
               options_list[z].push(this.state.clicked_option[k]);
               break;
            }
         }
      }

      // 옵션 출력 리스트 생성
      var key = 0;
      for (var t = 0; t < type_list.length; t++) {
         print_option.push(
            <h3 key={key} style={{ marginTop: '20px' }}>{type_list[t]}</h3>
         );
         key++;

         var option_wrapper = [];
         var option_inner = [];
         for (var z = 0; z < options_list[t].length; z++) {
            option_inner.push(
               <button
                  onClick={this.optionClick.bind(this, options_list[t][z].id)}
                  key={key}
                  className={`customButton3 allOption option${options_list[t][z].id}`}
                  style={{
                     width: '130px', height: '80px',
                     margin: '10px', background: '#222',
                     borderRadius: '15px', boxShadow: '5px 5px #AAA'
                  }}
               >
                  {options_list[t][z].name} <br />
                  {options_list[t][z].price.toLocaleString()}원
               </button>
            );
            key++;
         }

         option_wrapper.push(
            <div key={key} style={{ overflow: 'auto', height: '100px' }}>
               {option_inner}
            </div>
         );
         key++;

         print_option.push(option_wrapper);
      }

      // 선택된 옵션 출력리스트 생성
      var print_selected_option = [];
      for (var p = 0; p < this.state.selected_option.length; p++) {
         print_selected_option.push(
            <button key={p} className="customButton">
               {this.state.selected_option[p].name}({this.state.selected_option[p].price})
            </button>
         );
      }

      return (
         <div style={{ minHeight: '80vh' }}>
            <h1 style={{ margin: '20px 30px' }}>Order</h1>
            <Modal show={this.state.modal} centered backdrop="static" size='lg' >
               <Modal.Header style={{ background: "#A7C1CC" }}>
                  <h2 style={{ margin: '0', fontWeight: 'bold', fontSize: '25px', color: '#F5F5F5' }}>상품 옵션</h2>
                  <span onClick={this.modalClose.bind(this)} style={{ cursor: 'pointer', fontWeight: 'bold', fontSize: '25px', color: '#F5F5F5' }} className="pull-right">X</span>
               </Modal.Header>
               <Modal.Body>
                  {print_option}
               </Modal.Body>
               <Modal.Footer>
                  {print_selected_option}
                  <button onClick={this.putIntoBucketWithOption.bind(this)} className="btn btn-info">장바구니에 담기</button>
               </Modal.Footer>
            </Modal>
            <div className="row">
               {/* left navigation start */}
               <div className="col-lg-3">
                  <CategoryNav categories={this.state.input_category} selectingCategory={this.selectingCategory.bind(this)} />
               </div>
               {/* left navigation end */}
               <div className="col-lg-9">
                  {/* goods start */}
                  <div className="row">
                     <div className="col-lg-12">
                        <div className="row" style={{ margin: '20px 0px' }}>
                           {list}
                        </div>
                     </div>
                  </div>
                  {/* goods end */}
               </div>
            </div>
            <Bucket
               showBucket={this.showBucket.bind(this)}
               addGoods={this.addGoods.bind(this)}
               deleteGoods={this.deleteGoods.bind(this)}
               makeBucketEmpty={this.makeBucketEmpty.bind(this)}
               goPayemnt={this.goPayemnt.bind(this)}
               innerBucket={this.state.innerBucket}
               input_goods={this.state.input_goods}
               input_option={this.state.input_option}
               totalPrice={this.state.totalPrice}
            />
         </div>
      );
   }
}

class CategoryNav extends Component {
   render() {
      var categories = this.props.categories;
      var i = 0;
      var list = [];

      while (i < categories.length) {
         list.push(
            <button
               key={i}
               className={`list-group-item category${categories[i].id}`}
               onClick={this.props.selectingCategory.bind(this, categories[i].id)}
            >
               {categories[i].categoryName}
            </button>
         )
         i = i + 1;
      }

      return (
         <div className="list-group">
            {list}
         </div>
      );
   }
}

class Bucket extends Component {
   // props: innnerBucket, input_goods, showBucket, totalPirce
   render() {
      var list = [];
      var i = 0;

      while (i < this.props.innerBucket.length) {
         var index = this.props.innerBucket[i].id;
         var name = this.props.input_goods[index].name + " ";
         var price = this.props.input_goods[index].price;

         // 옵션이 있을 경우
         if (this.props.innerBucket[i].option !== undefined) {
            var option_list = this.props.innerBucket[i].option.split('/');
            for (var j = 1; j < option_list.length; j++) {
               name += ("{" + this.props.input_option[option_list[j]].name + "}");
               price += this.props.input_option[option_list[j]].price;
            }
         }

         // 출력리스트 생성
         list.push(
            <tr key={i}>
               <th>{name}</th>
               <th>{price.toLocaleString()}</th>
               <th>{this.props.innerBucket[i].quantity}</th>
               <th>{(this.props.innerBucket[i].quantity * price).toLocaleString()}</th>
               <th>
                  <div>
                     <svg onClick={this.props.deleteGoods.bind(this, i)} width="1em" height="1em" viewBox="0 0 16 16" className="customButton bi bi-dash-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fillRule="evenodd" d="M3.5 8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.5-.5z" />
                     </svg>
                     <svg onClick={this.props.addGoods.bind(this, i)} width="1em" height="1em" viewBox="0 0 16 16" className="customButton bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 3.5a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5H4a.5.5 0 0 1 0-1h3.5V4a.5.5 0 0 1 .5-.5z" />
                        <path fillRule="evenodd" d="M7.5 8a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1H8.5V12a.5.5 0 0 1-1 0V8z" />
                        <path fillRule="evenodd" d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                     </svg>
                  </div>
               </th>
            </tr>
         );

         i = i + 1;
      }

      return (
         <div>
            <svg onClick={this.props.showBucket}
               width="130px" height="130px"
               viewBox="0 0 16 16"
               className="bi bi-bucket-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg"
               style={{
                  border: '1px solid', borderRadius: '100px', padding: '5px', cursor: 'pointer', color: 'orange',
                  position: 'fixed', bottom: '2%', right: '5%', left: '5%'
               }}
            >
               <path fillRule="evenodd" d="M8 1.5A4.5 4.5 0 0 0 3.5 6h-1a5.5 5.5 0 1 1 11 0h-1A4.5 4.5 0 0 0 8 1.5z" />
               <path fillRule="evenodd" d="M1.61 5.687A.5.5 0 0 1 2 5.5h12a.5.5 0 0 1 .488.608l-1.826 8.217a1.5 1.5 0 0 1-1.464 1.175H4.802a1.5 1.5 0 0 1-1.464-1.175L1.512 6.108a.5.5 0 0 1 .098-.42z" />
            </svg>
            <div className="bucket"
               style={{
                  background: 'radial-gradient(circle, #141e30, #3B4459, #141e30)',
                  borderRadius: '10px', color: '#DFDFDF', display: 'none',
                  position: 'fixed', bottom: '2%', right: '5%', left: '5%'
               }}
            >
               <h2 style={{ marginLeft: '20px', float: 'left' }}>장바구니</h2>
               <div style={{ float: 'right' }}>
                  <span style={{ fontSize: '20px' }}>총 {this.props.totalPrice.toLocaleString()}원</span>
                  <button
                     className="customButton"
                     onClick={this.props.goPayemnt.bind(this)}
                     style={{
                        margin: '0px 10px',
                        fontWeight: 'bold',
                        fontSize: '15px'
                     }}>결제하기</button>
               </div>
               <div style={{ borderBottom: 'solid 1px', width: '95%', height: '50px', margin: '0 auto' }}></div>
               <div style={{ height: '150px', overflow: 'auto', width: '95%', margin: '10px auto', borderRadius: '10px' }}>
                  <table style={{ width: '90%', textAlign: 'center', margin: '0px auto', padding: '0' }}>
                     <tbody>
                        <tr>
                           <th style={{ borderBottom: '1px solid', borderLeft: '1px solid' }}>상품정보</th>
                           <th style={{ borderBottom: '1px solid', borderLeft: '1px solid' }}>가격</th>
                           <th style={{ borderBottom: '1px solid', borderLeft: '1px solid' }}>수량</th>
                           <th style={{ borderBottom: '1px solid', borderLeft: '1px solid' }}>합계</th>
                           <th style={{ borderBottom: '1px solid', borderLeft: '1px solid', borderRight: '1px solid' }} className="customButton" onClick={this.props.makeBucketEmpty}>비우기</th>
                        </tr>
                        {list}
                     </tbody>
                  </table>
               </div>
            </div>
         </div>
      );
   }
}
