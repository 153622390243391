import React, { Component } from 'react'

const ServerConfig = require('../ServerConfig');
const url = ServerConfig.url;

export default class PointCharge extends Component {
   constructor(props) {
      super(props);
      this.state = ({
         store_id: this.props.match.params.storeId,
         input_information : [],
         is_member: false,
         clickedIndex_point: -1,
         selected_date: new Date(),      // 선택된 날짜
         user_information: [],           // 멤버 정보
         point_list : [],
         point : "",
         price : "",
         total_point : "",
         store_name : ""
      });
      this.onChangePoint = this.onChangePoint.bind(this);
      this.onChangePrice = this.onChangePrice.bind(this);
      this.onChangeTotal = this.onChangeTotal.bind(this);
      this.selectPointList = this.selectPointList.bind(this);
   }

   onChangePoint(e) {
      this.setState({ point: e.target.value })
   }
   onChangePrice(e) {
      this.setState({ price: e.target.value })
   }
   onChangeTotal(e) {
      this.setState({ total_point: e.target.value })
   }
   
   nextStep() {
      if (this.state.clickedIndex_point === -1) {
         alert("목록을 선택해 주세요.");
         return;
      } else {
         this.tryPay();
      }
   }

   selectPointList(index) {
      var point =0;
      var pointlist = this.state.input_information.map(x=>x.point);
      if(pointlist.length>1)
         point = pointlist.reduce((x,y)=>x+y);
      else if(pointlist.length==1)
         point = pointlist[0];
      this.setState({
         clickedIndex_point: index,
         price : this.state.point_list[index].price,
         point : this.state.point_list[index].point,
         total_point : this.state.point_list[index].point +point
      });
   }

   /* 결제 버튼 이벤트 */
   tryPay() {
      window.sessionStorage.clear();
      window.sessionStorage.setItem("point", JSON.stringify({
         storeId: this.state.store_id,
         pointId: this.state.point_list[this.state.clickedIndex_point].id,
         totalPrice: this.state.point_list[this.state.clickedIndex_point].price,
         point : this.state.point_list[this.state.clickedIndex_point].point,
         tel: this.state.user_information.tel,
         email: this.state.user_information.email,
      }));
      window.location.href = "/store/" + this.state.store_id + "/payment/point";
   }
   /* 포인트 조회 */
   callGetApi() {
      var requestOptions = {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      fetch(url + "/api/members/reservation", requestOptions)
      .then(response => response.json())
      .then(value => {
         if (value.status === 200) {
            this.setState({
               is_member: true,
               user_information: value.data[0]
            });

         fetch(url + "/api/point/admin/"+value.data[0].tel, requestOptions)
            .then(response => response.json())
            .then(value => {
               if (value.status === 200) {
                  console.log(value);
                  if (value.data.length === 0) {
                     this.setState({
                        input_information: value.data
                     });
                  } else {
                     this.setState({
                        input_information: value.data,
                     });
                  }
               }
            })
            .catch(err => { console.log(err); alert("포인트 정보 데이터를 받아오지 못하였습니다."); });
         }
      })
      .catch(err => {
         console.log(err);
         alert("서버로부터 데이터를 받아오지 못하였습니다.");
      });

      fetch(url + "/api/goods/charging/" + this.state.store_id, requestOptions)
         .then(response => response.json())
         .then(value => {
            if (value.status === 200) {
               this.setState({
                  point_list: value.data
               });
            }
         })
         .catch(err => { console.log(err); alert("포인트 리스트를 서버로부터 데이터를 받아오지 못하였습니다.");
       });

       fetch(url + "/api/account/store-inform/storename/" +  this.state.store_id)
         .then(response => response.json())
         .then(value => {
            this.setState({ store_name: value.data[0] });
         })
         .catch(err => { console.log(err); });
   }
   componentDidMount() {
      this.callGetApi();
   }

render() {
      if (this.state.is_member) {

         return (
            <div style={{ minHeight: '80vh' }}>
               <h1 style={{ margin: '20px 0px' }}> 포인트 충전</h1>

               <div style={{ textAlign: 'center' }}>
                  <InnerData
                     nextStep={this.nextStep.bind(this)}
                     point={this.state.point}
                     price={this.state.price}
                     total_point={this.state.total_point}
                     point_list={this.state.point_list}
                     selectPointList={this.selectPointList.bind(this)}
                     input_information={this.state.input_information}
                     clickedIndex_point={this.state.clickedIndex_point}
                     is_member={this.state.is_member}  
                     store_name={this.state.store_name}  
                     user_information={this.state.user_information}
                     onChangePoint={this.onChangePoint.bind(this)}
                     onChangePrice={this.onChangePrice.bind(this)}
                     onChangeTotal={this.onChangeTotal.bind(this)}
                     tryPay={this.tryPay.bind(this)}
                  />
               </div>
            </div>
         );
      } else {
         var login_url = "/login?rtn=" + document.location.href;
         return (
            <div style={{ minHeight: '80vh', alignContent: 'center' }}>
               <div style={{ width: '100%', margin: '0 auto', textAlign: 'center', paddingTop: '200px' }}>
                  <a href={login_url}>
                     <button className="customButton loginBtn"
                        style={{ marginRight: '20px', width: '120px', height: '120px', fontSize: '20px', borderRadius: '15px' }}
                     >로그인</button>
                  </a>
               </div>
            </div>
         );
      }
    }
}

class InnerData extends Component {
   render() {
      // 코스 리스트
      var default_point =this.props.point;
      var default_price =this.props.price;
      var default_total =this.props.total_point;   
      var now = new Date();	// 현재 날짜 및 시간
      var expireday = new Date(now.setMonth(now.getMonth() + 1))
         expireday = expireday.getFullYear()+"-"+((expireday.getMonth()+1 > 9)? expireday.getMonth()+1 :"0"+(expireday.getMonth()+1))+"-"+expireday.getDate();
      var default_tel1 = "", default_tel2 = "", default_tel3 = "";
      if (this.props.is_member) {  //회원이면
         default_tel1 = this.props.user_information.tel.slice(0, 3);
         default_tel2 = this.props.user_information.tel.slice(3, this.props.user_information.tel.length - 4);
         default_tel3 = this.props.user_information.tel.slice(this.props.user_information.tel.length - 4, this.props.user_information.tel.length);
      }

      var point_lists = [];
      if(this.props.point_list)
      for (var i = 0; i < this.props.point_list.length; i++) {
         if(this.props.clickedIndex_point===i){
            point_lists.push(
               <button key={i}
                  className="btn btn-success"
                  onClick={this.props.selectPointList.bind(this, i)}
                  style={{
                     width: '150px',
                     fontSize: '15px', margin:'5px',
                     border: '2px solid gray'
                  }}
               >{this.props.point_list[i].name}<br/>
               금액 : {this.props.point_list[i].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원<br/>
               유효기간 : {this.props.point_list[i].day}일</button>
            );
         }
         else{
            point_lists.push(
               <button key={i}
                  className="btn btn-light"
                  onClick={this.props.selectPointList.bind(this, i)}
                  style={{
                     width: '150px',
                     fontSize: '15px', margin:'5px',
                     border: '2px solid gray'
                  }}
               >{this.props.point_list[i].name}<br/>
               금액 : {this.props.point_list[i].price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원<br/>
               유효기간 : {this.props.point_list[i].day}일</button>
            );
         }
      }

      return (
         <div>
            <div style={{ overflow: 'auto' }}>
               <h3>포인트 충전 선택</h3>
               <hr />
               <p></p>
               <div>
                  {point_lists}
               </div>
               <div style={{ textAlign: 'left', width: '100%', maxWidth: '500px', margin: '0 auto' }}>
                     <label className="customLabel">충전 포인트</label>
                     <input id="input_point"
                        className="customInput"
                        type="text"
                        defaultValue={default_point.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={this.props.onChangePoint}
                        style={{ width: '100%'}}
                        readOnly ={true}
                     /> 
                  
                     <label className="customLabel">결제 금액(원)</label>
                     <input id="input_price"
                        className="customInput"
                        type="text"
                        defaultValue={default_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={this.props.onChangePrice}
                        style={{ width: '100%' }}
                        readOnly ={true}
                     />

                     <label className="customLabel">충전 후 포인트</label>
                     <input id="total_point"
                        className="customInput"
                        type="text"
                        onChange={this.props.onChangeTotal}
                        defaultValue={default_total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        style={{ width: '100%' }}
                        readOnly ={true}
                     />
                     <label className="customLabel">핸드폰 번호</label>
                        <input id="input_tel1"
                           className="customInput"
                           defaultValue={default_tel1}
                           maxLength='3'
                           type="text"
                           style={{ width: '30%', display: 'inline-block' }}
                        /> -
                        <input id="input_tel2"
                           className="customInput"
                           defaultValue={default_tel2}
                           maxLength='4'
                           type="text"
                           style={{ width: '30%', display: 'inline-block' }}
                        /> -
                        <input id="input_tel3"
                           className="customInput"
                           defaultValue={default_tel3}
                           maxLength='4'
                           type="text"
                           style={{ width: '30%', display: 'inline-block' }}
                        />
                     <label className="customLabel">만료일</label>
                        <input id="expire_day"
                           className="customInput"
                           type="text"
                           defaultValue={expireday}
                           style={{ width: '100%' }}
                           readOnly ={true}
                     />
                     <label className="customLabel">매장</label>
                        <input id="expire_day"
                           className="customInput"
                           type="text"
                           defaultValue={this.props.store_name}
                           style={{ width: '100%' }}
                           readOnly ={true}
                     />
                  </div>
               <br/>
            </div>
            <button style={{ fontSize: '20px', margin: '0px 10px' }} onClick={this.props.nextStep} className="btn btn-info" >결제하기 →</button>
         </div>
      );
   }
}