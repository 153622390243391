import React, { Component } from 'react'
import $ from 'jquery'

export default class OAuthSignUp extends Component {
   constructor(props) {
      super(props)
      this.state = ({
         check_id: false,             // 아이디 중복 여부
         check_email: false,
         input_tel: "",
         input_simple_pw: "",
      })
      this.telOnChange = this.telOnChange.bind(this)
      this.simplePwOnChange = this.simplePwOnChange.bind(this)
   }

   // 전화번호 숫자만 허용
   telOnChange(e) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         this.setState({ input_tel: e.target.value })
      }
   }

   // 간편비밀번호 숫자만 허용
   simplePwOnChange(e) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
         this.setState({ input_simple_pw: e.target.value })
      }
   }

   /* 아이디 중복체크 */
   checkingId() {
      if ($('.input_id').val() === ""         //빈칸일 경우
         || $('.input_id').val() !== $('.input_id').val().toLowerCase()      //대문자가 있을 경우
         || $('.input_id').val().length < 6      //6자 미만일 경우
         || $('.input_id').val().length > 20)    //20자 초과일 경우
      {
         alert("아이디를 형식에 맞게 입력해 주세요.");
         this.setState({ check_id: false });
         return;
      }

      fetch(this.props.url + "/api/members/" + $('.input_id').val())
         .then(response => response.json())
         .then(value => {
            console.log(value);
            this.setState({
               check_id: value
            });
            if (value === true) {
               alert("사용 가능한 아이디입니다.");
               $('.input_id').css("border", "1px solid black");
            } else {
               alert("이미 사용되고 있습니다.");
            }
         })
         .catch(err => alert("err: " + err));
   }

   /* 이메일 인증번호 전송 */
   sendingMail() {
      // 이메일 체크
      var email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (email.test($('.input_email').val().trim())) {
         $('.input_email').css("border", "1px solid black");
      } else {
         $('.input_email').css("border", "2px solid #B10000");
         alert("이메일 형식이 아닙니다.");
         return;
      }
      $('.sendingEmailBtn').prop('disabled', true);
      $('.sendingEmailBtn').text("전송중");

      var requestOptions = {
         method: 'GET',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      fetch(this.props.url + "/api/members/email/" + $('.input_email').val(), requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            alert("인증번호가 전송되었습니다.");
            $('.input_email_key').prop('hidden', false);
            $('.checkingEmailBtn').prop('hidden', false);

            $('.sendingEmailBtn').prop('disabled', false);
            $('.sendingEmailBtn').text("재전송");
         })
         .catch(err => alert("err: " + err));
   }

   /* 이메일 인증번호 확인 */
   checkingEmail() {
      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         mode: 'cors',
         credentials: 'include'
      };
      fetch(this.props.url + "/api/members/email/" + $('.input_email_key').val(), requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value === true) {
               alert("이메일 인증이 완료되었습니다.");
               this.setState({ check_email: true });

               $('.input_email_key').prop('hidden', true);
               $('.checkingEmailBtn').prop('hidden', true);
               $('.input_email').prop('disabled', true);
            } else {
               alert("인증번호가 다릅니다.");
            }
         })
         .catch(err => alert("err: " + err));
   }

   /* input text 눌렸을 때 밑에 안내사항 보이기 */
   onFocusEvent(temp) {
      $('.text_other').prop("hidden", true);
      $('.text_' + temp).prop("hidden", false);
   }

   /* 회원가입 요청 */
   requestSignUp() {
      var result = true;      // fetch 여부
      var alertMessage = "";

      // 아이디 체크
      if ($('.input_id').val() === ""         //빈칸일 경우
         || $('.input_id').val() !== $('.input_id').val().toLowerCase()      //대문자가 있을 경우
         || $('.input_id').val().length < 6      //6자 미만일 경우
         || $('.input_id').val().length > 20)    //20자 초과일 경우
      {
         $('.input_id').css("border", "2px solid #B10000");
         alertMessage = "아이디를 확인해 주세요.";
         result = false;
      } else {
         $('.input_id').css("border", "1px solid black");
      }

      // 비밀번호 체크
      var engNum = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,20}$/;      //영어+숫자로 8~20글자
      if (engNum.test($('.input_pw').val())) {
         $('.input_pw').css("border", "1px solid black");
      } else {
         $('.input_pw').css("border", "2px solid #B10000");
         result = false;
         if (alertMessage === "")
            alertMessage = "비밀번호는 영어와 숫자를 혼합한 8~20글자이어야만 합니다.";
      }

      if ($('.input_pw_re').val() !== $('.input_pw').val()    // 비밀번호가 다를 경우
         || $('.input_pw_re').val() === "")                   // 빈칸일 경우
      {
         $('.input_pw').css("border", "2px solid #B10000");
         $('.input_pw_re').css("border", "2px solid #B10000");
         result = false;
         if (alertMessage === "")
            alertMessage = "비밀번호가 일치하지 않습니다.";
      } else {
         $('.input_pw_re').css("border", "1px solid black");
      }

      // 이름 체크
      if ($('.input_name').val() !== "") {
         $('.input_name').css("border", "1px solid black");
      } else {
         $('.input_name').css("border", "2px solid #B10000");
         result = false;
         if (alertMessage === "")
            alertMessage = "이름을 입력해 주세요.";
      }

      // // 이메일 체크
      // var email = /^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      // if (email.test($('.input_email').val())) {
      //     $('.input_email').css("border", "1px solid black");
      // } else {
      //     $('.input_email').css("border", "2px solid #B10000");
      //     result = false;
      //     if(alertMessage === "")
      //         alertMessage = "이메일 인증을 해주시기 바랍니다.";
      // }

      // 전화번호 체크
      if ($('.input_tel').val().length > 9) {
         $('.input_tel').css("border", "1px solid black");
      } else {
         $('.input_tel').css("border", "2px solid #B10000");
         result = false;
         if (alertMessage === "")
            alertMessage = "전화번호가 올바르지 않습니다.";
      }

      //간편비밀번호 체크
      if ($('.input_simple_pw').val().length === 6) {
         $('.input_simple_pw').css("border", "1px solid black");
      } else {
         $('.input_simple_pw').css("border", "2px solid #B10000");
         if (alertMessage === "")
            alertMessage = "간편 비밀번호를 확인해 주세요";
         result = false;
      }

      // 아이디 중복체크 여부
      if (this.state.check_id === false) {
         alert("아이디 중복체크를 진행해 주세요.");
         $('.input_id').css("border", "2px solid #B10000");
         return;
      }

      // 이메일 인증 여부
      if (this.state.check_email === false) {
         alert("이메일을 인증해 주세요.");
         return;
      }

      if (result === false) {
         alert(alertMessage);
         return;
      }

      var requestOptions = {
         method: 'POST',
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
         },
         body: JSON.stringify({
            inputMemberAccountDTO: {
               account: $('.input_id').val(),
               password: $('.input_pw').val(),
               simplePassword: $('.input_simple_pw').val(),
               tel: $('.input_tel').val(),
            },
            inputMemberInfoDTO: {
               birth: $('.input_birth_year').val() + "-" + $('.input_birth_month').val() + "-" + $('.input_birth_date').val(),
               email: $('.input_email').val(),
               gender: $('.input_gender').val(),
               name: $('.input_name').val(),
               tel: $('.input_tel').val(),
            }
         }),
         mode: 'cors',
         credentials: 'include'
      };
      fetch(this.props.url + "/api/members/sign-up", requestOptions)
         .then(response => response.json())
         .then(value => {
            console.log(value);
            if (value.status === 200) {
               alert("회원가입 되었습니다.");
               // window.location.href = "http://kwininno.iptime.org";
               document.location.href = document.referrer;     //이전페이지로 이동
            }
            else if (value.status === 409) {
               alert("해당 아이디 또는 전화번호가 등록되어있습니다.");
               window.location.reload();
            }
         })
         .catch(err => alert("err: " + err));
   }

   componentDidMount() {
      $('body').css('background', 'linear-gradient(to top, #accbee 0%, #e7f0fd 100%) no-repeat center center fixed');
      $('body').css('padding', '0');
   }

   render() {

      var idCheckBtn = [];
      if (this.state.check_id === false) {
         idCheckBtn =
            <button
               className="customButton2"
               style={{ fontSize: '14px', alignSelf: 'right', marginLeft: '5px', borderRadius: '10px', padding: '5px' }}
               onClick={this.checkingId.bind(this)}
            >
               아이디 중복체크
                </button>;
      } else {
         idCheckBtn =
            <button
               className="customButton"
               style={{ fontSize: '12px', alignSelf: 'right', marginLeft: '5px' }}
               onClick={this.checkingId.bind(this)}
            >
               사용 가능 (재확인)
                </button>
      }
      var emailCheckBtn = [];
      if (this.state.check_email === false) {
         emailCheckBtn =
            <button
               className="customButton3 sendingEmailBtn"
               style={{ fontSize: '14px', marginLeft: '5px', padding: '5px' }}
               onClick={this.sendingMail.bind(this)}
            >
               인증번호 전송
                </button>;
      } else {
         emailCheckBtn =
            <span>확인 완료</span>
      }

      //생년월일 select 박스용 데이터
      var year_list = [];
      var month_list = [];
      var date_list = [];

      var thisYear = new Date().getFullYear();
      for (var i = 0; i < 100; i++) {
         year_list.push(<option key={i} value={thisYear - i}>{thisYear - i}</option>);
      }
      for (var i = 1; i < 13; i++) {
         month_list.push(<option key={i} value={i}>{i}</option>);
      }
      for (var i = 1; i < 32; i++) {
         date_list.push(<option key={i} value={i}>{i}</option>);
      }

      return (
         <div style={{ textAlign: 'center' }}>
            <div
               className="text-center"
               style={{
                  textAlign: 'center', margin: '0 auto',
                  maxWidth: '500px', minWidth: '300px', width: '200%',
               }}
            >
               <h2 style={{ margin: '30px 0px', color: '#93ADCF' }}>KW INNOVATION</h2>

               {/* 입력 부분 시작 */}
               <div style={{ margin: '0 auto', fontSize: '13px', color: '#737', fontWeight: 'bold', textAlign: 'left', width: '80%' }}>로그인</div>

               <input
                  type="text"
                  className="input_id"
                  placeholder="아이디"
                  maxLength='20'
                  onFocus={this.onFocusEvent.bind(this, "id")}
                  style={{ width: '50%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               // style={{ width: '70%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               ></input>
               {idCheckBtn}
               <br />
               <div className="text_id text_other" style={{ fontSize: '12px', fontWeight: '700' }} hidden>
                  # 대문자 사용 불가능 (6~20자)
                    </div>

               <input
                  type="password"
                  className="input_pw"
                  placeholder="비밀번호"
                  maxLength='20'
                  onFocus={this.onFocusEvent.bind(this, "pw")}
                  style={{ width: '70%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               ></input>
               <br />
               <input
                  type="password"
                  className="input_pw_re"
                  placeholder="비밀번호 확인"
                  onFocus={this.onFocusEvent.bind(this, "pw")}
                  style={{ width: '70%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               ></input>
               <div className="text_pw text_other" style={{ fontSize: '12px', fontWeight: '700' }} hidden>
                  # 영문,숫자 조합 (8~20자)
                    </div>

               <div style={{ margin: '0 auto', fontSize: '13px', color: '#737', fontWeight: 'bold', textAlign: 'left', width: '80%' }}>회원정보</div>
               <input
                  type="text"
                  className="input_name"
                  placeholder="이름"
                  style={{ width: '70%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               ></input>
               <br />
               <select
                  className="input_gender"
                  style={{ width: '70%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               >
                  <option value="남">남</option>
                  <option value="여">여</option>
               </select>
               <br />
               <div style={{ margin: '0 auto', fontSize: '13px', color: '#737', fontWeight: 'bold', textAlign: 'left', width: '80%' }}>생년월일</div>
               <select className="input_birth_year"
                  style={{ width: '20%', margin: '5px 3px', height: '40px', fontSize: '14px' }}
               >
                  {year_list}
               </select>
               <select className="input_birth_month"
                  style={{ width: '20%', margin: '5px 3px', height: '40px', fontSize: '14px' }}
               >
                  {month_list}
               </select>
               <select className="input_birth_date"
                  style={{ width: '20%', margin: '5px 3px', height: '40px', fontSize: '14px' }}
               >
                  {date_list}
               </select>
               <br />
               <input
                  type="text"
                  className="input_email"
                  placeholder="이메일"
                  style={{ width: '50%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               ></input>
               {emailCheckBtn}
               <input
                  hidden
                  type="text"
                  className="input_email_key"
                  placeholder="인증번호"
                  style={{ width: '50%', margin: '5px auto', height: '40px', fontSize: '12px' }}
               ></input>
               <button
                  hidden
                  className="customButton3 checkingEmailBtn"
                  style={{ fontSize: '14px', marginLeft: '5px', padding: '5px' }}
                  onClick={this.checkingEmail.bind(this)}
               >인증번호 확인</button>
               <br />

               <div style={{ margin: '0 auto', fontSize: '13px', color: '#737', fontWeight: 'bold', textAlign: 'left', width: '80%' }}>간편로그인 (키오스크 결제시 사용)</div>
               <input
                  type="text"
                  className="input_tel"
                  placeholder="전화번호"
                  maxLength='11'
                  value={this.state.input_tel}
                  onChange={this.telOnChange}
                  onFocus={this.onFocusEvent.bind(this, "tel")}
                  style={{ width: '70%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               ></input>
               <div className="text_tel text_other" style={{ fontSize: '12px', fontWeight: '700' }} hidden>
                  # '-'없이 숫자만 입력
                    </div>

               <input
                  type="password"
                  className="input_simple_pw"
                  placeholder="간편비밀번호"
                  maxLength='6'
                  value={this.state.input_simple_pw}
                  onChange={this.simplePwOnChange}
                  onFocus={this.onFocusEvent.bind(this, "simple_pw")}
                  style={{ width: '70%', margin: '5px auto', height: '40px', fontSize: '14px' }}
               ></input>
               <div className="text_simple_pw text_other" style={{ fontSize: '12px', fontWeight: '700' }} hidden>
                  # 숫자 6자리
               </div>

               {/* 입력 부분 종료 */}

               <button
                  onClick={this.requestSignUp.bind(this)}
                  className="customButton3"
                  style={{ width: '60%', padding: '12px 0px', margin: '30px 0px' }}
               >동의 후 회원가입</button>


               <div
                  style={{
                     width: '90%', height: '200px', overflowY: 'scroll', margin: '0px auto',
                     border: '1px solid #ccc', background: '#fafafa', textAlign: 'left',
                     fontFamily: 'dotum,sans-serif', fontSize: '12px', padding: '10px'
                  }}>
                  □ 개인(신용)정보 수집 및 이용동의
                        <br /><br />
                        개인정보보호법에 따라 KW이노베이션에 회원가입 신청하시는 분께 수집하는 개인정보의 항목, 개인정보의 수집 및 이용목적, 개인정보의 보유 및 이용기간, 동의 거부권 및 동의 거부 시 불이익에 관한 사항을 안내 드리오니 자세히 읽은 후 동의하여 주시기 바랍니다.
                        <br /><br />
                        1. 수집하는 개인정보 항목<br />
                        1) 회원가입 시 : 아이디, 비밀번호, 전화번호, 간편 비밀번호, 이름, 생년월일, 성별, 이메일
                        2) KW이노베이션 포인트 서비스 이용자 및 가맹점 서비스 이용자에 한하여 위치정보, 가게 정보
                        3) 간편결제 서비스 이용자에 한하여 카드 정보
                        <br /><br />
                        2. 수집 및 이용목적<br />
                        1) 서비스 가입, 개인 식별, 본인 확인, 고객 상담, 서비스 이용 철회 처리 등의 회원 관리
                        2) 상품 구매 및 판매에 따른 본인인증, 구매 및 요금 결제, 상품 및 서비스의 배송
                        3) 서비스 방문 및 이용 기록의 분석과 서비스 이용에 대한 통계 등을 기반으로 맞춤형 서비스 제공 및 기존 서비스 개선, 신규 서비스 요소 개발 등 서비스 이용 환경 구축
                        4) 부정 이용(거래) 등의 법령 및 이용약관을 위배하거나 부정 이용 행위를 포함하여 서비스의 원활한 운영에 지장을 주는 이용자에 대한 제한 조치 및 제재, 계정도용 및 부정거래 방지, 약관 개정 등의 고지사항 전달, 분쟁조정을 위한 기록 보존, 민원처리 등 이용자 보호 및 서비스 운영
                        5) 특정 금융거래정보의 보고 및 이용 등에 관한 법률 상의 고객 확인 의무 및 강화된 고객 확인 수행
                        6) 광고성 정보 제공 등 마케팅 및 프로모션 소식 전달, 이벤트 정보 및 참여기회 제공
                        7) 서비스 이용기록과 접속 빈도 분석, 서비스 이용에 대한 통계, 서비스 분석 및 통계에 따른 맞춤 서비스 제공 및 광고 게재
                        8) 보안, 프라이버시, 안전 측면에서 이용자가 안심하고 이용할 수 있는 서비스 이용환경 구축
                        <br /><br />
                        3. 보유 및 이용기간, 파기이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되거나, 회원 탈퇴 요청 시 지체없이 재생이 불가능한 방법으로 파기합니다. 단, 아래의 정보에 대해서는 다음과 같은 이유로 명시한 기간 동안 보관합니다.<br />
                        1) 부정거래 방지 및 금융사고 조사를 위하여 회사의 내부 방침에 따라 개인정보 수집?이용 동의 철회 시 데이터 베이스에서 6개월간 보관 후 삭제.
                        2) 전자상거래 등에서의 소비자 보호에 관한 법률, 전자금융거래법, 통신비밀보호법 등 법령에서 일정기간 정보의 보관을 규정하는 경우, 이 기간 동안 법령의 규정에 따라 개인정보를 보관하며, 다른 목적으로는 절대 이용하지 않습니다.- 전자상거래 등에서의 소비자보호에 관한 법률 : 대금결제 및 재화 등의 공급에 관한 기록(5년), 계약 또는 청약철회 등에 관한 기록(5년), 소비자의 불만 또는 분쟁 처리에 관한 기록(3년), 표시·광고에 관한 기록(6개월)- 전자금융거래법 : 전자금융 거래에 관한 기록(5년)- 위치정보의 보호 및 이용 등에 관한 법률 : 위치정보취급대장(6개월)- 통신비밀보호법 : 웹사이트 방문 기록 (3개월)
                        3) 전자적 파일 형태의 경우 복구 및 재생이 되지 않도록 기술적인 방법을 이용하여 안전하게 삭제하며, 출력물 등은 분쇄하거나 소각하는 방식 등으로 파기합니다.
                        <br /><br />
                        4. 동의를 거부할 권리 및 거부 경우의 불이익<br />
                        이용자는 개인정보의 수집 및 이용 동의를 거부할 권리가 있습니다. 회원가입 시 수집하는 최소한의 개인정보, 즉, 필수 항목에 대한 수집 및 이용 동의를 거부하실 경우, 회원가입이 어려울 수 있습니다.
                    </div>
               <p style={{ fontSize: '10px', marginTop: '30px' }}>ⓒKW INNOVATION. All rights reserved.</p>
            </div>
         </div>
      );
   }
}