import React, { Component, useEffect } from 'react'
import { RenderAfterNavermapsLoaded, NaverMap, Marker } from 'react-naver-maps';
import $ from 'jquery'
import { Map, MapMarker } from 'react-kakao-maps-sdk';


export default class Root extends Component {
  constructor(props) {
    super(props)

    this.state = ({
      input_information: [],
      input_detail:[],
      lat: 0,
      lng: 0,
      addr: "",
      is_service_selected: false
    })
  }

  // 가맹점 사이트로 이동
  enterStore(index) {
    // console.log(this.state.input_information)
    // if (this.state.input_information[index].type === "wash") {
    //   document.location.href = "/store/" + this.state.input_information[index].storeId + "/reservation-wash";
    // } else {
    //   document.location.href = "/store/" + this.state.input_information[index].storeId;
    // }
    document.location.href = "/store/" + index + "/reservation-wash";
  }

  callGetApi = () => {
    let lat, long;
    let url = this.props.url;
        if (navigator.geolocation) { // GPS를 지원하면
            navigator.geolocation.getCurrentPosition((position) => {
                lat = position.coords.latitude;
                long = position.coords.longitude;
                console.log(lat);
                console.log(long);

                fetch(url + "/api/reservation/store-inform/easy/"+long +"/"+lat)
                .then(response => response.json())
                .then(value => {
                  if(value.data.length == "0"){
                    alert('주변 가맹점이 없습니다.');
                    fetch(url + "/api/account/store-inform")
                    .then(response => response.json())
                    .then(value => {
                      this.setState({ input_information: value.data, lat: lat, lng: long });    //메인설정 정보
                    })
                    .catch(err => { alert("서버로부터 데이터를 받아오지 못하였습니다."); });
                  }
                  //this.state.input_information = value.data;
                  if(value.data.length != "0"){
                    fetch(url + "/api/reservation/store-inform/"+long +"/"+lat)
                    .then(response => response.json())
                    .then(v => {
                      console.log(v);
                      this.setState({input_detail: v.data});
                      this.setState({ input_information: value.data }); 
                    })
                       //메인설정 정보
                  }
                })
                .catch(err => { console.log(err); alert("주변 가맹점이 없습니다."); });
            }, function(error) {
                console.error(error);
            }, {
                enableHighAccuracy: true,
                maximumAge: 0,
                timeout: Infinity
            })
            
        } else {
            alert('GPS를 지원하지 않습니다');
            fetch(this.props.url + "/api/account/store-inform")
            .then(response => response.json())
            .then(value => {
              console.log(value);
              this.setState({ input_information: value.data });    //메인설정 정보
            })
            .catch(err => { alert("서버로부터 데이터를 받아오지 못하였습니다."); });
        }
  }

  componentDidMount() {
    this.callGetApi();
  }

  render() {
    //가맹점 버튼
    let wash_list = [], cafe_list = [], wash_markers = [], cafe_markers = [], marker_list = [];
    let i = 0;


    // 가맹점 리스트 (Wash)
    while (i < this.state.input_information.length) {
      if (this.state.input_information[i].storeName !== "admin" &&
        this.state.input_information[i].type === "wash") {
        wash_list.push(
          <div key={i} style={{ width: '50%', margin: '20px 0px' }}>
            <button
              className="customButton"
              style={{ fontSize: '25px', width: '220px', height: '100px' }}
              onClick={this.enterStore.bind(this, i)}
            >
              {this.state.input_information[i].storeName}
            </button>
          </div>
        );
      }
      i++;
    }
    i = 0;
    while(i < this.state.input_detail.length){
      if(this.state.input_detail[i].type === "wash"){
        console.log(this.state.input_detail[i])
        // marker_list.push(
        //   {
        //     title: this.state.input_detail[i].storeName,
        //     latlng: {lat : this.state.input_detail[i].lat, lng: this.state.input_detail[i].lng }
        //   }
        // )
      }
      i++;
    }


    return (
      <div>
        {/* Top Navigation */}
        <div style={{ background: '#112', padding: '20px', position: 'fixed', width: '100%', zIndex: '10' }}>
          <h3 style={{ color: '#8DAFCE', fontSize: '45px', fontFamily: 'Dancing Script' }} href="#header">KW innovation</h3>
        </div>

        {/* Selecting Store (wash) */}
        <div id="view1" className="tiledBackground" style={{ background: 'url(../img/laundry-bg.jpg) no-repeat center/cover', overflowY: 'auto' }}>
          <div style={{ textAlign: 'center' }}>
            <div className="container" style={{ height: '500px' }}>
              <div style={{ height: '150px' }} />
                <Map center={{
                    lat: 37.554730,
                    lng: 127.075682 }}
                    style={{ width: "100%", height: "500px" }}
                    level={3}>
                    <MapMarker
                        key='1'
                        image={{
                            src: "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png", // 마커이미지의 주소입니다
                            size: {
                                widht: 24,
                                height: 35
                            }, // 마커이미지의 크기입니다
                        }}
                        position={{lat: 37.554730, lng: 127.075682}}// 마커를 표시할 위치
                        title="모지트 워시카페" // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                        >
                        <div style={{ minWidth: "150px" }}>
                            <img
                                alt="close"
                                width="14"
                                height="13"
                                src="https://t1.daumcdn.net/localimg/localimages/07/mapjsapi/2x/bt_close.gif"
                                style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "5px",
                                    cursor: "pointer",
                                }}
                            />
                            <div style={{ padding: "5px", color: "#000" }}>모지트 워시카페</div>
                            <div style={{ padding: "5px", color: "#000" }}>영업시간 10:00 ~ 01:00</div>
                            <button onClick={this.enterStore.bind(this, 2)}>가게 이용하기</button>
                        </div>
                    </MapMarker>
                    <MapMarker
                        key='2'
                        image={{
                            src: "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png", // 마커이미지의 주소입니다
                            size: {
                                widht: 24,
                                height: 35
                            }, // 마커이미지의 크기입니다
                        }}
                        position={{lat: 37.5403762, lng: 127.0670873}}// 마커를 표시할 위치
                        title="모지트 건대점" // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                        >
                        <div style={{ minWidth: "150px" }}>
                            <img
                                alt="close"
                                width="14"
                                height="13"
                                src="https://t1.daumcdn.net/localimg/localimages/07/mapjsapi/2x/bt_close.gif"
                                style={{
                                    position: "absolute",
                                    right: "5px",
                                    top: "5px",
                                    cursor: "pointer",
                                }}
                            />
                            <div style={{ padding: "5px", color: "#000" }}>모지트 건대점</div>
                            <div style={{ padding: "5px", color: "#000" }}>영업시간 10:00 ~ 00:00</div>
                            <button onClick={this.enterStore.bind(this, 3)}>가게 이용하기</button>
                        </div>
                    </MapMarker>
                </Map>

              <h2 style={{ color: '#724' }}>가맹점을 선택해 주세요.</h2>
              <h2 style={{ color: '#724' }}>현재 위치 : 서울특별시 광진구 군자동</h2>
              {/* <button className="customButton3"
                onClick={this.moveView.bind(this, 1)}
                style={{ width: '100px', height: '50px', borderRadius: '10px', fontSize: '20px' }}
              >뒤로가기</button> */}
              <hr style={{ borderColor: '#C97' }} />
              <div className="row">
                {wash_list}
              </div>
            </div>
          </div>
        </div>

        {/* Footer */}
        <div id="footer">
          <div className="text-center row" style={{ margin: '0px', background: '#112' }}>

          <div className="col-md-12">
              <p style={{ margin: '0px', color: '#8DAFCE' }}>상호명 : 모지트 | 주소 : 서울특별시 광진구 군자로 149 1층  </p>              
              <p style={{ margin: '0px', color: '#8DAFCE' }}>대표자명:윤왕근 | 사업자번호 : 616-55-00178 </p>           
              <p style={{ margin: '0px', color: '#8DAFCE' }}>고객센터 전화 :02-464-5024 </p>
              <p style={{ margin: '0px', color: '#8DAFCE' }}> &copy; 2020 KWINNOVATION. All rights reserved</p>
            
            </div>

          </div>
        </div>
      </div>
    );
  }
}

// class NaverMapRoot extends Component {
//   render() {
//     const navermaps = window.naver.maps;
//      var markers = [];
//      let lat = 0;
//      let long = 0;
//      console.log("maps")
//      this.props.data.input_detail.map((dat) => {
       
//         markers.push(
//           new navermaps.LatLng(dat.lat, dat.lng)
//        )
//      });
//       return (
//           <NaverMap
//               mapDivId={'maps-getting-started-uncontrolled'} // default: react-naver-map
//               style={{
//                   width: '100%', // 네이버지도 가로 길이
//                   height: '500px' // 네이버지도 세로 길이
//               }}
//               defaultCenter={{ lat: 37.5546318, lng: 127.0758583}} // 지도 초기 위치
//               defaultZoom={15} // 지도 초기 확대 배율
//             >
//               <Marker
//                 position={new navermaps.LatLng(37.5546318, 127.0758583)}
//                 // onClick={() => { alert(this.props.temp); }}
//             />
//               <Marker
//                 position={new navermaps.LatLng(37.556567, 127.079530)}
//                 // onClick={() => { alert(this.props.temp); }}
//             />
//               <Marker
//                 position={new navermaps.LatLng(37.5403762, 127.0670873)}
//                 // onClick={() => { alert(this.props.temp); }}
//             />
//           </NaverMap>
//       );
//   }
// }